import { combineReducers } from 'redux';
import { v4 as uuidv4 } from 'uuid';

var _ = require('lodash');

import {
  offsetDateForBrowser
} from '../../../shared/timeZoneLogic';

import {
  PERFORMERS_CHANGED,
  PERFORMER_REMOVED,
  STAGE_PERFORMER_FOR_UPDATE,
  CANCEL_STAGE_PERFORMER_FOR_UPDATE,
  PERFORMER_ACT_TYPE_CHANGED,
  PERFORMER_CHANGED,
  PERFORMER_START_TIME_CHANGED,
  PERFORMER_END_TIME_CHANGED,
  PERFORMER_ADDED,
  PERFORMER_ARTIST_CHANGED,
  PERFORMER_CREATED,
  CANCEL_ADD_PERFORMER,
  CANCEL_EDIT_PERFORMER,
  OPEN_NOTE_CHANGED,
  CLOSE_OPEN_NOTE,
  OPEN_NOTE_TITLE_CHANGED,
  OPEN_NOTE_BODY_CHANGED,
  NOTE_REMOVED,
  NOTE_CREATED,
  NOTE_UPDATED,
  OPEN_NOTE_LOADING,
  OPEN_NOTE_STOPPED_LOADING,
  DISABLE_OPEN_NOTE_PREVIEW_MODE,
  NOTE_TEMPLATES_CHANGED,
  OPEN_NOTE_ORIGINAL_CHANGED,
  ADD_CALENDAR_EVENT_CONTACT,
  CALENDAR_EVENT_CONTACT_REMOVED,
  REMOVE_UNSAVED_CALENDAR_EVENT_CONTACT,
  CALENDAR_EVENT_CONTACT_CREATED,
  EDIT_CALENDAR_EVENT_CONTACT,
  CANCEL_EDIT_CALENDAR_EVENT_CONTACT,
  UPDATE_CALENDAR_EVENT_CONTACT_NAME,
  UPDATE_CALENDAR_EVENT_CONTACT_EMAIL,
  UPDATE_CALENDAR_EVENT_CONTACT_PHONE,
  UPDATE_CALENDAR_EVENT_CONTACT_CONTACT_TYPE,
  UPDATE_CALENDAR_EVENT_CONTACT_TITLE,
  UPDATE_CALENDAR_EVENT_CONTACT_COMPANY_NAME,
  CALENDAR_EVENT_CONTACT_CHANGED,
  EDIT_UNSAVED_CALENDAR_EVENT_CONTACT,
  CALENDAR_EVENT_CONTACTS_CHANGED,
  CLOSE_PERFORMER_OFFER_MODAL,
  OPEN_PERFORMER_OFFER_MODAL,
  SET_OFFER_PERFORMER,
  OFFER_CHANGED,
  OFFERS_CHANGED,
  OFFER_DELETED,
  CONFIRM_CHANGED,
  MODAL_OFFER_CHANGED,
  SENDING_CUSTOMER_IO_EMAIL_CHANGED,
  CUSTOMER_IO_EMAIL_PURPOSE_CHANGED,
  CUSTOMER_IO_EMAIL_TEMPLATE_CHANGED,
  MARKETING_SECTION_ACTIVE_TAB_CHANGED,
  ADDING_NEW_SOCIAL_ACCOUNT_CHANGED,
  COMPOSING_POST_CHANGED,
  POST_CREATED,
  POST_DELETED,
  POST_TO_EDIT_CHANGED,
  POST_UPDATED,
  SHOW_ACTIVITIES_CHANGED,
  SHOW_ACTIVITY_DELETED,
  EDIT_SHOW_ACTIVITY,
  CANCEL_EDIT_SHOW_ACTIVITY,
  ADD_NEW_SHOW_ACTIVITY,
  SHOW_ACTIVITY_NAME_CHANGED,
  SHOW_ACTIVITY_START_DATE_CHANGED,
  SHOW_ACTIVITY_START_TIME_CHANGED,
  SHOW_ACTIVITY_CREATED,
  SHOW_ACTIVITY_UPDATED,
  SHOW_ADVANCE_SEND_CHANGED,
  SHOW_ADVANCE_SEND_TO_CHANGED,
  SHOW_ADVANCE_SEND_SUBJECT_CHANGED,
  SHOW_ADVANCE_SEND_BODY_CHANGED,
  SHOW_ADVANCE_SEND_NOTE_CLICKED,
  CONFIRM_INVITATION_CHANGED,
  CONFIRM_INVITATION_MEMO_CHANGED,
  EMAILS_TO_INVITE_CHANGED,
  CONFIRM_INVITATIONS_CREATED,
  IS_VIEWING_GUESTS_CHANGED,
  CONFIRM_INVITATIONS_DELETED,
  GUEST_TEAM_MEMBERSHIP_REMOVED,
  SHARE_SETTINGS_POPUP_CLOSED,
  OFFER_FOR_SETTLEMENT_CHANGED,
  PERMISSION_SET_CHANGED,
  PERMISSION_RULE_ENABLED_CLICKED,
  SELECT_ALL_PERMISSION_RULES_CLICKED,
  ON_PERMISSION_SET_SAVED_CHANGED,
  CONFIRM_PAGE_SECTION_CHANGED,
  SHOW_ADVANCE_SEND_CREATED,
  ADD_SOCIAL_MARKETING_BUDGET_ITEM,
  ADD_TRADITIONAL_MARKETING_BUDGET_ITEM,
  MARKETING_BUDGET_ITEM_CREATED,
  MARKETING_BUDGET_ITEM_DESTROY,
  EDIT_MARKETING_BUDGET_ITEM,
  MARKETING_BUDGET_ITEM_UPDATED,
  MARKETING_BUDGET_ITEM_TO_EDIT_CHANGED,
  FINANCE_ITEMS_CHANGED,
  FINANCE_ITEM_DELETED,
  FINANCE_ITEM_TO_EDIT_CHANGED,
  FINANCE_ITEM_ADDED,
  FINANCE_ITEM_TO_EDIT_CANCELED,
  FINANCE_ITEM_CREATED,
  FINANCE_ITEM_UPDATED,
  FINANCE_ITEM_FOR_FORM_CHANGED,
  FINANCE_CATEGORIES_CHANGED,
  SHOW_WIDGET_CODE_CHANGED,
  EDITING_CONFIRM_WEBSITE_CHANGED,
  EDITING_CONFIRM_WEBSITE_VALUE_CHANGED,
  FOLDER_CHANGED,
  PROFIT_AND_LOSS_CHANGED,
  HOLD_GROUP_DELETION_CHANGED,
  HOLD_TO_CONFIRM_CHANGED,
  USER_CHANGED_DOORS_TIME_CHANGED,
  USER_CHANGED_EVENT_END_TIME_CHANGED,
  IS_CONVERTING_HOLD_TO_CONFIRM_CHANGED,
  POSSIBLE_MATCHING_OFFERS_CHANGED,
  OFFER_ADDED,
  POSSIBLE_MATCHING_OFFER_REMOVED
} from '../constants/confirmShowConstants';

const confirm = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_CHANGED:
      return action.confirm;
    default:
      return state;
  }
};

const performers = (state = [], action) => {
  switch (action.type) {
    case PERFORMERS_CHANGED:
      return action.performers;
    case PERFORMER_ADDED:
      return [...state, {act_type: "headline"}];
    case PERFORMER_CREATED:
      return [...state].map((performer, index) => {
        if(index === action.index){
          return action.performer;
        } else {
          return performer;
        }
      });
    case STAGE_PERFORMER_FOR_UPDATE:
      return [...state].map((performer, index) => {
        if(performer.id === action.performer.id){
          return Object.assign({}, performer, {stagedForUpdate: true, stagedForDelete: false});
        } else {
          return Object.assign({}, performer, {stagedForUpdate: false, stagedForDelete: false});
        }
      });
    case CANCEL_STAGE_PERFORMER_FOR_UPDATE:
      return [...state].map((performer, index) => {
        return Object.assign({}, performer, {stagedForUpdate: false});
      });
    case CANCEL_EDIT_PERFORMER:
      return [...state].map((performer, index) => {
        if(performer.id === action.performer.id){
          return action.originalPerformer;
        } else {
          return performer;
        }
      });
    case PERFORMER_ACT_TYPE_CHANGED:
      return [...state].map((performer, index) => {
        if(index === action.index){
          return Object.assign({}, performer, {act_type: action.actType});
        } else {
          return performer;
        }
      });
    case PERFORMER_ARTIST_CHANGED:
      return [...state].map((performer, index) => {
        if(index === action.index){
          return Object.assign({}, performer, {artist: action.artist});
        } else {
          return performer;
        }
      });
    case PERFORMER_START_TIME_CHANGED:
      return [...state].map((performer, index) => {
        if(index === action.index){
          return Object.assign({}, performer, {start_time: action.startTime});
        } else {
          return performer;
        }
      });
    case PERFORMER_END_TIME_CHANGED:
      return [...state].map((performer, index) => {
        if(index === action.index){
          return Object.assign({}, performer, {end_time: action.endTime});
        } else {
          return performer;
        }
      });
    case PERFORMER_REMOVED:
      return state.filter((performer) => {
        return performer.id !== action.performer.id;
      });
    case CANCEL_ADD_PERFORMER:
      return state.filter((performer) => {
        return performer.id !== undefined;
      });
    case PERFORMER_CHANGED:
      return [...state].map((performer, index) => {
        if(performer.id === action.performer.id){
          return action.performer;
        } else {
          return performer;
        }
      });
    default:
      return state;
  }
};

const csrfToken = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const team = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const originalPerformer = (state = {}, action) => {
  switch (action.type) {
    case STAGE_PERFORMER_FOR_UPDATE:
      return action.performer;
    default:
      return state;
  }
};

const currentUser = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const openNote = (state = {}, action) => {
  switch (action.type) {
    case DISABLE_OPEN_NOTE_PREVIEW_MODE:
      return Object.assign({}, state, {previewMode: false});
    case OPEN_NOTE_CHANGED:
      return action.note;
    case CLOSE_OPEN_NOTE:
      return {};
    case OPEN_NOTE_TITLE_CHANGED:
      return Object.assign({}, state, {title: action.title});
    case OPEN_NOTE_BODY_CHANGED:
      return Object.assign({}, state, {body: action.editorState});
    default:
      return state;
  }
};

const railsEnv = (state = 'development', action) => {
  switch(action.type) {
    default:
      return state;
  }
};


const notes = (state = [], action) => {
  switch (action.type) {
    case NOTE_CREATED:
      return [...state, action.note];
    case NOTE_REMOVED:
      return state.filter((note) => {
        return note.id !== action.note.id;
      });
    case NOTE_UPDATED:
      return [...state].map((note) => {
        if(note.id === action.note.id){
          return action.note;
        } else {
          return note;
        }
      })
    default:
      return state;
  }
};

const noteTemplates = (state = [], action) => {
  switch (action.type) {
    case NOTE_TEMPLATES_CHANGED:
      return action.noteTemplates;
    default:
      return state;
  }
};

const openNoteOriginal = (state = {}, action) => {
  switch (action.type) {
    case OPEN_NOTE_ORIGINAL_CHANGED:
      return action.note;
    case CLOSE_OPEN_NOTE:
      return {};
    default:
      return state;
  }
};

const openNoteLoading = (state = false, action) => {
  switch (action.type) {
    case OPEN_NOTE_LOADING:
      return true;
    case OPEN_NOTE_STOPPED_LOADING:
      return false;
    default:
      return state;
  }
};

var manageIsEditingCalendarEventContact = (calendarEventContacts, indexToEdit, originalCalendarEventContact) => {
  return [...calendarEventContacts].map((calendarEventContact, index) => {
    var isEditing = (index === indexToEdit);

    var calendarEventContactToReturn;
    if(originalCalendarEventContact && originalCalendarEventContact.id === calendarEventContact.id){
      calendarEventContactToReturn = originalCalendarEventContact;
    } else {
      calendarEventContactToReturn = calendarEventContact;
    }

    return Object.assign({}, calendarEventContactToReturn, {isEditing: isEditing});
  });
}

const calendarEventContacts = (state = [], action) => {
  switch (action.type) {
    case CALENDAR_EVENT_CONTACTS_CHANGED:
      return action.calendarEventContacts;
    case ADD_CALENDAR_EVENT_CONTACT:
      var calendarEventContacts = [...state, {}];
      return manageIsEditingCalendarEventContact(calendarEventContacts, undefined, action.originalCalendarEventContact);
    case CALENDAR_EVENT_CONTACT_REMOVED:
      return state.filter((calendarEventContact) => {
        return calendarEventContact.id !== action.calendarEventContact.id;
      });
    case REMOVE_UNSAVED_CALENDAR_EVENT_CONTACT:
      return state.filter((calendarEventContact) => {
        return calendarEventContact.id !== undefined;
      });
    case CALENDAR_EVENT_CONTACT_CREATED:
      return [...state].map((calendarEventContact, index) => {
        if(index === action.index){
          return action.calendarEventContact;
        } else {
          return calendarEventContact;
        }
      });
    case EDIT_CALENDAR_EVENT_CONTACT:
      var calendarEventContacts = [...state].filter((calendarEventContact) => {
        return calendarEventContact.id !== undefined;
      });

      return manageIsEditingCalendarEventContact(calendarEventContacts, action.index, action.originalCalendarEventContact);
    case EDIT_UNSAVED_CALENDAR_EVENT_CONTACT:
      var calendarEventContacts = manageIsEditingCalendarEventContact(state, action.index, action.originalCalendarEventContact);

      return calendarEventContacts.map((calendarEventContact, index) => {
        if(index === action.index){
          return Object.assign({}, calendarEventContact, {contact: {name: action.name}});
        } else {
          return calendarEventContact;
        }
      });
    case CANCEL_EDIT_CALENDAR_EVENT_CONTACT:
      var calendarEventContacts = [...state].filter((calendarEventContact) => {
        return calendarEventContact.id !== undefined;
      });

      return manageIsEditingCalendarEventContact(calendarEventContacts, undefined, action.originalCalendarEventContact);
    case UPDATE_CALENDAR_EVENT_CONTACT_NAME:
      return [...state].map((calendarEventContact, index) => {
        if(index === action.index){
          var contact = Object.assign({}, calendarEventContact.contact, {name: action.name});
          return Object.assign({}, calendarEventContact, {contact: contact});
        } else {
          return calendarEventContact;
        }
      });
    case UPDATE_CALENDAR_EVENT_CONTACT_EMAIL:
      return [...state].map((calendarEventContact, index) => {
        if(index === action.index){
          var contact = Object.assign({}, calendarEventContact.contact, {email: action.email});
          return Object.assign({}, calendarEventContact, {contact: contact});
        } else {
          return calendarEventContact;
        }
      });
    case UPDATE_CALENDAR_EVENT_CONTACT_PHONE:
      return [...state].map((calendarEventContact, index) => {
        if(index === action.index){
          var contact = Object.assign({}, calendarEventContact.contact, {phone: action.phone});
          return Object.assign({}, calendarEventContact, {contact: contact});
        } else {
          return calendarEventContact;
        }
      });
    case UPDATE_CALENDAR_EVENT_CONTACT_CONTACT_TYPE:
      return [...state].map((calendarEventContact, index) => {
        if(index === action.index){
          var contact = Object.assign({}, calendarEventContact.contact, {contact_type: action.contactType});
          return Object.assign({}, calendarEventContact, {contact: contact});
        } else {
          return calendarEventContact;
        }
      });
    case UPDATE_CALENDAR_EVENT_CONTACT_TITLE:
      return [...state].map((calendarEventContact, index) => {
        if(index === action.index){
          var contact = Object.assign({}, calendarEventContact.contact, {title: action.title});
          return Object.assign({}, calendarEventContact, {contact: contact});
        } else {
          return calendarEventContact;
        }
      });
    case UPDATE_CALENDAR_EVENT_CONTACT_COMPANY_NAME:
      return [...state].map((calendarEventContact, index) => {
        if(index === action.index){
          var contact = Object.assign({}, calendarEventContact.contact, {company_name: action.companyName});
          return Object.assign({}, calendarEventContact, {contact: contact});
        } else {
          return calendarEventContact;
        }
      });
    case CALENDAR_EVENT_CONTACT_CHANGED:
      return [...state].map((calendarEventContact, index) => {
        if(index === action.index){
          return action.calendarEventContact;
        } else {
          return calendarEventContact;
        }
      });
    default:
      return state;
  }
};

const originalCalendarEventContact = (state = {}, action) => {
  switch (action.type) {
    case EDIT_CALENDAR_EVENT_CONTACT:
      return action.calendarEventContact;
    default:
      return state;
  }
};

const defaultOffer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offerModalIsOpen = (state = false, action) => {
  switch (action.type) {
    case OPEN_PERFORMER_OFFER_MODAL:
      return true;
    case CLOSE_PERFORMER_OFFER_MODAL:
      return false;
    default:
      return state;
  }
};

const venuesOptions = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offerPerformer = (state = {}, action) => {
  switch (action.type) {
    case SET_OFFER_PERFORMER:
      return Object.assign({}, state, action.performer);
    default:
      return state;
  }
};

const offers = (state = [], action) => {
  switch (action.type) {
    case OFFER_DELETED:
      return state.filter((offer) => {
        return offer.id !== action.offer.id
      });
    case OFFER_ADDED:
      return [...state, action.offer];
    case OFFERS_CHANGED:
      return action.offers;
    case OFFER_CHANGED:
      return [...state].map((offer) => {
        if(offer.id === action.offer.id){
          return action.offer;
        } else {
          return offer;
        }
      });
    case PERFORMER_REMOVED:
      return state.filter((offer) => {
        return offer.performer_id !== action.performer.id;
      });
    default:
      return state;
  }
};

const modalOffer = (state = {}, action) => {
  switch (action.type) {
    case MODAL_OFFER_CHANGED:
      return action.offer;
    default:
      return state;
  }
};

const offerTemplates = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const sendingCustomerIOEmail = (state = false, action) => {
  switch (action.type) {
    case SENDING_CUSTOMER_IO_EMAIL_CHANGED:
      return action.sendingCustomerIOEmail;
    default:
      return state;
  }
};

const customerIOEmailPurpose = (state = "", action) => {
  switch (action.type) {
    case SENDING_CUSTOMER_IO_EMAIL_CHANGED:
      return "";
    case CUSTOMER_IO_EMAIL_PURPOSE_CHANGED:
      return action.customerIOEmailPurpose;
    default:
      return state;
  }
};

const customerIOEmailTemplate = (state = "", action) => {
  switch (action.type) {
    case SENDING_CUSTOMER_IO_EMAIL_CHANGED:
      return "";
    case CUSTOMER_IO_EMAIL_TEMPLATE_CHANGED:
      return action.customerIOEmailTemplate;
    default:
      return state;
  }
};

const marketingSectionActiveTab = (state = "social", action) => {
  switch (action.type) {
    case MARKETING_SECTION_ACTIVE_TAB_CHANGED:
      return action.tab;
    default:
      return state;
  }
};

const authorizedPages = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const addingNewSocialAccount = (state = false, action) => {
  switch (action.type) {
    case ADDING_NEW_SOCIAL_ACCOUNT_CHANGED:
      return action.addingNewSocialAccount;
    default:
      return state;
  }
};

const onepostPublicKey = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const twitterProviderId = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const facebookProviderId = (state = 0, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const composingPost = (state = false, action) => {
  switch (action.type) {
    case COMPOSING_POST_CHANGED:
      return action.composingPost;
    default:
      return state;
  }
};

const posts = (state = [], action) => {
  switch (action.type) {
    case POST_CREATED:
      return [...state, action.post];
    case POST_DELETED:
      return state.filter((post) => (
        post.id !== action.post.id
      ))
    case POST_UPDATED:
      return [...state].map((post) => {
        if(post.id === action.post.id){
          return action.post;
        } else {
          return post;
        }
      })
    default:
      return state;
  }
};

const postToEdit = (state = {}, action) => {
  switch (action.type) {
    case POST_TO_EDIT_CHANGED:
      return action.post;
    default:
      return state;
  }
};

const showActivities = (state = [], action) => {
  switch (action.type) {
    case SHOW_ACTIVITIES_CHANGED:
      return action.showActivities;
    case SHOW_ACTIVITY_DELETED:
      return state.filter((showActivity) => showActivity.id !== action.showActivity.id);
    case EDIT_SHOW_ACTIVITY:
      return [...state]
        .filter((showActivity) => showActivity.id)
        .map((showActivity) => {
          if(showActivity.id === action.showActivity.id){
            var offsetStartTime = offsetDateForBrowser(showActivity.start_time_with_wrong_date);

            return Object.assign({}, showActivity, {
              _editing: true,
              start_time_with_wrong_date: offsetStartTime
            });
          } else {
            return showActivity;
          }
        });
    case CANCEL_EDIT_SHOW_ACTIVITY:
      return [...state]
        .filter((showActivity) => showActivity.id)
        .map((showActivity) => {
          if(showActivity.id === action.showActivityCopy.id){
            return action.showActivityCopy;
          } else {
            return Object.assign({}, showActivity, {_editing: false});
          }
        });
    case ADD_NEW_SHOW_ACTIVITY:
      var confirmStartDate = offsetDateForBrowser(action.confirm.start_date_midday);
      var confirmStartTime = offsetDateForBrowser(action.confirm.start_time);
      var newShowActivity = {
        _editing: true,
        start_date_midday: confirmStartDate,
        start_time_with_wrong_date: confirmStartTime
      };

      var existing = [...state]
        .map((showActivity) => {
          if(showActivity.id === action.showActivityCopy.id){
            return action.showActivityCopy;
          } else {
            return Object.assign({}, showActivity, {_editing: false});
          }
        });

      return [...existing, newShowActivity];
    case SHOW_ACTIVITY_NAME_CHANGED:
      return [...state].map((showActivity, index) => {
        if(index === action.index){
          return Object.assign({}, showActivity, {name: action.name});
        } else {
          return showActivity;
        }
      });
    case SHOW_ACTIVITY_START_DATE_CHANGED:
      return [...state].map((showActivity, index) => {
        if(index === action.index){
          return Object.assign({}, showActivity, {start_date_midday: action.date});
        } else {
          return showActivity;
        }
      });
    case SHOW_ACTIVITY_START_TIME_CHANGED:
      return [...state].map((showActivity, index) => {
        if(index === action.index){
          return Object.assign({}, showActivity, {start_time_with_wrong_date: action.time});
        } else {
          return showActivity;
        }
      });
    case SHOW_ACTIVITY_CREATED:
      return [...state].map((showActivity, index) => {
        if(showActivity._editing){
          return Object.assign({}, showActivity, action.showActivity, {_editing: false});
        } else {
          return showActivity;
        }
      });
    case SHOW_ACTIVITY_UPDATED:
      return [...state].map((showActivity, index) => {
        if(showActivity.id === action.showActivity.id){
          return Object.assign({}, action.showActivity, {_editing: false});
        } else {
          return showActivity;
        }
      });
    default:
      return state;
  }
};

const showActivityCopy = (state = {}, action) => {
  switch (action.type) {
    case EDIT_SHOW_ACTIVITY:
      return action.showActivity;
    case CANCEL_EDIT_SHOW_ACTIVITY:
      return {};
    case ADD_NEW_SHOW_ACTIVITY:
      return {};
    case SHOW_ACTIVITY_CREATED:
      return {};
    case SHOW_ACTIVITY_UPDATED:
      return {};
    default:
      return state;
  }
};

const showAdvanceSend = (state = {}, action) => {
  switch (action.type) {
    case SHOW_ADVANCE_SEND_CHANGED:
      return action.showAdvanceSend;
    case SHOW_ADVANCE_SEND_TO_CHANGED:
      return Object.assign({}, state, {to: action.to});
    case SHOW_ADVANCE_SEND_SUBJECT_CHANGED:
      return Object.assign({}, state, {subject: action.subject});
    case SHOW_ADVANCE_SEND_BODY_CHANGED:
      return Object.assign({}, state, {body: action.body});
    case SHOW_ADVANCE_SEND_NOTE_CLICKED:
      var noteIds = (state.note_ids ? [...state.note_ids] : []);

      if(noteIds.find((noteId) => noteId === action.noteId)){
        noteIds = noteIds.filter((noteId) => noteId !== action.noteId);
      } else {
        noteIds = [...noteIds, action.noteId];
      }

      return Object.assign({}, state, {note_ids: noteIds});
    default:
      return state;
  }
};

const venueSelected = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const confirmInvitation = (state = {}, action) => {
  switch (action.type) {
    case CONFIRM_INVITATION_CHANGED:
      return action.confirmInvitation;
    case CONFIRM_INVITATION_MEMO_CHANGED:
      return Object.assign({}, state, {memo: action.memo});
    case CONFIRM_INVITATIONS_CREATED:
      return {};
    default:
      return state;
  }
};

const emailsToInvite = (state = [], action) => {
  switch (action.type) {
    case EMAILS_TO_INVITE_CHANGED:
      return [...action.emailsToInvite];
    case CONFIRM_INVITATIONS_CREATED:
      return [];
    default:
      return state;
  }
};

const guestRole = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const pendingConfirmInvitations = (state = [], action) => {
  switch (action.type) {
    case CONFIRM_INVITATIONS_CREATED:
      return [...state].concat(action.invitations);
    case CONFIRM_INVITATIONS_DELETED:
      return state.filter((i) => i.id !== action.invitation.id);
    default:
      return state;
  }
};

const guestTeamMemberships = (state = [], action) => {
  switch (action.type) {
    case GUEST_TEAM_MEMBERSHIP_REMOVED:
      return state.filter((tm) => tm.id !== action.teamMembership.id);
    default:
      return state;
  }
};

const isViewingGuests = (state = false, action) => {
  switch (action.type) {
    case IS_VIEWING_GUESTS_CHANGED:
      return action.isViewingGuests;
    case SHARE_SETTINGS_POPUP_CLOSED:
      return false;
    default:
      return state;
  }
};

const selfRegisterLink = (state = "", action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const offerForSettlement = (state = {}, action) => {
  switch (action.type) {
    case OFFER_FOR_SETTLEMENT_CHANGED:
      return action.offer;
    default:
      return state;
  }
};

const permissionSet = (state = {}, action) => {
  switch (action.type) {
    case PERMISSION_SET_CHANGED:
      return action.permissionSet;
    case SELECT_ALL_PERMISSION_RULES_CLICKED:
      var permissionRules = [...state.permission_rules].map((permissionRule) => {
        return Object.assign({}, permissionRule, {enabled: action.checked});
      });

      return Object.assign({}, state, {permission_rules: permissionRules});
    case PERMISSION_RULE_ENABLED_CLICKED:
      var isEnabled = !action.permissionRule.enabled;

      var permissionRules = [...state.permission_rules].map((permissionRule) => {
        var isMatch = (
          (
            action.permissionRule.rulable_type === permissionRule.rulable_type
              && action.permissionRule.rulable_id === permissionRule.rulable_id
          ) || (
            action.permissionRule.rulable_type === "Role"
              && permissionRule.rulable_type === "TeamMembership"
              && permissionRule.rulable_data.role_id === action.permissionRule.rulable_id
          )
        );

        if(isMatch){
          return  Object.assign({}, permissionRule, {enabled: isEnabled});
        } else {
          return permissionRule;
        }
      });

      return Object.assign({}, state, {permission_rules: permissionRules});
    default:
      return state;
  }
};

const onPermissionSetSaved = (state = () => {}, action) => {
  switch (action.type) {
    case ON_PERMISSION_SET_SAVED_CHANGED:
      return action.onPermissionSetSaved;
    default:
      return state;
  }
};

const confirmPageSections = (state = [], action) => {
  switch (action.type) {
    case CONFIRM_PAGE_SECTION_CHANGED:
      return [...state].map((confirmPageSection) => {
        if(confirmPageSection.id === action.confirmPageSection.id){
          return action.confirmPageSection;
        } else {
          return confirmPageSection;
        }
      });
    default:
      return state;
  }
};

const promotersWithPermission = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const showAdvanceSends = (state = [], action) => {
  switch (action.type) {
    case SHOW_ADVANCE_SEND_CREATED:
      return [...state, action.showAdvanceSend];
    default:
      return state;
  }
};

const socialMarketingBudgetItems = (state = [], action) => {
  switch (action.type) {
    case ADD_SOCIAL_MARKETING_BUDGET_ITEM:
      return [...state, action.marketingBudgetItem];
    case MARKETING_SECTION_ACTIVE_TAB_CHANGED:
      return state.filter((item) => item.id);
    case MARKETING_BUDGET_ITEM_CREATED:
      if(action.marketingBudgetItem.category !== "Social"){
        return state;
      }

      return [...state, action.marketingBudgetItem].filter((item) => item.id);
    case MARKETING_BUDGET_ITEM_DESTROY:
      return state.filter((item) => item.id !== action.marketingBudgetItem.id);
    case EDIT_MARKETING_BUDGET_ITEM:
      return [...state].filter((item) => item.id);
    case MARKETING_BUDGET_ITEM_UPDATED:
      return [...state].map((item) => {
        if(item.id === action.marketingBudgetItem.id){
          return action.marketingBudgetItem;
        } else {
          return item;
        }
      });
    default:
      return state;
  }
};

const traditionalMarketingBudgetItems = (state = [], action) => {
  switch (action.type) {
    case ADD_TRADITIONAL_MARKETING_BUDGET_ITEM:
      return [...state, action.marketingBudgetItem];
    case MARKETING_SECTION_ACTIVE_TAB_CHANGED:
      return state.filter((item) => item.id);
    case MARKETING_BUDGET_ITEM_CREATED:
      if(action.marketingBudgetItem.category !== "TV / Radio / Print"){
        return state;
      }

      return [...state, action.marketingBudgetItem].filter((item) => item.id);
    case MARKETING_BUDGET_ITEM_DESTROY:
      return state.filter((item) => item.id !== action.marketingBudgetItem.id);
    case EDIT_MARKETING_BUDGET_ITEM:
      return [...state].filter((item) => item.id);
    case MARKETING_BUDGET_ITEM_UPDATED:
      return [...state].map((item) => {
        if(item.id === action.marketingBudgetItem.id){
          return action.marketingBudgetItem;
        } else {
          return item;
        }
      });
    default:
      return state;
  }
};

const marketingBudgetItemToEdit = (state = {}, action) => {
  switch (action.type) {
    case ADD_SOCIAL_MARKETING_BUDGET_ITEM:
      return action.marketingBudgetItem;
    case ADD_TRADITIONAL_MARKETING_BUDGET_ITEM:
      return action.marketingBudgetItem;
    case EDIT_MARKETING_BUDGET_ITEM:
      return action.marketingBudgetItem;
    case MARKETING_BUDGET_ITEM_TO_EDIT_CHANGED:
      return action.marketingBudgetItem;
    case MARKETING_SECTION_ACTIVE_TAB_CHANGED:
      return {};
    case MARKETING_BUDGET_ITEM_CREATED:
      return {};
    case MARKETING_BUDGET_ITEM_DESTROY:
      return {};
    case MARKETING_BUDGET_ITEM_UPDATED:
      return {};
    default:
      return state;
  }
};

const financeItems = (state = [], action) => {
  switch (action.type) {
    case FINANCE_ITEMS_CHANGED:
      return action.financeItems;
    case FINANCE_ITEM_DELETED:
      return state.filter((fi) => fi.id !== action.financeItem.id);
    case FINANCE_ITEM_ADDED:
      return [...state, action.financeItem];
    case FINANCE_ITEM_TO_EDIT_CANCELED:
      return state.filter((fi) => fi.id);
    case FINANCE_ITEM_TO_EDIT_CHANGED:
      if(action.financeItem.id){
        return state.filter((fi) => fi.id);
      } else {
        return state;
      }
    case FINANCE_ITEM_CREATED:
      return [...state].map((fi) => {
        if(!fi.id){
          return action.financeItem;
        } else {
          return fi;
        }
      });
    case FINANCE_ITEM_UPDATED:
      return [...state].map((fi) => {
        if(fi.id === action.financeItem.id){
          return action.financeItem;
        } else {
          return fi;
        }
      });
    default:
      return state;
  }
};

const financeCategories = (state = [], action) => {
  switch (action.type) {
    case FINANCE_CATEGORIES_CHANGED:
      return action.financeCategories;
    default:
      return state;
  }
};

const financeItemToEdit = (state = {}, action) => {
  switch (action.type) {
    case FINANCE_ITEM_TO_EDIT_CHANGED:
      return action.financeItem;
    case FINANCE_ITEM_TO_EDIT_CANCELED:
      return {};
    default:
      return state;
  }
};

const financeItemForForm = (state = {}, action) => {
  switch (action.type) {
    case FINANCE_ITEM_FOR_FORM_CHANGED:
      return action.financeItemForForm;
    default:
      return state;
  }
};

const ticketTypesByPrice = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const showWidgetCode = (state = false, action) => {
  switch (action.type) {
    case SHOW_WIDGET_CODE_CHANGED:
      return action.showWidgetCode;
    default:
      return state;
  }
};

const addOnsByPrice = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const buyers = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const promoters = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const venueOwnerships = (state = [], action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const editingConfirmWebsite = (state = false, action) => {
  switch(action.type) {
    case EDITING_CONFIRM_WEBSITE_CHANGED:
      return action.editingConfirmWebsite;
    default:
      return state;
  }
};

const editingConfirmWebsiteValue = (state = "", action) => {
  switch(action.type) {
    case EDITING_CONFIRM_WEBSITE_VALUE_CHANGED:
      return action.editingConfirmWebsiteValue;
    default:
      return state;
  }
};

const folder = (state = {}, action) => {
  switch(action.type) {
    case FOLDER_CHANGED:
      return action.folder;
    default:
      return state;
  }
};

const teamRootFolder = (state = {}, action) => {
  switch(action.type) {
    default:
      return state;
  }
};

const profitAndLoss = (state = {}, action) => {
  switch (action.type) {
    case PROFIT_AND_LOSS_CHANGED:
      return action.profitAndLoss;
    default:
      return state;
  }
};

const mayCancelConfirm = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const mayUncancelConfirm = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const holdGroupDeletion = (state = {}, action) => {
  switch (action.type) {
    case HOLD_GROUP_DELETION_CHANGED:
      return action.holdGroupDeletion;
    default:
      return state;
  }
};

const holdToConfirm = (state = {}, action) => {
  switch (action.type) {
    case HOLD_TO_CONFIRM_CHANGED:
      return action.holdToConfirm;
    default:
      return state;
  }
};

const doorsTimeDefault = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const eventEndTimeDefault = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const userChangedDoorsTime = (state = false, action) => {
  switch (action.type) {
    case USER_CHANGED_DOORS_TIME_CHANGED:
      return action.userChangedDoorsTime;
    default:
      return state;
  }
};

const userChangedEventEndTime = (state = false, action) => {
  switch (action.type) {
    case USER_CHANGED_EVENT_END_TIME_CHANGED:
      return action.userChangedEventEndTime;
    default:
      return state;
  }
};

const isConvertingHoldToConfirm = (state = false, action) => {
  switch (action.type) {
    case IS_CONVERTING_HOLD_TO_CONFIRM_CHANGED:
      return action.isConvertingHoldToConfirm;
    default:
      return state;
  }
};

const possibleMatchingOffers = (state = [], action) => {
  switch (action.type) {
    case POSSIBLE_MATCHING_OFFERS_CHANGED:
      return action.possibleMatchingOffers;
    case POSSIBLE_MATCHING_OFFER_REMOVED:
      return state.filter((offer) => offer.id !== action.possibleMatchingOffer.id);
    default:
      return state;
  }
};

const manualTicketCounts = (state = [], action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const showUpgradeLink = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const showDonationLink = (state = false, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const confirmShowReducer = combineReducers({
  confirm,
  performers,
  csrfToken,
  team,
  originalPerformer,
  currentUser,
  openNote,
  notes,
  noteTemplates,
  openNoteOriginal,
  openNoteLoading,
  calendarEventContacts,
  originalCalendarEventContact,
  modalOffer,
  defaultOffer,
  offerModalIsOpen,
  offerTemplates,
  venuesOptions,
  offerPerformer,
  offers,
  sendingCustomerIOEmail,
  customerIOEmailPurpose,
  customerIOEmailTemplate,
  marketingSectionActiveTab,
  authorizedPages,
  addingNewSocialAccount,
  onepostPublicKey,
  twitterProviderId,
  facebookProviderId,
  composingPost,
  posts,
  postToEdit,
  showActivities,
  showActivityCopy,
  showAdvanceSend,
  venueSelected,
  confirmInvitation,
  emailsToInvite,
  guestRole,
  pendingConfirmInvitations,
  guestTeamMemberships,
  isViewingGuests,
  selfRegisterLink,
  offerForSettlement,
  permissionSet,
  onPermissionSetSaved,
  confirmPageSections,
  promotersWithPermission,
  showAdvanceSends,
  socialMarketingBudgetItems,
  traditionalMarketingBudgetItems,
  marketingBudgetItemToEdit,
  financeItems,
  financeCategories,
  financeItemToEdit,
  financeItemForForm,
  ticketTypesByPrice,
  showWidgetCode,
  addOnsByPrice,
  buyers,
  promoters,
  venueOwnerships,
  editingConfirmWebsite,
  editingConfirmWebsiteValue,
  folder,
  teamRootFolder,
  profitAndLoss,
  mayCancelConfirm,
  mayUncancelConfirm,
  railsEnv,
  holdGroupDeletion,
  holdToConfirm,
  doorsTimeDefault,
  eventEndTimeDefault,
  userChangedDoorsTime,
  userChangedEventEndTime,
  isConvertingHoldToConfirm,
  possibleMatchingOffers,
  manualTicketCounts,
  showUpgradeLink,
  showDonationLink
});

export default confirmShowReducer;

import React, { useEffect, useState } from "react";

import { useBroadcastEditContext } from "./BroadcastEditContainer";
import BroadcastSidebar from "./BroadcastSidebar";
import SegmentFilters from "./SegmentFilters";

import Tippy from "@tippyjs/react";
import { toastSuccess, toastError } from "../../../shared/toastHelper";

const backButtonStyle = {
  padding: "12px 12px",
  color: "#1982C4",
  fontWeight: "bold",
};

const RecipientsTab = () => {
  const {
    csrfToken,
    team,
    setTab,
    broadcast,
    updateBroadcast,
    scheduledSend,
    segmentFilters,
    visibleSegmentFilters,
    fetchAllFansCount,
    fetchSegmentFilteredFansCount,
    recipientsCount,
    setRecipientsCount,
  } = useBroadcastEditContext();

  const [recipientsType, setRecipientsType] = useState(broadcast.recipient_type);
  const [segmentType, setSegmentType] = useState(broadcast.segment_type);

  useEffect(() => {
    if(recipientsType === "all_fans") {
      fetchAllFansCount();
    } else if(recipientsType === "conditional") {
      if(segmentType === "existing") {
        fetchSegmentFilteredFansCount();
      } else {
        setRecipientsCount("--");
      }
    }
  }, [recipientsType, segmentType, segmentFilters]);

  return (
    <BroadcastSidebar>
      <div
        className="mt-3 ml-5 d-flex flex-column justify-content-between"
        style={{ minHeight: "300px" }}
      >
        <div>
          <h3 style={{ fontSize: "20px" }}>
            <strong>
              Recipients
            </strong>
          </h3>
          <p>Who are you sending this email to?</p>
          <div className="d-flex">
            <div>
              <div className="d-flex flex-wrap">
                <button
                  type="button"
                  className="d-flex align-items-center mr-4 mb-4"
                  style={{
                    width: "300px",
                    height: "60px",
                    backgroundColor: recipientsType === "conditional" ? "#eef7fd" : "#fff",
                    border: recipientsType === "conditional" ? "2px solid #1982C4" : "2px solid #ccc",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setRecipientsType("conditional")
                    setSegmentType(null)

                    updateBroadcast({
                      recipient_type: "conditional",
                      segment_type: null
                    }, false);
                  }}
                >
                  <p className="m-3" style={{ fontSize: "16px" }}>
                    <strong>
                      Fans matching conditions
                    </strong>
                  </p>
                </button>
                <button
                  type="button"
                  className="d-flex align-items-center mr-4 mb-4"
                  style={{
                    width: "300px",
                    height: "60px",
                    backgroundColor: recipientsType === "all_fans" ? "#eef7fd" : "#fff",
                    border: recipientsType === "all_fans" ? "2px solid #1982C4" : "2px solid #ccc",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    setRecipientsType("all_fans")
                    setSegmentType(null)

                    updateBroadcast({
                      recipient_type: "all_fans",
                      segment_type: null
                    }, false);
                  }}
                >
                  <p className="m-3" style={{ fontSize: "16px" }}>
                    <strong>
                      Every fan in my workspace
                    </strong>
                  </p>
                </button>
              </div>
              <div>
                {recipientsType === "conditional" ? (
                  <>
                    <div>
                      <p>Target the fans that who should receive this broadcast.</p>
                    </div>
                    <div className="d-flex flex-wrap">
                      <button
                        type="button"
                        className="d-flex align-items-center mr-3 mb-4"
                        style={{
                          width: "260px",
                          height: "50px",
                          backgroundColor: segmentType === "existing" ? "#eef7fd" : "#fff",
                          border: segmentType === "existing" ? "2px solid #1982C4" : "2px solid #ccc",
                          borderRadius: "10px",
                        }}
                        onClick={() => {
                          setSegmentType("existing")

                          updateBroadcast({
                            segment_type: "existing"
                          }, false);
                        }}
                      >
                        <p className="w-100 m-3 text-center" style={{ fontSize: "14px" }}>
                          <strong>
                            Choose an existing segment
                          </strong>
                        </p>
                      </button>
                      <Tippy content="Coming soon!">
                        <span>
                          <button
                            disabled
                            type="button"
                            className="d-flex align-items-center mb-4 text-muted"
                            style={{
                              width: "260px",
                              height: "50px",
                              backgroundColor: segmentType === "new" ? "#eef7fd" : "#fff",
                              border: segmentType === "new" ? "2px solid #1982C4" : "2px solid #ccc",
                              borderRadius: "10px",
                            }}
                            onClick={() => {
                              setSegmentType("new")

                              updateBroadcast({
                                ...broadcast,
                                segment_type: "new"
                              }, false);
                            }}
                          >
                            <p className="w-100 m-3 text-center" style={{ fontSize: "14px" }}>
                              <strong>
                                Create a new segment
                              </strong>
                            </p>
                          </button>
                        </span>
                      </Tippy>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
            {recipientsCount !== "--" ? (
              <div
                className="p-3 rounded align-self-start"
                style={{
                  width: "300px",
                  backgroundColor: "#eef7fd",
                }}
              >
                <p className="mb-2">
                  <strong>
                    Recipients
                  </strong>
                </p>
                <p className="mb-2" style={{ fontSize: "24px" }}>
                  <strong>
                    {recipientsCount}
                  </strong>
                </p>
                {recipientsType === "all_fans" ? (
                  <p className="mb-2">Total fans in this workspace</p>
                ) : (
                  <p className="mb-2">Total fans matching conditions</p>
                )}
                {recipientsType === "all_fans" ? (
                  <div className="w-100 mt-3 p-3 rounded" style={{ backgroundColor: "#fff" }}>
                    <p className="mb-1">
                      <i className="mr-2 fas fa-bolt text-primary" />
                      <strong>
                        Best Practices
                      </strong>
                    </p>
                    <p className="mb-0" style={{ fontSize: "12px" }}>
                      Sending emails to everyone in your database can damage your sending reputation and deliverability.
                    </p>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
          {recipientsType === "conditional" && segmentType === "existing" ? (
            <SegmentFilters
              editable={true}
              showAddButton={visibleSegmentFilters.length === 0}
            />
          ) : null}
        </div>
        <div
          className="d-flex justify-content-end"
          style={{ marginTop: "40px" }}
        >
          <button
            disabled={
              recipientsType === null
                || (recipientsType === "conditional" && !segmentFilters[0]?.set)
            }
            type="button"
            className="ml-2 btn btn-primary"
            onClick={e => {
              e.preventDefault();
              if(!!scheduledSend) {
                window.location.search = "tab=details";
              } else {
                setTab("from");
              }
            }}
          >
            {!!scheduledSend ? "Save" : "Next"}
          </button>
        </div>
      </div>
    </BroadcastSidebar>
  );
};

export default RecipientsTab;

import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import {GlobalCurrency} from '../../GlobalCurrency/GlobalCurrency';
import BudgetLineItemsTable from './BudgetLineItemsTable';
import TagManagerApp from '../../TagManager/startup/TagManagerApp';

import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
const axios = require('axios').default;

var formatter;
const formatCurrency = function(value){
  var abs = Math.abs(value);

  if(value < 0){
    return ("(" + formatter.format(abs) + ")");
  } else {
    return formatter.format(abs);
  }
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}


function offerDate(offer, confirm) {
  if (offer.human_start_date) {
    return <>{offer.human_start_date} at {offer.venue.name} in {offer.venue.city_and_state}</>;
  }

  if (!confirm) {
    return <>TBD at {offer.venue.name} in {offer.venue.city_and_state}</>;
  }

  if (confirm.hold_positions && confirm.hold_positions.length === 1) {
    const holdPositionDate = new Date(confirm.hold_positions[0].start_time);
    return (
      <>
        {holdPositionDate.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' })}
        {' at '}
        {offer.venue.name}
        {' in '}
        {offer.venue.city_and_state}
      </>
    );
  }

  if (confirm.hold_positions && confirm.hold_positions.length > 0) {
    const dates = confirm.hold_positions.map(hold_position => {
      return hold_position.start_time ? new Date(hold_position.start_time) : null;
    }).filter(date => date instanceof Date && !isNaN(date))
      .sort((a, b) => a - b)
      .map(date => date.toLocaleDateString("en-US", { year: '2-digit', month: 'numeric', day: 'numeric' }));

    return (
      <>
        {offer.venue.name} in {offer.venue.city_and_state}<br />
        Dates Currently Held: {dates.join(", ")}
      </>
    );
  }

  return <>TBD at {offer.venue.name} in {offer.venue.city_and_state}</>;
}


function artistPercentageDescription(budget, calculated) {
  var andOrVs;

  if(budget.dollar_or_percentage_operator === "AND"){
    andOrVs = "and";
  } else {
    andOrVs = "or";
  };

  return `${andOrVs} ${calculated.showSummary.upsidePercentage}% of Calculated Backend`;
}

function parsedUpsidePercentage(budget) {
  var upsidePercentage;

  if(budget.upside_percentage){
    upsidePercentage = parseFloat(budget.upside_percentage);
  } else {
    upsidePercentage = 0.00;
  }

  return upsidePercentage;
}

function hasUpsidePercentage(budget) {
  return parsedUpsidePercentage(budget) > 0.00;
}

function tableColgroup(isSettling, requestFormat, showCurrencyConversion) {
  let innerCols = (
    showCurrencyConversion ? (
      <>
        <col span={"1"} width={"15%"} />
        <col span={"1"} width={"15%"} />
        <col span={"1"} width={"15%"} />
        <col span={"1"} width={"15%"} />
      </>
    ) : (
      <>
        <col span={"1"} width={"20%"} />
        <col span={"1"} width={"20%"} />
        <col span={"1"} width={"20%"} />
      </>
    )
  );

  return isSettling && requestFormat === "html" ? (
    <colgroup>
      <col span={"1"} width={"35%"} />
      {innerCols}
      <col span={"1"} width={"5%"} />
    </colgroup>
  ) : (
    <colgroup>
      <col span={"1"} width={"40%"} />
      {innerCols}
      <col span={"1"} width={"0%"} />
    </colgroup>
  );
}

function ticketTableColgroup(isSettling, requestFormat, showCurrencyConversion) {
  let innerCols = (
    showCurrencyConversion ? (
      <>
        <col span={"1"} width={"10%"} />
        <col span={"1"} width={"5%"} />
        <col span={"1"} width={"5%"} />
        <col span={"1"} width={"5%"} />
        <col span={"1"} width={"15%"} />
        <col span={"1"} width={"15%"} />
        <col span={"1"} width={"15%"} />
      </>
    ) : (
      <>
        <col span={"1"} width={"15%"} />
        <col span={"1"} width={"5%"} />
        <col span={"1"} width={"5%"} />
        <col span={"1"} width={"5%"} />
        <col span={"1"} width={"20%"} />
        <col span={"1"} width={"20%"} />
      </>
    )
  );

  return isSettling && requestFormat === "html" ? (
    <colgroup>
      <col span={"1"} width={"25%"} />
      {innerCols}
      <col span={"1"} width={"5%"} />
    </colgroup>
  ) : (
    <colgroup>
      <col span={"1"} width={"30%"} />
      {innerCols}
      <col span={"1"} width={"0%"} />
    </colgroup>
  );
}

function summaryTableColgroup(showCurrencyConversion) {
  return (
    showCurrencyConversion ? (
      <colgroup>
        <col span={"1"} width={"60%"} />
        <col span={"1"} width={"20%"} />
        <col span={"1"} width={"20%"} />
      </colgroup>
    ) : (
      <colgroup>
        <col span={"1"} width={"70%"} />
        <col span={"1"} width={"30%"} />
      </colgroup>
    )
  );
}

function finalDropCount(budget, confirm) {
  return (
    budget.drop_count_override !== null ?
      budget.drop_count_override : confirm.number_of_manifested_tickets_checked_in
  );
}

function finalCompCount(budget, confirm) {
  return (
    budget.comp_count_override !== null ?
      budget.comp_count_override : confirm.number_of_tickets_comped
  );
}

// We have no way of automatically determining the real kill count right now
// If it hasn't been overridden, we return 0.
function finalKillCount(budget, confirm) {
  return (
    budget.kill_count_override !== null ?
      budget.kill_count_override : 0
  );
}

const SettlementTable = ({
  budget,
  setBudgetState,
  calculated,
  offer,
  confirm,
  createSettlementLineItem,
  csrfToken,
  team,
  currentUser,
  offerId,
  deleteSettlementLineItem,
  itemToEdit,
  itemToEditChanged,
  deleteSettlementOverride,
  requestFormat,
  isSettling,
  editedDropCount,
  editedDropCountChanged,
  editedCompCount,
  editedCompCountChanged,
  editedKillCount,
  editedKillCountChanged,
  saveOverrides,
  isSaving,
  offerChanged,
  isSavingChanged,
  getActiveTags,
  getAvailableTags,
}) => {
  const [tagManagerOpen, tagManagerOpenChanged] = useState(false);
  const [tagManagerFilter, tagManagerFilterChanged] = useState("");
  const [availableTags, availableTagsChanged] = useState([]);

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: team.base_currency_symbol
  });

  const filterBudgetLineItems = (items) => {
    return items.filter((item) => {
      if (requestFormat === "html") {
        return true;
      } else if (isSettling) {
        return (!!item.settlementVisibility
          ? item.settlementVisibility.visible
          : item.show_in_offer) && item.calculatedRightHand > 0;
      } else {
        return item.show_in_offer;
      }
    });
  };

  const cashBonuses = budget.budget_bonuses
    .filter((bb) => bb.dollar_or_percentage_operator === "CASH")
    .filter((bb) => bb.ticket_count_requirement)
    .filter((bb) => bb.dollar_amount)
    .sort((a, b) => a.ticket_count_requirement - b.ticket_count_requirement);

  const percentageBonuses = budget.budget_bonuses
    .filter((bb) => bb.dollar_or_percentage_operator === "PERCENTAGE")
    .filter((bb) => bb.ticket_count_requirement)
    .filter((bb) => bb.upside_percentage)
    .sort((a, b) => a.ticket_count_requirement - b.ticket_count_requirement);

  const autoSaveOffer = (team, offerId, offer) => {
    new Promise(resolve => {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
      isSavingChanged(true);

      axios.patch(`/teams/${team.id}/offers/${offerId}`, {
          offer: {
            display_currency_conversion_for_settlement: offer.display_currency_conversion_for_settlement
          }
        })
        .finally(() => {
          isSavingChanged(false);
        });
    });
  };

  const currencySymbol = (
    team.secondary_currency_symbol && team.secondary_currency_symbol.length > 0 ?
      team.secondary_currency_symbol : "USD"
  )

  const globalCurrency = new GlobalCurrency(
    team.currency_conversion_rate, currencySymbol
  );

  const currencyConversionIsEnabled = (
    typeof(team.secondary_currency_symbol) === "string"
      && team.secondary_currency_symbol.length > 0
      && (
        isSettling ?
          offer.display_currency_conversion_for_settlement : offer.display_currency_conversion_for_offer
      )
  );

  const showCurrencyConversion = (
    currencyConversionIsEnabled
      && !team.convert_artist_earnings_only
  );

  return(<div style={{"padding": "0px 7px"}}>
    <table style={{
            "width": "100%",
            "fontFamily": "Open Sans",
            "fontSize": "14px"
          }}>
      <tbody>
        <tr>
          {offer.venue_ownership && offer.venue_ownership.logo_thumb_url ? (
            <td style={{"textAlign": "left", "verticalAlign": "middle"}}>
              <div style={{
                            "width": "56px",
                            "height": "56px",
                            "overflow": "hidden",
                            "borderRadius": "8px",
                            "marginRight": "12px"
                          }}>
                <img src={offer.venue_ownership.logo_thumb_url}
                      style={{"height": "100%"}}>
                </img>
              </div>
            </td>
          ) : null}
          <td style={{"verticalAlign": "middle", "width": "100%"}}>
            <p style={{"margin": "0px", "fontSize": "18px", "textAlign": "left", "display": "inline-block", "float": "left"}}>
              {isSettling ? (
                <strong>SETTLEMENT</strong>
              ) : (
                <strong>OFFER</strong>
              )}
            </p>
            {requestFormat === "html" && isSettling && team.secondary_currency_symbol && team.secondary_currency_symbol.length > 0 ? (
              <div className="custom-control custom-checkbox custom-checkbox-table text-right">
                <input type="checkbox"
                        className="custom-control-input"
                        checked={offer.display_currency_conversion_for_settlement}
                        onChange={
                          (e) => {
                            var updated = Object.assign({}, offer, {
                              display_currency_conversion_for_settlement: e.target.checked
                            });

                            offerChanged(updated);
                            autoSaveOffer(team, offerId, updated);
                          }
                        }
                        id="offer-display-currency-conversion-for-settlement" />
                <label className="custom-control-label"
                        style={{"fontSize": "14px"}}
                        htmlFor="offer-display-currency-conversion-for-settlement">
                  {`Display currency conversion (1 ${team.base_currency_symbol} / ${team.currency_conversion_rate} ${team.secondary_currency_symbol})`}
                </label>
              </div>
            ) : null}
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <p style={{"margin": "0px", "fontSize": "24px"}}>
              <strong>{offer.artist_or_event_name}</strong>
            </p>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <p style={{"margin": "-3px 0px 16px 0px", "fontSize": "16px"}}>
              {offerDate(offer, confirm)}
            </p>
          </td>
        </tr>
        {!isSettling ? (
          <>
            {requestFormat === "html" ? (
              <tr>
                <td colSpan={2}>
                  <TagManagerApp
                    csrfToken={csrfToken}
                    team={team}
                    currentUser={currentUser}
                    scope="Offer"
                    title="Tags for Offer"
                    taggable={offer}
                  />
                </td>
              </tr>
            ) : null}
            <tr>
              <td colSpan={2} style={{"background": "#333333"}}>
                <p style={{"fontSize": "16px", "color": "white", "margin": "8px 15px"}}>
                  <strong>Deal Terms</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td colSpan={2}
                  style={{
                    overflow: 'hidden',
                    verticalAlign: 'top'
                  }}>
                <div style={{
                        background: '#f9f9f9',
                        margin: "30px 0",
                        padding: '18px 15px',
                        borderRadius: '4px',
                        display: 'inline-block',
                        fontSize: '10px',
                        minWidth: '415px'
                      }}>
                  <span style={{
                          fontSize: '10px',
                          letterSpacing: '1px'
                        }}>
                    OFFER DETAILS
                  </span>
                  <p style={{
                        fontSize: '14px',
                        marginTop: '3px',
                        marginBottom: '0px'
                      }}>
                    {currencyConversionIsEnabled ? (
                      <strong style={{ fontSize: '18px' }}>
                        {`${globalCurrency.formatted(budget.dollar_amount || 0.00)} ${team.secondary_currency_symbol} (${formatCurrency(budget.dollar_amount || '0.00')} ${team.base_currency_symbol})`}
                      </strong>
                    ) : (
                      <strong style={{ fontSize: '18px' }}>
                        {formatCurrency(budget.dollar_amount || '0.00')}
                      </strong>
                    )}
                    {hasUpsidePercentage(budget) ? (
                      <>
                        <span style={{"margin": "0px 4px"}}>
                          {budget.dollar_or_percentage_operator === 'AND' ? (
                            <span>+</span>
                          ) : (
                            <span>VS</span>
                          )}
                        </span>
                        <strong style={{ fontSize: '18px' }}>
                          {budget.upside_percentage || '0'}%
                        </strong>
                        <span style={{"margin": "0px 4px"}}>
                          of ticket sales,
                        </span>
                      </>
                    ) : (
                      <span style={{"margin": "0px 4px"}}>
                        flat guarantee
                      </span>
                    )}
                  </p>
                  {calculated.showSummary.upsidePercentage > 0.00 ? (
                    <p style={{
                      fontSize: '14px',
                      marginTop: '3px',
                      marginBottom: '0px'
                    }}>
                      minus mutually agreed upon expenses
                    </p>
                  ) : null}
                  {cashBonuses.length === 1 ? (
                    <p style={{
                      fontSize: '14px',
                      marginTop: '14px',
                      marginBottom: '0px'
                    }}>
                      {
                        cashBonuses
                          .map((b) => `At ${b.ticket_count_requirement.toLocaleString()} tickets, a ${formatter.format(b.dollar_amount)} bonus will be paid.`)
                          .join(" ")
                      }
                    </p>
                  ) : cashBonuses.length > 1 ? (
                    <ol style={{
                      fontSize: '14px',
                      marginTop: '14px',
                      marginBottom: '0px'
                    }}>
                      {cashBonuses.map((b, index) =>
                        <li key={index}>
                          {`At ${b.ticket_count_requirement.toLocaleString()} tickets, a ${formatter.format(b.dollar_amount)} bonus will be paid.`}
                        </li>
                      )}
                    </ol>
                  ) : null}
                  {percentageBonuses.length === 1 ? (
                    <p style={{
                      fontSize: '14px',
                      marginTop: '14px',
                      marginBottom: '0px'
                    }}>
                      {
                        percentageBonuses
                          .map((b, index) => {
                            var nextBonus = percentageBonuses[index + 1];
                            var endOfSentence = ".";

                            if(nextBonus){
                              endOfSentence = ` up to ${(nextBonus.ticket_count_requirement - 1).toLocaleString()} tickets.`;
                            }

                            return `At ${b.ticket_count_requirement.toLocaleString()} tickets sold, the deal converts to ${parseFloat(b.upside_percentage).toFixed(2)}% of the NBOR retro to dollar one, minus mutually agreed upon expenses${endOfSentence}`
                          })
                          .join(" ")
                      }
                    </p>
                  ) : percentageBonuses.length > 1 ? (
                    <ol style={{
                      fontSize: '14px',
                      marginTop: '14px',
                      marginBottom: '0px'
                    }}>
                      {percentageBonuses
                        .map((b, index) => {
                          var nextBonus = percentageBonuses[index + 1];
                          var endOfSentence = ".";

                          if(nextBonus){
                            endOfSentence = ` up to ${(nextBonus.ticket_count_requirement - 1).toLocaleString()} tickets.`;
                          }

                          return (
                            <li key={index}>
                              {`At ${b.ticket_count_requirement.toLocaleString()} tickets sold, the deal converts to ${parseFloat(b.upside_percentage).toFixed(2)}% of the NBOR retro to dollar one, minus mutually agreed upon expenses${endOfSentence}`}
                            </li>
                          )
                        })}
                    </ol>
                  ) : null}
                </div>
              </td>
            </tr>
          </>
        ) : null}
        <tr>
          <td colSpan={2} style={{"background": "#333333"}}>
            <p style={{"fontSize": "16px", "color": "white", "margin": "8px 15px"}}>
              {isSettling ? (
                <strong>Settlement</strong>
              ) : (
                <strong>Offer</strong>
              )}
            </p>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div style={{"marginTop": "3px"}}>
              <BudgetLineItemsTable items={filterBudgetLineItems(calculated.ticketsSection.items)}
                                    title={"Tickets"}
                                    tableColgroup={isSettling ? (
                                      tableColgroup(isSettling, requestFormat, showCurrencyConversion)
                                    ) : (
                                      ticketTableColgroup(isSettling, requestFormat, showCurrencyConversion)
                                    )}
                                    showCurrencyConversion={showCurrencyConversion}
                                    globalCurrency={globalCurrency}
                                    itemToEdit={itemToEdit}
                                    itemToEditChanged={itemToEditChanged}
                                    editedDropCountChanged={editedDropCountChanged}
                                    deleteSettlementOverride={deleteSettlementOverride}
                                    calculated={calculated}
                                    formatCurrency={formatCurrency}
                                    numberWithCommas={numberWithCommas}
                                    csrfToken={csrfToken}
                                    team={team}
                                    onClickAddSettlementLineItem={
                                      (e) => {
                                        e.preventDefault();

                                        createSettlementLineItem(csrfToken, team, {
                                          offer_id: offerId,
                                          section: "Tickets"
                                        });
                                      }
                                    }
                                    deleteSettlementLineItem={deleteSettlementLineItem}
                                    isSettling={isSettling}
                                    requestFormat={requestFormat}
                                    leftHandTitle={"PRICE"}
                                    rightHandTitle={"SOLD"}
                                    totalTitle={"GROSS"}
                                    totalRowLeftHand={"Total Ticket Revenue"}
                                    totalRowRightHand={numberWithCommas(calculated.ticketsSection.rightTotal)}
                                    offerId={offerId}
                                    setBudgetState={setBudgetState}
                                    totalRowTotal={calculated.ticketsSection.total}>
              </BudgetLineItemsTable>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div style={{"marginTop": "3px"}}>
              <BudgetLineItemsTable items={filterBudgetLineItems(calculated.revenueSection.items)}
                                    title={"Other Revenue"}
                                    tableColgroup={tableColgroup(isSettling, requestFormat, showCurrencyConversion)}
                                    showCurrencyConversion={showCurrencyConversion}
                                    globalCurrency={globalCurrency}
                                    itemToEdit={itemToEdit}
                                    itemToEditChanged={itemToEditChanged}
                                    editedDropCountChanged={editedDropCountChanged}
                                    deleteSettlementOverride={deleteSettlementOverride}
                                    calculated={calculated}
                                    formatCurrency={formatCurrency}
                                    numberWithCommas={numberWithCommas}
                                    csrfToken={csrfToken}
                                    team={team}
                                    onClickAddSettlementLineItem={
                                      (e) => {
                                        e.preventDefault();

                                        createSettlementLineItem(csrfToken, team, {
                                          offer_id: offerId,
                                          section: "Revenue"
                                        });
                                      }
                                    }
                                    deleteSettlementLineItem={deleteSettlementLineItem}
                                    isSettling={isSettling}
                                    requestFormat={requestFormat}
                                    leftHandTitle={"RATE"}
                                    rightHandTitle={"QUANTITY"}
                                    totalTitle={"GROSS"}
                                    totalRowLeftHand={"Total Other Revenue"}
                                    totalRowRightHand={numberWithCommas(calculated.revenueSection.rightTotal)}
                                    offerId={offerId}
                                    setBudgetState={setBudgetState}
                                    totalRowTotal={calculated.revenueSection.total}>
              </BudgetLineItemsTable>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div style={{"marginTop": "20px"}}>
              <BudgetLineItemsTable items={filterBudgetLineItems(calculated.beforeAdjustedDeductionsSection.items)}
                                    title={"Before Adjusted Deductions"}
                                    tableColgroup={tableColgroup(isSettling, requestFormat, showCurrencyConversion)}
                                    showCurrencyConversion={showCurrencyConversion}
                                    globalCurrency={globalCurrency}
                                    itemToEdit={itemToEdit}
                                    itemToEditChanged={itemToEditChanged}
                                    editedDropCountChanged={editedDropCountChanged}
                                    deleteSettlementOverride={deleteSettlementOverride}
                                    calculated={calculated}
                                    formatCurrency={formatCurrency}
                                    numberWithCommas={numberWithCommas}
                                    csrfToken={csrfToken}
                                    team={team}
                                    onClickAddSettlementLineItem={
                                      (e) => {
                                        e.preventDefault();

                                        createSettlementLineItem(csrfToken, team, {
                                          offer_id: offerId,
                                          section: "Before Adjusted Deductions"
                                        });
                                      }
                                    }
                                    deleteSettlementLineItem={deleteSettlementLineItem}
                                    isSettling={isSettling}
                                    requestFormat={requestFormat}
                                    leftHandTitle={"RATE"}
                                    rightHandTitle={"AMOUNT"}
                                    totalTitle={"TOTAL"}
                                    totalRowLeftHand={"Total Before Adjusted Deductions"}
                                    offerId={offerId}
                                    setBudgetState={setBudgetState}
                                    totalRowTotal={calculated.beforeAdjustedDeductionsSection.total}>
                <tr>
                  <td colSpan={3}
                      style={{
                        "textAlign": "right",
                        "padding": "3px 9px 3px 0px",
                        "borderTop": "2px solid #333333"
                      }}>
                    <strong style={{"fontSize": "16px"}}>
                      Subtotal
                    </strong>
                  </td>
                  {showCurrencyConversion ? (
                    <td colSpan={1}
                        style={{
                          "textAlign": "right",
                          "padding": "3px 9px 3px 0px",
                          "borderTop": "2px solid #333333"
                        }}>
                      <strong style={{"fontSize": "16px"}}>
                        {globalCurrency.formatted(calculated.showSummary.netBoxOfficeReceipts)}
                      </strong>
                    </td>
                  ) : null}
                  <td colSpan={1}
                      style={{
                        "textAlign": "right",
                        "padding": "3px 15px 3px 0px",
                        "borderTop": "2px solid #333333"
                      }}>
                    <strong style={{"fontSize": "16px"}}>
                      {formatCurrency(calculated.showSummary.netBoxOfficeReceipts)}
                    </strong>
                  </td>
                  <td style={{
                        "textAlign": "right",
                        "padding": "3px 0px",
                        "borderTop": "2px solid #333333"
                      }}>
                    &nbsp;
                  </td>
                </tr>
              </BudgetLineItemsTable>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div style={{"marginTop": "3px"}}>
              <BudgetLineItemsTable items={filterBudgetLineItems(calculated.expensesSection.items.concat(calculated.finalExpensesSection.items))}
                                    title={"Expenses"}
                                    tableColgroup={tableColgroup(isSettling, requestFormat, showCurrencyConversion)}
                                    showCurrencyConversion={showCurrencyConversion}
                                    globalCurrency={globalCurrency}
                                    itemToEdit={itemToEdit}
                                    itemToEditChanged={itemToEditChanged}
                                    editedDropCountChanged={editedDropCountChanged}
                                    deleteSettlementOverride={deleteSettlementOverride}
                                    calculated={calculated}
                                    formatCurrency={formatCurrency}
                                    numberWithCommas={numberWithCommas}
                                    csrfToken={csrfToken}
                                    team={team}
                                    onClickAddSettlementLineItem={
                                      (e) => {
                                        e.preventDefault();

                                        createSettlementLineItem(csrfToken, team, {
                                          offer_id: offerId,
                                          section: "Expenses"
                                        });
                                      }
                                    }
                                    settlementLineItems={
                                      budget.settlement_line_items.filter((sli) => sli.section === "Revenue")
                                    }
                                    deleteSettlementLineItem={deleteSettlementLineItem}
                                    isSettling={isSettling}
                                    requestFormat={requestFormat}
                                    leftHandTitle={"RATE"}
                                    rightHandTitle={"QUANTITY"}
                                    totalTitle={"TOTAL"}
                                    totalRowLeftHand={"Total Expenses"}
                                    offerId={offerId}
                                    setBudgetState={setBudgetState}
                                    totalRowTotal={calculated.expensesSection.total + calculated.finalExpensesSection.total}>
                <tr>
                  <td colSpan={3}
                      style={{
                        "textAlign": "right",
                        "padding": "3px 9px 3px 0px",
                        "borderTop": "2px solid #333333"
                      }}>
                    <strong style={{"fontSize": "16px"}}>
                      Subtotal
                    </strong>
                  </td>
                  {showCurrencyConversion ? (
                    <td colSpan={1}
                        style={{
                          "textAlign": "right",
                          "padding": "3px 9px 3px 0px",
                          "borderTop": "2px solid #333333"
                        }}>
                      <strong style={{"fontSize": "16px"}}>
                        {`-${globalCurrency.formatted(calculated.expensesSection.total)}`}
                      </strong>
                    </td>
                  ) : null}
                  <td colSpan={1}
                      style={{
                        "textAlign": "right",
                        "padding": "3px 15px 3px 0px",
                        "borderTop": "2px solid #333333"
                      }}>
                    <strong style={{"fontSize": "16px"}}>
                      {`-${formatCurrency(calculated.expensesSection.total + calculated.finalExpensesSection.total)}`}
                    </strong>
                  </td>
                  <td style={{
                        "textAlign": "right",
                        "padding": "3px 0px",
                        "borderTop": "2px solid #333333"
                      }}>
                    &nbsp;
                  </td>
                </tr>
              </BudgetLineItemsTable>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div style={{"marginTop": "15px"}}>
              <table style={{"width": "100%", "borderSpacing": "0px"}}>
                {tableColgroup(isSettling, requestFormat, showCurrencyConversion)}
                <tbody style={{"fontSize": "12px"}}>
                  <tr>
                    <td style={{"padding": "5px 9px 5px 15px", "background": "#f2f2f2"}}
                        colSpan={2}>
                      <strong style={{"fontSize": "16px"}}>
                        Show Summary
                      </strong>
                    </td>
                    <td colSpan={3}>&nbsp;</td>
                  </tr>
                  {isSettling ? (
                    <>
                      <tr>
                        <td colSpan={2}
                            style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                          <table style={{"width": "100%"}}>
                            {summaryTableColgroup(showCurrencyConversion)}
                            <tbody>
                              <tr>
                                <td>Total Tickets Sold</td>
                                {showCurrencyConversion ? (
                                  <td style={{"textAlign": "right"}}>
                                    &nbsp;
                                  </td>
                                ) : null}
                                <td style={{"textAlign": "right"}}>
                                  {numberWithCommas(calculated.ticketsSection.rightTotal)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan={3}>&nbsp;</td>
                      </tr>
                      <tr>
                        <td colSpan={2}
                            style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                          <table style={{"width": "100%"}}>
                            {summaryTableColgroup(showCurrencyConversion)}
                            <tbody>
                              <tr>
                                <td>Comps</td>
                                {showCurrencyConversion ? (
                                  <td style={{"textAlign": "right"}}>
                                    &nbsp;
                                  </td>
                                ) : null}
                                <td style={{"textAlign": "right"}}>
                                  {editedCompCount !== null ? (
                                    <MaskedInput style={{
                                                    "fontSize": "12px",
                                                    "border": "none",
                                                    "width": "100%",
                                                    "padding": "3px 0px",
                                                    "textAlign": "right"
                                                  }}
                                                  autoFocus={true}
                                                  mask={createNumberMask({
                                                    prefix: "",
                                                    decimalLimit: 0
                                                  })}
                                                  onChange={
                                                    (e) => {
                                                      editedCompCountChanged(e.target.value);
                                                    }
                                                  }
                                                  value={editedCompCount || ""} />
                                  ) : (
                                    numberWithCommas(finalCompCount(budget, confirm))
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan={3}>
                          {requestFormat === "html" ? (
                            <div style={{"marginLeft": "3px"}}>
                              {editedCompCount !== null ? (
                                <>
                                  <a href="#"
                                      style={{"color": "#999999", "marginRight": "3px"}}
                                      title={"Cancel"}
                                      onClick={
                                        (e) => {
                                          e.preventDefault();
                                          editedCompCountChanged(null);
                                        }
                                      }>
                                    <i aria-hidden className="fas fa-times-circle fa-fw"></i>
                                  </a>
                                  {budget.comp_count_override !== null ? (
                                    <a href="#"
                                        style={{"color": "#FF595E", "marginRight": "3px"}}
                                        title={"Delete"}
                                        onClick={
                                          (e) => {
                                            e.preventDefault();
                                            saveOverrides(csrfToken, team, offerId, budget, { comp_count_override: "" });
                                          }
                                        }>
                                      <i aria-hidden className="fas fa-trash-alt fa-fw"></i>
                                    </a>
                                  ) : null}
                                  <button style={{
                                            "border": "none",
                                            "cursor": "pointer",
                                            "padding": "0px",
                                            "background": "white"
                                          }}
                                          title={"Save"}
                                          type="submit">
                                    <i aria-hidden
                                        style={{"color": "#1982C4"}}
                                        className="fas fa-check-circle fa-fw"></i>
                                  </button>
                                </>
                              ) : (
                                <a href="#"
                                    onClick={
                                      (e) => {
                                        e.preventDefault();

                                        var compCount = finalCompCount(budget, confirm);
                                        editedCompCountChanged(compCount.toString());
                                        itemToEditChanged({});
                                      }
                                    }
                                    style={{"color": "#999999"}}>
                                  <i aria-hidden className="fa fa-pencil fa-fw"></i>
                                </a>
                              )}
                            </div>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}
                            style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                          <table style={{"width": "100%"}}>
                            {summaryTableColgroup(showCurrencyConversion)}
                            <tbody>
                              <tr>
                                <td>Kills</td>
                                {showCurrencyConversion ? (
                                  <td style={{"textAlign": "right"}}>
                                    &nbsp;
                                  </td>
                                ) : null}
                                <td style={{"textAlign": "right"}}>
                                  {editedKillCount !== null ? (
                                    <MaskedInput style={{
                                                    "fontSize": "12px",
                                                    "border": "none",
                                                    "width": "100%",
                                                    "padding": "3px 0px",
                                                    "textAlign": "right"
                                                  }}
                                                  autoFocus={true}
                                                  mask={createNumberMask({
                                                    prefix: "",
                                                    decimalLimit: 0
                                                  })}
                                                  onChange={
                                                    (e) => {
                                                      editedKillCountChanged(e.target.value);
                                                    }
                                                  }
                                                  value={editedKillCount || ""} />
                                  ) : (
                                    numberWithCommas(finalKillCount(budget, confirm))
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan={3}>
                          {requestFormat === "html" ? (
                            <div style={{"marginLeft": "3px"}}>
                              {editedKillCount !== null ? (
                                <>
                                  <a href="#"
                                      style={{"color": "#999999", "marginRight": "3px"}}
                                      title={"Cancel"}
                                      onClick={
                                        (e) => {
                                          e.preventDefault();
                                          editedKillCountChanged(null);
                                        }
                                      }>
                                    <i aria-hidden className="fas fa-times-circle fa-fw"></i>
                                  </a>
                                  {budget.kill_count_override !== null ? (
                                    <a href="#"
                                        style={{"color": "#FF595E", "marginRight": "3px"}}
                                        title={"Delete"}
                                        onClick={
                                          (e) => {
                                            e.preventDefault();
                                            saveOverrides(csrfToken, team, offerId, budget, { kill_count_override: "" });
                                          }
                                        }>
                                      <i aria-hidden className="fas fa-trash-alt fa-fw"></i>
                                    </a>
                                  ) : null}
                                  <button style={{
                                            "border": "none",
                                            "cursor": "pointer",
                                            "padding": "0px",
                                            "background": "white"
                                          }}
                                          title={"Save"}
                                          type="submit">
                                    <i aria-hidden
                                        style={{"color": "#1982C4"}}
                                        className="fas fa-check-circle fa-fw"></i>
                                  </button>
                                </>
                              ) : (
                                <a href="#"
                                    onClick={
                                      (e) => {
                                        e.preventDefault();

                                        var killCount = finalKillCount(budget, confirm);
                                        editedKillCountChanged(killCount.toString());
                                        itemToEditChanged({});
                                      }
                                    }
                                    style={{"color": "#999999"}}>
                                  <i aria-hidden className="fa fa-pencil fa-fw"></i>
                                </a>
                              )}
                            </div>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}
                            style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                          <table style={{"width": "100%"}}>
                            {summaryTableColgroup(showCurrencyConversion)}
                            <tbody>
                              <tr>
                                <td>Drop Count</td>
                                {showCurrencyConversion ? (
                                  <td style={{"textAlign": "right"}}>
                                    &nbsp;
                                  </td>
                                ) : null}
                                <td style={{"textAlign": "right"}}>
                                  {editedDropCount !== null ? (
                                    <MaskedInput style={{
                                                    "fontSize": "12px",
                                                    "border": "none",
                                                    "width": "100%",
                                                    "padding": "3px 0px",
                                                    "textAlign": "right"
                                                  }}
                                                  autoFocus={true}
                                                  mask={createNumberMask({
                                                    prefix: "",
                                                    decimalLimit: 0
                                                  })}
                                                  onChange={
                                                    (e) => {
                                                      editedDropCountChanged(e.target.value);
                                                    }
                                                  }
                                                  value={editedDropCount || ""} />
                                  ) : (
                                    numberWithCommas(finalDropCount(budget, confirm))
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan={3}>
                          {requestFormat === "html" ? (
                            <div style={{"marginLeft": "3px"}}>
                              {editedDropCount !== null ? (
                                <>
                                  <a href="#"
                                      style={{"color": "#999999", "marginRight": "3px"}}
                                      title={"Cancel"}
                                      onClick={
                                        (e) => {
                                          e.preventDefault();
                                          editedDropCountChanged(null);
                                        }
                                      }>
                                    <i aria-hidden className="fas fa-times-circle fa-fw"></i>
                                  </a>
                                  {budget.drop_count_override !== null ? (
                                    <a href="#"
                                        style={{"color": "#FF595E", "marginRight": "3px"}}
                                        title={"Delete"}
                                        onClick={
                                          (e) => {
                                            e.preventDefault();
                                            saveOverrides(csrfToken, team, offerId, budget, { drop_count_override: "" });
                                          }
                                        }>
                                      <i aria-hidden className="fas fa-trash-alt fa-fw"></i>
                                    </a>
                                  ) : null}
                                  <button style={{
                                            "border": "none",
                                            "cursor": "pointer",
                                            "padding": "0px",
                                            "background": "white"
                                          }}
                                          title={"Save"}
                                          type="submit">
                                    <i aria-hidden
                                        style={{"color": "#1982C4"}}
                                        className="fas fa-check-circle fa-fw"></i>
                                  </button>
                                </>
                              ) : (
                                <a href="#"
                                    onClick={
                                      (e) => {
                                        e.preventDefault();

                                        var dropCount = finalDropCount(budget, confirm);
                                        editedDropCountChanged(dropCount.toString());
                                        itemToEditChanged({});
                                      }
                                    }
                                    style={{"color": "#999999"}}>
                                  <i aria-hidden className="fa fa-pencil fa-fw"></i>
                                </a>
                              )}
                            </div>
                          ) : (
                            <span>&nbsp;</span>
                          )}
                        </td>
                      </tr>
                    </>
                  ) : null}
                  <tr>
                    <td colSpan={2}
                        style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                      <table style={{"width": "100%"}}>
                        {summaryTableColgroup(showCurrencyConversion)}
                        <tbody>
                          <tr>
                            <td>Net Box Office Receipts</td>
                            {showCurrencyConversion ? (
                              <td style={{"textAlign": "right"}}>
                                {globalCurrency.formatted(calculated.showSummary.netBoxOfficeReceipts)}
                              </td>
                            ) : null}
                            <td style={{"textAlign": "right"}}>
                              {formatCurrency(calculated.showSummary.netBoxOfficeReceipts)}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <td colSpan={3}>&nbsp;</td>
                  </tr>
                  {calculated.expensesSection.total > 0 ? (
                    <>
                      <tr>
                        <td colSpan={2}
                            style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                          <table style={{"width": "100%"}}>
                            {summaryTableColgroup(showCurrencyConversion)}
                            <tbody>
                              <tr>
                                <td>Expenses</td>
                                {showCurrencyConversion ? (
                                  <td style={{"textAlign": "right"}}>
                                    {`-${globalCurrency.formatted(calculated.showSummary.expenses)}`}
                                  </td>
                                ) : null}
                                <td style={{"textAlign": "right"}}>
                                  {`-${formatCurrency(calculated.showSummary.expenses)}`}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan={3}>&nbsp;</td>
                      </tr>
                      <tr>
                        <td colSpan={2}
                            style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                          <table style={{"width": "100%"}}>
                            {summaryTableColgroup(showCurrencyConversion)}
                            <tbody>
                              <tr>
                                <td>Show Net, After Expenses</td>
                                {showCurrencyConversion ? (
                                  <td style={{"textAlign": "right"}}>
                                    {globalCurrency.formatted(calculated.showSummary.showNetAfterExpenses)}
                                  </td>
                                ) : null}
                                <td style={{"textAlign": "right"}}>
                                  {formatCurrency(calculated.showSummary.showNetAfterExpenses)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan={3}>&nbsp;</td>
                      </tr>
                    </>
                  ) : null}
                  {calculated.finalExpensesSection.total > 0 ? (
                    <>
                      <tr>
                        <td colSpan={2}
                            style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                          <table style={{"width": "100%"}}>
                            {summaryTableColgroup(showCurrencyConversion)}
                            <tbody>
                              <tr>
                                <td>Final Expenses</td>
                                {showCurrencyConversion ? (
                                  <td style={{"textAlign": "right"}}>
                                    {`-${globalCurrency.formatted(calculated.finalExpensesSection.total)}`}
                                  </td>
                                ) : null}
                                <td style={{"textAlign": "right"}}>
                                  {`-${formatCurrency(calculated.finalExpensesSection.total)}`}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan={3}>&nbsp;</td>
                      </tr>
                      <tr>
                        <td colSpan={2}
                            style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                          <table style={{"width": "100%"}}>
                            {summaryTableColgroup(showCurrencyConversion)}
                            <tbody>
                              <tr>
                                <td>Show Net, After Final Expenses</td>
                                {showCurrencyConversion ? (
                                  <td style={{"textAlign": "right"}}>
                                    {globalCurrency.formatted(calculated.showSummary.showNetAfterFinalExpenses)}
                                  </td>
                                ) : null}
                                <td style={{"textAlign": "right"}}>
                                  {formatCurrency(calculated.showSummary.showNetAfterFinalExpenses)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan={3}>&nbsp;</td>
                      </tr>
                    </>
                  ) : null}
                  {calculated.showSummary.promoterProfit > 0 ? (
                    <>
                      <tr>
                        <td colSpan={2}
                            style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                          <table style={{"width": "100%"}}>
                            {summaryTableColgroup(showCurrencyConversion)}
                            <tbody>
                              <tr>
                                <td>Promoter Profit</td>
                                {showCurrencyConversion ? (
                                  <td style={{"textAlign": "right"}}>
                                    {`-${globalCurrency.formatted(calculated.showSummary.promoterProfit)}`}
                                  </td>
                                ) : null}
                                <td style={{"textAlign": "right"}}>
                                  {`-${formatCurrency(calculated.showSummary.promoterProfit)}`}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan={3}>&nbsp;</td>
                      </tr>
                      <tr>
                        <td colSpan={2}
                            style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                          <table style={{"width": "100%"}}>
                            {summaryTableColgroup(showCurrencyConversion)}
                            <tbody>
                              <tr>
                                <td>Show Net, After Promoter Profit</td>
                                {showCurrencyConversion ? (
                                  <td style={{"textAlign": "right"}}>
                                    {globalCurrency.formatted(calculated.showSummary.showNetAfterPromoterProfit)}
                                  </td>
                                ) : null}
                                <td style={{"textAlign": "right"}}>
                                  {formatCurrency(calculated.showSummary.showNetAfterPromoterProfit)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan={3}>&nbsp;</td>
                      </tr>
                    </>
                  ) : null}
                  {budget.dollar_or_percentage_operator === 'AND' ? (
                    <>
                      <tr>
                        <td colSpan={2}
                            style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                          <table style={{"width": "100%"}}>
                            {summaryTableColgroup(showCurrencyConversion)}
                            <tbody>
                              <tr>
                                <td>
                                  {budget.artist ? (
                                    <span>{budget.artist.name} Guarantee</span>
                                  ) : (
                                    <span>Artist Guarantee</span>
                                  )}
                                </td>
                                {showCurrencyConversion ? (
                                  <td style={{"textAlign": "right"}}>
                                    {globalCurrency.formatted(calculated.showSummary.artistGuarantee)}
                                  </td>
                                ) : null}
                                <td style={{"textAlign": "right"}}>
                                  {formatCurrency(calculated.showSummary.artistGuarantee)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan={3}>&nbsp;</td>
                      </tr>
                      {calculated.showSummary.upsidePercentage > 0 ? (
                        <tr>
                          <td colSpan={2}
                              style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                            <table style={{"width": "100%"}}>
                              {summaryTableColgroup(showCurrencyConversion)}
                              <tbody>
                                <tr>
                                  <td>To Be Shared Amount</td>
                                  {showCurrencyConversion ? (
                                    <td style={{"textAlign": "right"}}>
                                      {globalCurrency.formatted(calculated.showSummary.toBeSharedAmount)}
                                    </td>
                                  ) : null}
                                  <td style={{"textAlign": "right"}}>
                                    {formatCurrency(calculated.showSummary.toBeSharedAmount)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td colSpan={3}>&nbsp;</td>
                        </tr>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {calculated.showSummary.upsidePercentage > 0 ? (
                        <tr>
                          <td colSpan={2}
                              style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                            <table style={{"width": "100%"}}>
                              {summaryTableColgroup(showCurrencyConversion)}
                              <tbody>
                                <tr>
                                  <td>To Be Shared Amount</td>
                                  {showCurrencyConversion ? (
                                    <td style={{"textAlign": "right"}}>
                                      {globalCurrency.formatted(calculated.showSummary.toBeSharedAmount)}
                                    </td>
                                  ) : null}
                                  <td style={{"textAlign": "right"}}>
                                    {formatCurrency(calculated.showSummary.toBeSharedAmount)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                          <td colSpan={3}>&nbsp;</td>
                        </tr>
                      ) : null}
                      <tr>
                        <td colSpan={2}
                            style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                          <table style={{"width": "100%"}}>
                            {summaryTableColgroup(showCurrencyConversion)}
                            <tbody>
                              <tr>
                                <td>
                                  {budget.artist ? (
                                    <span>{budget.artist.name} Guarantee</span>
                                  ) : (
                                    <span>Artist Guarantee</span>
                                  )}
                                </td>
                                {showCurrencyConversion ? (
                                  <td style={{"textAlign": "right"}}>
                                    {globalCurrency.formatted(calculated.showSummary.artistGuarantee)}
                                  </td>
                                ) : null}
                                <td style={{"textAlign": "right"}}>
                                  {formatCurrency(calculated.showSummary.artistGuarantee)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                        <td colSpan={3}>&nbsp;</td>
                      </tr>
                    </>
                  )}
                  {calculated.showSummary.upsidePercentage > 0 ? (
                    <tr>
                      <td colSpan={2}
                          style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                        <table style={{"width": "100%"}}>
                          {summaryTableColgroup(showCurrencyConversion)}
                          <tbody>
                            <tr>
                              <td>{artistPercentageDescription(budget, calculated)}</td>
                              {showCurrencyConversion ? (
                                <td style={{"textAlign": "right"}}>
                                  {globalCurrency.formatted(calculated.showSummary.artistPercentage)}
                                </td>
                              ) : null}
                              <td style={{"textAlign": "right"}}>
                                {formatCurrency(calculated.showSummary.artistPercentage)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td colSpan={3}>&nbsp;</td>
                    </tr>
                  ) : null}
                  <tr>
                    <td colSpan={2} style={{"padding": "0px", "background": "#f2f2f2"}}>&nbsp;</td>
                    <td colSpan={2} style={{"padding": "0px"}}>&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div style={{"marginTop": "12px"}}>
              <BudgetLineItemsTable items={filterBudgetLineItems(calculated.additionsSection.items)}
                                    title={"Additions"}
                                    tableColgroup={tableColgroup(isSettling, requestFormat, showCurrencyConversion)}
                                    showCurrencyConversion={showCurrencyConversion}
                                    globalCurrency={globalCurrency}
                                    itemToEdit={itemToEdit}
                                    itemToEditChanged={itemToEditChanged}
                                    editedDropCountChanged={editedDropCountChanged}
                                    deleteSettlementOverride={deleteSettlementOverride}
                                    calculated={calculated}
                                    formatCurrency={formatCurrency}
                                    numberWithCommas={numberWithCommas}
                                    csrfToken={csrfToken}
                                    team={team}
                                    onClickAddSettlementLineItem={
                                      (e) => {
                                        e.preventDefault();

                                        createSettlementLineItem(csrfToken, team, {
                                          offer_id: offerId,
                                          section: "Additions"
                                        });
                                      }
                                    }
                                    deleteSettlementLineItem={deleteSettlementLineItem}
                                    isSettling={isSettling}
                                    requestFormat={requestFormat}
                                    leftHandTitle={"RATE"}
                                    rightHandTitle={"QUANTITY"}
                                    totalTitle={"TOTAL"}
                                    totalRowLeftHand={"Total Additions"}
                                    offerId={offerId}
                                    setBudgetState={setBudgetState}
                                    totalRowTotal={calculated.additionsSection.total}>
              </BudgetLineItemsTable>
            </div>
          </td>
        </tr>
        <tr>
          <td colSpan={2}>
            <div style={{"marginTop": "16px"}}>
              <BudgetLineItemsTable items={filterBudgetLineItems(calculated.deductionsSection.items)}
                                    title={"Deductions"}
                                    tableColgroup={tableColgroup(isSettling, requestFormat, showCurrencyConversion)}
                                    showCurrencyConversion={showCurrencyConversion}
                                    globalCurrency={globalCurrency}
                                    itemToEdit={itemToEdit}
                                    itemToEditChanged={itemToEditChanged}
                                    editedDropCountChanged={editedDropCountChanged}
                                    deleteSettlementOverride={deleteSettlementOverride}
                                    calculated={calculated}
                                    formatCurrency={formatCurrency}
                                    numberWithCommas={numberWithCommas}
                                    csrfToken={csrfToken}
                                    team={team}
                                    onClickAddSettlementLineItem={
                                      (e) => {
                                        e.preventDefault();

                                        createSettlementLineItem(csrfToken, team, {
                                          offer_id: offerId,
                                          section: "Deductions"
                                        });
                                      }
                                    }
                                    deleteSettlementLineItem={deleteSettlementLineItem}
                                    isSettling={isSettling}
                                    requestFormat={requestFormat}
                                    leftHandTitle={"RATE"}
                                    rightHandTitle={"QUANTITY"}
                                    totalTitle={"TOTAL"}
                                    totalRowLeftHand={"Total Deductions"}
                                    offerId={offerId}
                                    setBudgetState={setBudgetState}
                                    totalRowTotal={calculated.deductionsSection.total}>
              </BudgetLineItemsTable>
            </div>
          </td>
        </tr>
        {!isSettling && budget.has_merchandising ? (
          <tr>
            <td colSpan={2}>
              <div style={{"marginTop": "15px", "marginBottom": "15px"}}>
                <div style={{"marginLeft": "15px", "marginBottom": "7px"}}>
                  <strong style={{"fontSize": "16px"}}>
                    Merch Split
                  </strong>
                </div>
                <table style={{"width": "100%", "marginTop": "-8px", "borderSpacing": "0px 2px", "borderCollapse": "separate"}}>
                  <colgroup>
                    <col span={"1"} width={"15%"} />
                    <col span={"1"} width={"15%"} />
                    <col span={"1"} width={"40%"} />
                    <col span={"1"} width={"15%"} />
                    <col span={"1"} width={"15%"} />
                  </colgroup>
                  <thead style={{"textAlign": "left"}}>
                    <tr style={{"color": "#B3B3B3", "fontSize": "10px"}}>
                      <th style={{"fontWeight": "normal", "padding": "0px 15px"}}>&nbsp;</th>
                      <th style={{"fontWeight": "normal", "padding": "0px 15px"}}>
                        Seller
                      </th>
                      <th style={{"fontWeight": "normal", "padding": "0px 15px"}}>
                        Notes
                      </th>
                      <th style={{"fontWeight": "normal", "padding": "0px 15px", "textAlign": "right"}}>
                        Artist
                      </th>
                      <th style={{"fontWeight": "normal", "padding": "0px 15px", "textAlign": "right"}}>
                        Venue
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{"fontSize": "12px"}}>
                    <tr>
                      <td style={{"padding": "3px 15px", "background": "#f2f2f2"}}>
                        Soft Merch
                      </td>
                      <td style={{"padding": "3px 15px", "background": "#f2f2f2"}}>
                        {budget.soft_merch_sold_by}
                      </td>
                      <td style={{"padding": "3px 15px", "background": "#f2f2f2"}}>
                        {budget.soft_merch_notes}
                      </td>
                      <td style={{"padding": "3px 15px", "background": "#f2f2f2", "textAlign": "right"}}>
                        {parseFloat(budget.soft_merch_rate) || 0}%
                      </td>
                      <td style={{"padding": "3px 15px", "background": "#f2f2f2", "textAlign": "right"}}>
                        {100.0 - parseFloat(budget.soft_merch_rate) || 0}%
                      </td>
                    </tr>
                    <tr>
                      <td style={{"padding": "3px 15px", "background": "#f2f2f2"}}>
                        Hard Merch
                      </td>
                      <td style={{"padding": "3px 15px", "background": "#f2f2f2"}}>
                        {budget.hard_merch_sold_by}
                      </td>
                      <td style={{"padding": "3px 15px", "background": "#f2f2f2"}}>
                        {budget.hard_merch_notes}
                      </td>
                      <td style={{"padding": "3px 15px", "background": "#f2f2f2", "textAlign": "right"}}>
                        {parseFloat(budget.hard_merch_rate) || 0}%
                      </td>
                      <td style={{"padding": "3px 15px", "background": "#f2f2f2", "textAlign": "right"}}>
                        {100.0 - parseFloat(budget.hard_merch_rate) || 0}%
                      </td>
                    </tr>
                    {budget.addl_merch_notes ? (
                      <tr>
                        <td colSpan="5" style={{"padding": "3px 15px"}}>
                          Additional notes: {budget.addl_merch_notes}
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        ) : null}
        <tr>
          <td colSpan={2}>
            <div style={{"marginTop": "15px", "marginBottom": "15px"}}>
              <div style={{"marginLeft": "15px", "marginBottom": "7px"}}>
                <strong style={{"fontSize": "16px"}}>
                  Artist Earnings
                </strong>
              </div>
              <table style={{"width": "100%", "borderSpacing": "0px 2px", "borderCollapse": "separate", "marginTop": "-8px"}}>
                {tableColgroup(isSettling, requestFormat, currencyConversionIsEnabled)}
                <thead style={{"textAlign": "left"}}>
                  <tr style={{"color": "#B3B3B3", "fontSize": "10px"}}>
                    <th style={{"fontWeight": "normal", "paddingLeft": "15px"}}>&nbsp;</th>
                    <th style={{"fontWeight": "normal", "textAlign": "right", "paddingRight": "9px"}}>&nbsp;</th>
                    <th style={{"fontWeight": "normal", "textAlign": "right", "paddingRight": "9px"}}>&nbsp;</th>
                    {currencyConversionIsEnabled ? (
                      <th style={{"fontWeight": "normal", "textAlign": "right", "paddingRight": "9px"}}>
                        {team.secondary_currency_symbol.toUpperCase()}
                      </th>
                    ) : null}
                    <th style={{"fontWeight": "normal", "textAlign": "right", "paddingRight": "15px"}}>
                      TOTAL
                    </th>
                    <th><span>&nbsp;</span></th>
                  </tr>
                </thead>
                <tbody style={{"fontSize": "12px"}}>
                  <tr>
                    <td style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                      Artist Guarantee and/or Percentage
                    </td>
                    <td style={{"background": "#f2f2f2", "padding": "3px 0px"}}><span>&nbsp;</span></td>
                    <td style={{"background": "#f2f2f2", "padding": "3px 0px"}}><span>&nbsp;</span></td>
                    {currencyConversionIsEnabled ? (
                      <td style={{"padding": "3px 9px 3px 0px", "background": "#f2f2f2", "textAlign": "right"}}>
                        {globalCurrency.formatted(calculated.artistEarnings.artistGuaranteeAndOrPercentage)}
                      </td>
                    ) : null}
                    <td style={{"padding": "3px 15px 3px 0px", "background": "#f2f2f2", "textAlign": "right"}}>
                      <strong>
                        {formatCurrency(calculated.artistEarnings.artistGuaranteeAndOrPercentage)}
                      </strong>
                    </td>
                    <td style={{"background": "#f2f2f2", "padding": "3px 0px"}}><span>&nbsp;</span></td>
                  </tr>
                  {calculated.additionsSection.items.length > 0 ? (
                    <tr>
                      <td style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                        Total Additions
                      </td>
                      <td style={{"background": "#f2f2f2", "padding": "3px 0px"}}><span>&nbsp;</span></td>
                      <td style={{"background": "#f2f2f2", "padding": "3px 0px"}}><span>&nbsp;</span></td>
                      {currencyConversionIsEnabled ? (
                        <td style={{"padding": "3px 9px 3px 0px", "background": "#f2f2f2", "textAlign": "right"}}>
                          {globalCurrency.formatted(calculated.artistEarnings.totalAdditions)}
                        </td>
                      ) : null}
                      <td style={{"padding": "3px 15px 3px 0px", "background": "#f2f2f2", "textAlign": "right"}}>
                        <strong>
                          {formatCurrency(calculated.artistEarnings.totalAdditions)}
                        </strong>
                      </td>
                      <td style={{"background": "#f2f2f2", "padding": "3px 0px"}}><span>&nbsp;</span></td>
                    </tr>
                  ) : null}
                  {calculated.deductionsSection.items.length > 0 ? (
                    <tr>
                      <td style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                        Total Deductions
                      </td>
                      <td style={{"background": "#f2f2f2", "padding": "3px 0px"}}><span>&nbsp;</span></td>
                      <td style={{"background": "#f2f2f2", "padding": "3px 0px"}}><span>&nbsp;</span></td>
                      {currencyConversionIsEnabled ? (
                        <td style={{"padding": "3px 9px 3px 0px", "background": "#f2f2f2", "textAlign": "right"}}>
                          {globalCurrency.formatted(calculated.artistEarnings.totalDeductions)}
                        </td>
                      ) : null}
                      <td style={{"padding": "3px 15px 3px 0px", "background": "#f2f2f2", "textAlign": "right"}}>
                        <strong>
                          {formatCurrency(calculated.artistEarnings.totalDeductions)}
                        </strong>
                      </td>
                      <td style={{"background": "#f2f2f2", "padding": "3px 0px"}}><span>&nbsp;</span></td>
                    </tr>
                  ) : null}
                  <tr style={{"fontSize": "16px"}}>
                    <td style={{"padding": "3px 9px 3px 15px", "background": "#f2f2f2"}}>
                      <strong>
                        <span>
                          {`${isSettling ? "Total Due" : "Total Potential Due"} ${budget.artist ? budget.artist.name : ""}`}
                        </span>
                      </strong>
                    </td>
                    <td style={{"background": "#f2f2f2", "padding": "3px 0px"}}><span>&nbsp;</span></td>
                    <td style={{"background": "#f2f2f2", "padding": "3px 0px"}}><span>&nbsp;</span></td>
                    {currencyConversionIsEnabled ? (
                      <td style={{"padding": "3px 9px 3px 0px", "background": "#f2f2f2", "textAlign": "right"}}>
                        {globalCurrency.formatted(calculated.artistEarnings.artistTotalDue)}
                      </td>
                    ) : null}
                    <td style={{"padding": "3px 15px 3px 0px", "background": "#f2f2f2", "textAlign": "right"}}>
                      <strong>
                        {formatCurrency(calculated.artistEarnings.artistTotalDue)}
                      </strong>
                    </td>
                    <td style={{"background": "#f2f2f2", "padding": "3px 0px"}}><span>&nbsp;</span></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
)};

SettlementTable.propTypes = {
  budget: PropTypes.object.isRequired,
  setBudgetState: PropTypes.func.isRequired,
  calculated: PropTypes.object,
  offer: PropTypes.object.isRequired,
  confirm: PropTypes.object,
  createSettlementLineItem: PropTypes.func.isRequired,
  csrfToken: PropTypes.string,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  offerId: PropTypes.string.isRequired,
  deleteSettlementLineItem: PropTypes.func.isRequired,
  itemToEdit: PropTypes.object,
  itemToEditChanged: PropTypes.func.isRequired,
  deleteSettlementOverride: PropTypes.func.isRequired,
  requestFormat: PropTypes.string,
  isSettling: PropTypes.bool,
  editedDropCount: PropTypes.string,
  editedDropCountChanged: PropTypes.func.isRequired,
  editedCompCount: PropTypes.string,
  editedCompCountChanged: PropTypes.func.isRequired,
  editedKillCount: PropTypes.string,
  editedKillCountChanged: PropTypes.func.isRequired,
  saveOverrides: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  offerChanged: PropTypes.func.isRequired,
  isSavingChanged: PropTypes.func.isRequired,
  getActiveTags: PropTypes.func.isRequired,
  getAvailableTags: PropTypes.func.isRequired,
};

export default SettlementTable;

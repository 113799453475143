import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import SemiCircle from '../../SemiCircle/components/SemiCircle';
import { toast, ToastContainer } from 'react-toastify';
var ReactDOM = require('react-dom');
import SlidingPane from "react-sliding-pane";
import Popup from "reactjs-popup";
import Select from 'react-select';

const axios = require('axios').default;

import ShowOrderSlidingState from './ShowOrderSlidingState';
import ResendEmailSlidingState from './ResendEmailSlidingState';
import RefundModal from './RefundModal';
import TableSearch from './TableSearch';
import DataTable from '../../DataTable/components/DataTable';
import Tippy from '@tippyjs/react';
import styled from "styled-components";
import EventDetailsApp from '../../EventDetails/startup/EventDetailsApp';

import {
  ResponsiveContainer,
  AreaChart,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  RadialBarChart,
  RadialBar,
} from "recharts";

import {
  TICKETS_SOLD_WIDGET_TAB,
  ADMISSION_WIDGET_TAB,
  SHOW_ORDER_SLIDING_STATE,
  RESEND_EMAIL_SLIDING_STATE
} from '../constants/confirmDashboardConstants';

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "200px",
  "padding": "0px"
}

const printTicketsContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const CustomToolTipWrapper = styled.div`
  border: none;
  border-radius: 8px;
  background: black;
  padding: 13px;
  min-width: 100px;

  p {
    padding: 0;
    color: white;
    margin: 0;
  }

  .date {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 8px;
  }

  .value {
    font-size: 14px;
    font-weight: 700;
  }

  .label {
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 8px;
  }
`;

const calculatePercentageSold = (confirm) => {
  var ticketsSold = confirm.number_of_manifested_tickets_sold;
  var venueCapacity = confirm.venue_capacity;

  if(venueCapacity === 0){
    return (ticketsSold > 0 ? 100 : 0);
  }

  return Math.ceil((ticketsSold / venueCapacity) * 100);
}

const calculatePercentageChecked = (confirm) => {
  var ticketsChecked = confirm.number_of_tickets_checked_in;
  var ticketsSold = confirm.number_of_manifested_tickets_sold;

  if(ticketsSold === 0){
    return (ticketsChecked > 0 ? 100 : 0);
  }

  return Math.ceil((ticketsChecked / ticketsSold) * 100);
}

const decodeHtml = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const useFakeSalesData = (cumulativeSalesData) => {
  return cumulativeSalesData.length < 2;
}

const generateFakeSalesData = () => {
  var numberOfDays = Math.floor(Math.random() * 10) + 10;
  var summedAmount = Math.floor(Math.random() * 3000) + 150;
  var summedNumberOfTicketsSold = Math.floor(Math.random() * 50) + 10;

  return Array.apply(null, new Array(numberOfDays))
     .map(() => new Date())
     .map((v, i) => {
       v.setDate(v.getDate() - i);
       return v;
     })
     .reverse()
     .reduce((data, date) => {
       var newAmount = Math.floor(Math.random() * 3000) + 150;
       summedAmount  = (summedAmount + newAmount);
       var month = (date.getMonth() + 1);
       var day = date.getDate();
       var year = date.getFullYear() % 100;
       var numberOfTicketsSold = Math.floor(Math.random() * 50);
       summedNumberOfTicketsSold += numberOfTicketsSold;

       data.push({
         date: (month + "/" + day + "/" + year),
         amount: summedAmount,
         number_of_tickets_sold: summedNumberOfTicketsSold
       });

       return data;
     }, []);
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    var amount = Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(payload[0].payload.amount);
    var formattedNumberOfTicketsSold = Intl.NumberFormat().format(payload[0].payload.number_of_tickets_sold)

    return (
      <CustomToolTipWrapper>
        <p className="date">{ payload[0].payload.alt_date }</p>
        <p className="value">{ `${amount}` }</p>
        <p className="label">SALES</p>
        <p className="value">{ formattedNumberOfTicketsSold }</p>
        <p className="label">TICKETS SOLD</p>
      </CustomToolTipWrapper>
    );
  }

  return null;
};

const print = (csrfToken, team, objToPrint, setObjToPrint, selectedPrinter, setSelectedPrinter) => {
  axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

  let resource;

  if(objToPrint.type === "order") {
    resource = `orders/${objToPrint.obj.id}`;
  } else if(objToPrint.type === "ticket") {
    resource = `orders/${objToPrint.obj.order.id}/tickets/${objToPrint.obj.id}`;
  }

  axios.get(`/teams/${team.id}/${resource}/print?printer_id=${selectedPrinter.value}`)
    .catch(function (error) {
      toast.error("Could not print tickets. Plese try again.", {
        position: toast.POSITION.TOP_CENTER,
        draggable: false,
        closeOnClick: false,
        autoClose: 5000,
        hideProgressBar: true
      });
    })
    .finally(function () {
      setObjToPrint(null);
      setSelectedPrinter({ value: 1, label: "Printer 1" });
    });
}

const ConfirmDashboard = ({
  csrfToken,
  team,
  currentUser,
  confirm,
  confirmChanged,
  ticketTypes,
  typedSearchTerm,
  startSearchTermUpdate,
  searchedSearchTerm,
  csvDataSource,
  dataTableEl,
  dataTableElChanged,
  order,
  orderChanged,
  sendOrderReceipt,
  widgetTab,
  widgetTabChanged,
  addOnsByPrice,
  refund,
  fetchNewRefund,
  refundChanged,
  refundReasons,
  createRefund,
  submittingRefund,
  slidingPaneState,
  slidingPaneStateChanged,
  resendEmail,
  resendEmailChanged,
  sendingConfirmationEmail,
  cumulativeSalesData,
  fakeSalesData,
  fakeSalesDataChanged,
  confirmRefund,
  refundOrder,
  ticketBuyersData,
  fetchTicketBuyersData,
  promoters,
  buyers,
  ticketTypesByPrice,
  canBypassRefundRestrictions,
  payoutInfo,
  salesData,
  sendOrderCheckIn,
  sendOrderCheckOut
}) => {
  useEffect(() => {
    if(refundOrder){
      fetchNewRefund(csrfToken, team, refundOrder);
    }

    fakeSalesDataChanged(generateFakeSalesData());
    fetchTicketBuyersData(csrfToken, team, confirm);
  }, []);

  const [selectedPrinter, setSelectedPrinter] = useState({ value: 1, label: "Printer 1"});
  const [objToPrint, setObjToPrint] = useState(null);

  const fetchOrder = (csrfToken, team, orderId) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    axios.get(`/teams/${team.id}/orders/${orderId}`)
      .then(({ data }) => {
        orderChanged(data);
      })
      .catch((error) => {
        toast.error("Could not get order data. Plese try again.", {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  }

  return (
    <div>
      <ToastContainer />
      <div className="row m-0">
        <div className="col-12">
          <div className="page-title d-flex align-items-center">
            { confirm.name }
            {confirm.calendar_classification === "past" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#919191", "color": "white", "fontSize": "10px"}}>
                Past
              </span>
            ) : confirm.calendar_classification === "canceled" ? (
              <span className="badge badge-danger mt-1 ml-3 p-2"
                    style={{"fontSize": "10px"}}>
                Canceled
              </span>
            ) : confirm.calendar_classification === "on_sale" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#5f5383", "color": "white", "fontSize": "10px"}}>
                On Sale
              </span>
            ) : confirm.calendar_classification === "announced" ? (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#98c648", "color": "white", "fontSize": "10px"}}>
                Announced
              </span>             
            ) :  (
              <span className="badge badge-secondary mt-1 ml-3 p-2"
                    style={{"background": "#4c81b9", "color": "white", "fontSize": "10px"}}>
                Confirmed
              </span>
            )}
          </div>
          <EventDetailsApp
            csrfToken={csrfToken}
            team={team}
            currentUser={currentUser}
            confirm={{...confirm}}
            confirmSaved={(savedConfirm) => {
              confirmChanged(savedConfirm);
            }}
            buyers={[...buyers]}
            promoters={promoters} />
        </div>
      </div>
      <div className="row mb-4 mx-0">
        <div className="col-12">
          <div className="card border-0 shadow-2 overflow-hidden">
            <div className="card-body"
                 style={{"padding": "24px"}}>
              <div className="row">
                <div className="col">
                  <h3 className="mb-5"
                      style={{"fontSize": "24px"}}>
                    <strong>
                      Ticket Dashboard
                    </strong>
                  </h3>
                </div>
                {confirm.calendar_classification === "on_sale" ? (
                  <div className="col-xs-auto"
                       style={{"paddingRight": "15px"}}>
                    <div className="rounded"
                         style={{'background': "#f4f9fc", "padding": "16px"}}>
                      <div className="row">
                        <div className="col-xs-auto d-flex align-items-center"
                             style={{"paddingLeft": "15px"}}>
                          <i className="far fa-ticket-alt fa-rotate-90"
                             style={{"color": "#98c648", "fontSize": "24px"}}></i>
                        </div>
                        <div className="col"
                             style={{"paddingLeft": "9px"}}>
                          <p className="mb-0 text-dark"
                             style={{"fontSize": "16px"}}>
                            <strong>On Sale</strong>
                          </p>
                          <p className="mb-0 text-dark small"
                             style={{"marginTop": "-5px"}}>
                            This event is live and on sale.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='row bordered-cols'
                   style={{
                     "marginTop": "20px",
                     "marginBottom": "25px"
                   }}>
                <div className="col-12 col-lg-8 right-border-for-lg-and-up">
                  <p className="mb-0" style={{"fontSize": "20px"}}>
                    <strong>Sales</strong>
                  </p>
                  <div className="row">
                    <div className="col-12 col-sm-auto mb-5 mb-lg-0"
                         style={{"paddingLeft": "15px", "paddingRight": "25px"}}>
                      <p className="mb-0 mt-4 text-muted">
                        <strong>
                          Venue Gross
                        </strong>
                      </p>
                      <p className="mb-0" style={{"fontSize": "24px"}}>
                        <strong>
                          {formatter.format(parseFloat(salesData.gross))}
                        </strong>
                      </p>
                      <p className="mb-0 mt-1 text-muted small">
                        <strong>
                          Tickets Gross
                        </strong>
                      </p>
                      <p className="mb-0" style={{"fontSize": "16px", "marginTop": "-4px"}}>
                        <strong>
                          {formatter.format(parseFloat(salesData.tickets))}
                        </strong>
                      </p>
                      <p className="mb-0 mt-1 text-muted small">
                        <strong>
                          Add-Ons Gross
                        </strong>
                      </p>
                      <p className="mb-0" style={{"fontSize": "16px", "marginTop": "-4px"}}>
                        <strong>
                          {formatter.format(parseFloat(salesData.add_ons))}
                        </strong>
                      </p>
                      {parseFloat(salesData.other) > 0.00 ? (
                        <React.Fragment>
                          <p className="mb-0 mt-1 text-muted small">
                            <strong>
                              Other Gross
                            </strong>
                          </p>
                          <p className="mb-0" style={{"fontSize": "16px", "marginTop": "-4px"}}>
                            <strong>
                              {formatter.format(parseFloat(salesData.other))}
                            </strong>
                          </p>
                        </React.Fragment>
                      ) : null}
                      <p className="mb-0 mt-1 text-muted small">
                        <strong>
                          Royalties
                        </strong>
                      </p>
                      <p className="mb-0" style={{"fontSize": "16px", "marginTop": "-4px"}}>
                        <strong>
                          {formatter.format(parseFloat(salesData.royalties))}
                        </strong>
                      </p>
                      {parseFloat(salesData.taxes) > 0.00 ? (
                        <React.Fragment>
                          <p className="mb-0 mt-1 text-muted small">
                            <strong>
                              Taxes
                            </strong>
                          </p>
                          <p className="mb-0" style={{"fontSize": "16px", "marginTop": "-4px"}}>
                            <strong>
                              {formatter.format(parseFloat(salesData.taxes))}
                            </strong>
                          </p>
                        </React.Fragment>
                      ) : null}
                      {parseFloat(salesData.refunds) > 0.00 ? (
                        <React.Fragment>
                          <p className="mb-0 mt-1 text-muted small">
                            <strong>
                              Refunded
                            </strong>
                          </p>
                          <p className="mb-0" style={{"fontSize": "16px", "marginTop": "-4px"}}>
                            <strong>
                              ({formatter.format(parseFloat(salesData.refunds))})
                            </strong>
                          </p>
                        </React.Fragment>
                      ) : null}
                      {parseFloat(salesData.disputes_lost) > 0.00 ? (
                        <React.Fragment>
                          <p className="mb-0 mt-1 text-muted small">
                            <strong>
                              Disputes Lost
                            </strong>
                          </p>
                          <p className="mb-0" style={{"fontSize": "16px", "marginTop": "-4px"}}>
                            <strong>
                              ({formatter.format(parseFloat(salesData.disputes_lost))})
                            </strong>
                          </p>
                        </React.Fragment>
                      ) : null}
                      {parseFloat(salesData.disputes_won) > 0.00 ? (
                        <React.Fragment>
                          <p className="mb-0 mt-1 text-muted small">
                            <strong>
                              Disputes Won
                            </strong>
                          </p>
                          <p className="mb-0" style={{"fontSize": "16px", "marginTop": "-4px"}}>
                            <strong>
                              {formatter.format(parseFloat(salesData.disputes_won))}
                            </strong>
                          </p>
                        </React.Fragment>
                      ) : null}
                      {parseFloat(salesData.adjustments) !== 0.00 ? (
                        <React.Fragment>
                          <p className="mb-0 mt-1 text-muted small">
                            <strong>
                              Adjustments
                            </strong>
                          </p>
                          <p className="mb-0" style={{"fontSize": "16px", "marginTop": "-4px"}}>
                            <strong>
                              {formatter.format(parseFloat(salesData.adjustments))}
                            </strong>
                          </p>
                        </React.Fragment>
                      ) : null}
                      <p className="mb-0 mt-1 text-muted small">
                        <strong>
                          Net
                        </strong>
                      </p>
                      <p className="mb-0" style={{"fontSize": "16px", "marginTop": "-4px"}}>
                        <strong>
                          {formatter.format(parseFloat(salesData.net))}
                        </strong>
                      </p>
                    </div>
                    <div className="col mb-5 mb-lg-0">
                      {/* https://github.com/recharts/recharts/issues/172#issuecomment-992240256 */}
                      <div className={"net-sales-chart mt-4 " + (useFakeSalesData(cumulativeSalesData) ? "placeholder" : "")}>
                        <div style={{
                              width: "100%",
                              height: "235px",
                              overflow: "hidden",
                              position: "relative",
                            }}>
                          <div style={{
                                 width: "100%",
                                 height: "100%",
                                 position: "absolute",
                                 top: 0,
                                 left: 0,
                               }}>
                            {!useFakeSalesData(cumulativeSalesData) ? (
                              <ResponsiveContainer width="100%" height="100%">
                                <AreaChart
                                  data={cumulativeSalesData}
                                  margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0
                                  }}
                                >
                                  <Tooltip content={<CustomTooltip />} />
                                  <CartesianGrid vertical={false}
                                                 stroke="#ececec"
                                                 strokeDasharray="3 3" />
                                  <XAxis dataKey="date"
                                         tickLine={false}
                                         axisLine={false} />
                                  <YAxis axisLine={false}
                                         tickFormatter={
                                           (value) => {
                                             var compact = Intl.NumberFormat('en-US', {
                                               notation: "compact",
                                               maximumFractionDigits: 1
                                             }).format(value);

                                             return ("$" + compact);
                                           }
                                         }
                                         tickLine={false} />
                                  <Area type="linear"
                                        dataKey="amount"
                                        stroke="#6A4C93"
                                        strokeWidth={2}
                                        fillOpacity={1}
                                        fill="#d5cde3" />
                                </AreaChart>
                              </ResponsiveContainer>
                            ) : (
                              <ResponsiveContainer width="100%" height="100%">
                                <AreaChart
                                  data={fakeSalesData}
                                  margin={{
                                    top: 10,
                                    right: 30,
                                    left: 0,
                                    bottom: 0
                                  }}
                                >
                                  <Tooltip content={<CustomTooltip />} />
                                  <CartesianGrid vertical={false}
                                                 stroke="#ececec"
                                                 strokeDasharray="3 3" />
                                  <XAxis dataKey="date"
                                         tickLine={false}
                                         axisLine={false} />
                                  <YAxis axisLine={false}
                                         tickFormatter={
                                           (value) => {
                                             var compact = Intl.NumberFormat('en-US', {
                                               notation: "compact",
                                               maximumFractionDigits: 1
                                             }).format(value);

                                             return ("$" + compact);
                                           }
                                         }
                                         tickLine={false} />
                                  <Area type="linear"
                                        dataKey="amount"
                                        stroke="#e5e7eb"
                                        strokeWidth={2}
                                        fillOpacity={1}
                                        fill="#f9fafb" />
                                </AreaChart>
                              </ResponsiveContainer>
                            )}
                          </div>
                          {useFakeSalesData(cumulativeSalesData) ? (
                            <div className="position-absolute"
                                 style={{
                                   "left": "50%",
                                   "top": "40%"
                                 }}>
                              <div style={{
                                     "position": "relative",
                                     "left": "-50%",
                                     "top": "-40%"
                                   }}>
                                <p className="mb-0 text-dark"
                                   style={{
                                     "fontSize": "16px"
                                   }}>
                                  <strong>Not enough data yet</strong>
                                </p>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-4 extra-left-paddin-lg-and-up">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a href="#"
                         onClick={
                           (e) => {
                             e.preventDefault();
                             widgetTabChanged(TICKETS_SOLD_WIDGET_TAB);
                           }
                         }
                         className={"nav-link px-4 " + (widgetTab === TICKETS_SOLD_WIDGET_TAB ? "active" : "")}>
                        <strong>Tickets Sold</strong>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="#"
                         onClick={
                           (e) => {
                             e.preventDefault();
                             widgetTabChanged(ADMISSION_WIDGET_TAB);
                           }
                         }
                         className={"nav-link px-4 " + (widgetTab === ADMISSION_WIDGET_TAB ? "active" : "")}>
                        <strong>Admission</strong>
                      </a>
                    </li>
                  </ul>
                  {widgetTab === TICKETS_SOLD_WIDGET_TAB ? (
                    <div>
                      <div className="row px-2"
                           style={{"paddingTop": "38px"}}>
                        <div className="col-12 col-lg-4 d-flex justify-content-between flex-row flex-lg-column">
                          <dl>
                            <dt className="text-muted">
                              <strong className="text-no-wrap">Sold</strong>
                            </dt>
                            <dd style={{"marginTop": "-5px"}}>
                              <strong className="text-no-wrap"
                                      style={{"fontSize": "24px"}}>
                                {confirm.number_of_manifested_tickets_sold}
                              </strong>
                            </dd>
                          </dl>
                          <dl>
                            <dt className="text-muted">
                              <strong className="text-no-wrap">Capacity</strong>
                            </dt>
                            <dd style={{"marginTop": "-5px"}}>
                              <strong className="text-no-wrap"
                                      style={{"fontSize": "24px"}}>
                                {confirm.venue_capacity}
                              </strong>
                            </dd>
                          </dl>
                        </div>
                        <div className="col-12 col-lg-8 d-flex justify-content-center"
                             style={{"paddingBottom": "35px"}}>
                          <SemiCircle wrapperClassName="align-self-end"
                                      percentage={calculatePercentageSold(confirm)} />
                        </div>
                      </div>

                      {currentUser.can_see_unmanifested_tickets ? (
                        <div className="row">
                          <div className="col-12">
                            <div className="card border-0 rounded-0"
                                 style={{
                                   "background": "#f9f9f9",
                                   "marginBottom": "11px"
                                 }}>
                              <div className="card-body text-center m-0 py-3">
                                <span style={{"fontSize": "16px"}}><strong>{confirm.number_of_tickets_issued} </strong></span>
                                <span className="text-muted small">
                                  <strong>
                                    Tickets Issued
                                  </strong>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-12 mb-3">
                          </div>
                        </div>
                      )}

                    </div>
                  ) : null}
                  {widgetTab === ADMISSION_WIDGET_TAB ? (
                    <div>
                      <div className="row px-2"
                           style={{"paddingTop": "38px"}}>
                        <div className="col-12 col-lg-4 d-flex justify-content-between flex-row flex-lg-column">
                          <dl>
                            <dt className="text-muted">
                              <strong className="text-no-wrap">Checked</strong>
                            </dt>
                            <dd style={{"marginTop": "-5px"}}>
                              <strong className="text-no-wrap"
                                      style={{"fontSize": "24px"}}>
                                {confirm.number_of_tickets_checked_in}
                              </strong>
                            </dd>
                          </dl>
                          <dl>
                            <dt className="text-muted">
                              <strong className="text-no-wrap">Sold</strong>
                            </dt>
                            <dd style={{"marginTop": "-5px"}}>
                              <strong className="text-no-wrap"
                                      style={{"fontSize": "24px"}}>
                                {confirm.number_of_manifested_tickets_sold}
                              </strong>
                            </dd>
                          </dl>
                        </div>
                        <div className="col-12 col-lg-8 d-flex justify-content-center"
                             style={{"paddingBottom": "25px"}}>
                          <SemiCircle wrapperClassName="align-self-end"
                                      percentage={calculatePercentageChecked(confirm)} />
                        </div>
                        <div className="col-12">
                          <table className="table mb-0 vertical-middle table-sm"
                                 style={{tableLayout: "fixed"}}>
                            <colgroup>
                              <col span={1} style={{"width": "75%"}} />
                              <col span={1} style={{"width": "25%"}} />
                            </colgroup>
                            <tbody>
                              {ticketTypes.map((ticketType, index) =>
                                <tr className="border-0" key={index}>
                                  <td className="overflow-ellipsis text-muted border-0 px-0">
                                    <strong title={ticketType.name}>
                                      {ticketType.name}
                                    </strong>
                                  </td>
                                  <td className="px-0 border-0 text-right">
                                    <strong style={{"fontSize": "16px"}}>
                                      {ticketType.number_of_tickets_checked_in}/{ticketType.number_of_tickets_sold}
                                    </strong>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              {payoutInfo.payoutBalanceTransactions.length > 0 ? (
                <div style={{"marginTop": "20px", "marginBottom": "25px"}}>
                  <div className='row mb-1'>
                    <div className='col-12 col-lg-8'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <p className="mb-0" style={{"fontSize": "20px"}}>
                          <strong>Payouts</strong>
                        </p>
                        <a href={`/teams/${team.id}/payouts`}>
                          <strong>
                            See all payouts
                            <i className="fas fa-angle-right ml-1"></i>
                          </strong>
                        </a>
                      </div>
                    </div>
                    <div className='col-12 col-lg-4'>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12 col-lg-8'>
                      <table className='table vertical-middle table-bordered-bottom no-top-border'>
                        <colgroup>
                          <col span={1} style={{"width": "30%"}} />
                          <col span={1} style={{"width": "30%"}} />
                          <col span={1} style={{"width": "30%"}} />
                          <col span={1} style={{"width": "10%"}} />
                        </colgroup>
                        <tbody>
                          {payoutInfo.payoutBalanceTransactions.map((balanceTransaction, index) => 
                            <tr key={index}>
                              <td>
                                {balanceTransaction.human_created_at}
                              </td>
                              <td className='text-right'>
                                {balanceTransaction.category === "debit" ? (
                                  <span>{formatter.format(parseFloat(balanceTransaction.amount))}</span>
                                ) : (
                                  <span>-{formatter.format(parseFloat(balanceTransaction.amount))}</span>
                                )}
                              </td>
                              <td>&nbsp;</td>
                              <td>
                                <a href={`/teams/${team.id}/balance_transactions/${balanceTransaction.id}.csv`} className='text-muted'>
                                  <i className="fas fa-download"></i>
                                </a>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='col-12 col-lg-4'>
                      <div className='card border-0' style={{"background": "#f4f9fd"}}>
                        <div className='card-body'>
                          <p className='text-muted mb-0'>
                            <strong>Paid Out</strong>
                          </p>
                          <p className='text-dark mb-3' style={{"fontSize": "24px", "marginTop": "-5px"}}>
                            <strong>{formatter.format(parseFloat(payoutInfo.paidOutAmount))}</strong>
                          </p>
                          <p className='text-muted mb-0'>
                            <strong>Payout Remaining</strong>
                          </p>
                          <p className='text-dark mb-3' style={{"fontSize": "24px", "marginTop": "-5px"}}>
                            <strong>{formatter.format(parseFloat(payoutInfo.payoutRemaining))}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div
                className='row bordered-cols'
                style={{
                  "marginTop": "20px",
                  "marginBottom": "25px"
                }}
              >
                <div className="col-12">
                  <p className="mb-0" style={{"fontSize": "20px"}}>
                    <strong>Ticket Buyers</strong>
                  </p>
                </div>
                { ticketBuyersData.length == 0 ? (
                  <div className="col-12 text-center">
                    <img src="/uploading-loading.gif"
                         style={{width: "50px"}} />
                  </div>
                ) : (
                  <div className="col-12 row">
                    <div
                      className="col-12 col-lg-3"
                      style={{ "minHeight": "150px" }}
                    >
                      <ResponsiveContainer width="100%" height="100%">
                        <RadialBarChart
                          cx="50%"
                          cy="50%"
                          innerRadius="10%"
                          outerRadius="100%"
                          barSize={20}
                          data={Object.assign([], ticketBuyersData).reverse()}
                        >
                          <RadialBar
                            minAngle={15}
                            background
                            clockWise
                            dataKey="count"
                          />
                        </RadialBarChart>
                      </ResponsiveContainer>
                    </div>
                    <div className="col-12 col-lg-9">
                      <div className="row">
                        { ticketBuyersData.map((ticketBuyerData, index) =>
                          <div className="col-4 mb-3" key={index}>
                            <div className="row">
                              <div className="col-12 d-flex align-items-center">
                                <div
                                  style={{
                                    "width": "10px",
                                    "height": "10px",
                                    "backgroundColor": `${ticketBuyerData.fill}`,
                                    "marginRight": "5px",
                                    "borderRadius": "2px"
                                  }}
                                >
                                </div>
                                <div>
                                  { ticketBuyerData.name }
                                </div>
                                <Tippy
                                  content={
                                    <div style={{
                                          "fontSize": "12px"
                                        }}>
                                      { ticketBuyerData.tooltip }
                                    </div>
                                  }
                                  placement={'top'}
                                  maxWidth={200}
                                  animation={'shift-away'}
                                  theme='material'
                                >
                                  <i className="fas fa-info-circle text-muted ml-2"></i>
                                </Tippy>
                              </div>
                              <div className="col-12">
                                <h2
                                  className="font-700"
                                  style={{ "fontSize": "34px" }}
                                >
                                  { Intl.NumberFormat().format(ticketBuyerData.count) }
                                </h2>
                              </div>
                              { (ticketBuyerData.total_description != undefined) && (
                                <div
                                  className="col-12"
                                  style={{
                                    "fontSize": "14px",
                                    "color": "#333333",
                                    "opacity": "50%",
                                  }}
                                >
                                    { ticketBuyerData.total_description }
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {ticketTypesByPrice.length > 0 ? (
                <div className='row'>
                  <div className="col-12">
                    <div className="row">
                      <div className="col">
                        <p className="mb-0" style={{"fontSize": "20px"}}>
                          <strong>Sales by Ticket Type</strong>
                        </p>
                      </div>
                      <div className="col-xs-auto"
                           style={{"paddingRight": "15px"}}>
                        <a href={"/teams/" + team.id + "/confirms/" + confirm.id + "/ticketing?active_menu_item=TICKETS_MENU_ITEM"}
                           className="btn btn-external btn-sm">
                          <strong>Manage Tickets</strong>
                        </a>
                      </div>
                    </div>
                    <table className="table mt-2 mb-0 vertical-middle table-bordered-bottom"
                           style={{tableLayout: "fixed"}}>
                      <colgroup>
                        <col span={1} style={{"width": "70%"}} />
                        <col span={1} style={{"width": "15%"}} />
                        <col span={1} style={{"width": "15%"}} />
                      </colgroup>
                      <tbody>
                        {ticketTypesByPrice.map((salesData, index) =>
                          <tr key={index}
                              className={"border-top-0 " + (salesData.ticket_type.scheduled ? "text-muted" : "text-dark")}>
                            <td className="overflow-ellipsis pl-0">
                              <div>
                                <strong title={salesData.ticket_type.name}>
                                  {salesData.ticket_type.name}
                                </strong>
                                {!salesData.ticket_type.manifested ? (
                                  <span className="ml-2 text-muted">
                                    (unmanifested)
                                  </span>
                                ) : null}
                              </div>
                              {salesData.ticket_type.scheduled ? (
                                <div className="small">
                                  Scheduled
                                </div>
                              ) : null}
                            </td>
                            <td className="text-center pl-0">
                              {salesData.quantity}/{salesData.ticket_type.available}
                            </td>
                            <td className="text-center pl-0">
                              {parseFloat(salesData.price) === 0 ? (
                                "FREE"
                              ) : (
                                formatter.format(salesData.price)
                              )}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
              {addOnsByPrice.length > 0 ? (
                <div className='row'>
                  <div className="col-12"
                       style={{"marginTop": "53px"}}>
                    <div className="row">
                      <div className="col">
                        <p className="mb-0" style={{"fontSize": "20px"}}>
                          <strong>Sales by Add-on</strong>
                        </p>
                      </div>
                      <div className="col-xs-auto"
                           style={{"paddingRight": "15px"}}>
                        <a href={"/teams/" + team.id + "/confirms/" + confirm.id + "/ticketing?active_menu_item=TICKETS_MENU_ITEM&active_ticketing_menu_item=TICKETING_ADD_ONS_MENU_ITEM"}
                           className="btn btn-external btn-sm">
                          <strong>Manage Add-ons</strong>
                        </a>
                      </div>
                    </div>
                    <table className="table mt-2 mb-0 vertical-middle table-bordered-bottom"
                           style={{tableLayout: "fixed"}}>
                      <colgroup>
                        <col span={1} style={{"width": "70%"}} />
                        <col span={1} style={{"width": "15%"}} />
                        <col span={1} style={{"width": "15%"}} />
                      </colgroup>
                      <tbody>
                        {addOnsByPrice.sort((a, b) => a.add_on.position - b.add_on.position).map((salesData, index) =>
                          <tr key={index}
                              className={"border-top-0 " + (salesData.add_on.scheduled ? "text-muted" : "text-dark")}>
                            <td className="overflow-ellipsis pl-0">
                              <strong title={salesData.add_on.name}>
                                {salesData.add_on.name}
                              </strong>
                              {salesData.add_on.scheduled ? (
                                <div className="small">
                                  Scheduled
                                </div>
                              ) : null}
                            </td>
                            <td className="text-center pl-0">
                              {salesData.quantity}/{salesData.add_on.available}
                            </td>
                            <td className="text-center pl-0">
                              {formatter.format(salesData.price)}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
              <Popup
                open={objToPrint != null}
                arrow={false}
                modal={true}
                contentStyle={printTicketsContentStyle}
              >
                {close => (
                  <div className="">
                    <div className="row">
                      <div className="col">
                        <div className="row">
                          <div className="col">
                            <h5 style={{"marginBottom": "16px"}}>
                              <div className="strong">
                                {objToPrint && objToPrint.type === "ticket" ? (
                                  "Print ticket?"
                                ) : (
                                  "Print tickets?"
                                )}
                              </div>
                            </h5>
                          </div>
                          <div className="col-xs-auto">
                            <a href="#"
                               className="text-dark"
                               style={{"paddingRight": "15px"}}
                               onClick={e => {
                                 e.preventDefault();
                                 setObjToPrint(null);
                               }} >
                              <i className="fa fa-times"></i>
                            </a>
                          </div>
                        </div>
                        {confirm.venue_ownership.available_printers.length > 1 ? (
                          <div className="row mb-2">
                            <div className="col">
                              <strong>Select a printer</strong>
                              <Select
                                value={selectedPrinter}
                                options={confirm.venue_ownership.available_printers.map(printer => {
                                  return {
                                    value: printer.id,
                                    label: printer.name
                                  };
                                })}
                                onChange={option => setSelectedPrinter(option)}
                              />
                            </div>
                          </div>
                        ) : null}
                        {objToPrint && objToPrint.type === "ticket" ? (
                          <p>The ticket will be printed.</p>
                        ) : (
                          <p>The tickets for this order will be printed.</p>
                        )}
                        <p>This cannot be undone.</p>
                        <p>Are you sure?</p>
                        <div className="text-right">
                          <a
                            href="#"
                            onClick={e => {
                              e.preventDefault();
                              setObjToPrint(null);
                            }}>
                            Cancel
                          </a>
                          <a
                            href="#"
                            className="btn btn-danger ml-3"
                            onClick={e => {
                              e.preventDefault();

                              print(csrfToken, team, objToPrint, setObjToPrint, selectedPrinter, setSelectedPrinter);
                            }}>

                            {objToPrint && objToPrint.type === "ticket" ? (
                              "Print Ticket"
                            ) : (
                              "Print Tickets"
                            )}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </Popup>
              <div className='row'>
                <div className="col-12"
                     style={{"marginTop": "53px"}}>
                  <div className="confirm-dashboard-datatable">
                    <div className="table-search-row">
                      <TableSearch typedSearchTerm={typedSearchTerm}
                                   csvDataSource={csvDataSource}
                                   dataTableEl={dataTableEl}
                                   dataTableElChanged={dataTableElChanged}
                                   team={team}
                                   confirm={confirm}
                                   currentUser={currentUser}
                                   startSearchTermUpdate={startSearchTermUpdate} />
                    </div>
                    <DataTable title={"Recent Orders"}
                               dataSource={"/teams/" + team.id + "/confirms/" + confirm.id + "/dashboard.json"}
                               searchedSearchTerm={searchedSearchTerm}
                               onInitComplete={(settings, json) => {
                                 dataTableElChanged(settings.oInstance[0]);
                               }}
                               colGroup={
                                 <colgroup>
                                   <col span={1} style={{"width": "19%"}} />
                                   <col span={1} style={{"width": "19%"}} />
                                   <col span={1} style={{"width": "14%"}} />
                                   <col span={1} style={{"width": "14%"}} />
                                   <col span={1} style={{"width": "29%"}} />
                                   <col span={1} style={{"width": "5%"}} />
                                 </colgroup>
                               }
                               columns={
                                 [
                                   {"label": "json", "data": "json", "visible": false},
                                   {"label": "Order Number", "data": "number"},
                                   {"label": "Buyer", "data": "buyer", "orderable": false},
                                   {"label": "QTY", "data": "qty", "orderable": false},
                                   {"label": "Price", "data": "price"},
                                   {"label": "Date", "data": "date"},
                                   {"label": "", "data": "menu", "orderable": false},
                                   {"label": "first_name", "data": "first_name", "visible": false},
                                   {"label": "last_name", "data": "last_name", "visible": false},
                                   {"label": "email", "data": "email", "visible": false},
                                 ]
                               }
                               rowCallback={
                                  (row, data) => {
                                    var order = JSON.parse(decodeHtml(data.json));

                                    var mountReact = row.getElementsByClassName("order-id-mount-react")[0];
                                    ReactDOM.render((
                                      <a href="#"
                                         className="text-primary strong"
                                         onClick={
                                           (e) => {
                                             e.preventDefault();
                                             fetchOrder(csrfToken, team, order.id);
                                           }
                                         }>
                                        {order.id}
                                      </a>
                                    ), mountReact);

                                    var menuMountReact = row.getElementsByClassName("order-menu-mount-react")[0];
                                    ReactDOM.render((
                                      <Popup arrow={false}
                                             offsetY={5}
                                             position="bottom right"
                                             contentStyle={popupContentStyle}
                                             onOpen={
                                               (e) => {
                                                 e.preventDefault();
                                               }
                                             }
                                             trigger={open => (
                                               <a href="#"
                                                  className="text-muted">
                                                 <i className="fas fa-ellipsis-v"></i>
                                               </a>
                                             )} >
                                        {close => (
                                          <div className='row text-left'>
                                            <div className="col-12">
                                              <ul className="list-group offer-inline-menu highlight-primary"
                                                  style={{"fontSize": "14px"}}>
                                                {team.package.printing && confirm.venue_ownership.can_print ? (
                                                  <li className="list-group-item pl-2">
                                                    <a
                                                      href="#"
                                                      className="text-dark"
                                                      onClick={e => {
                                                        e.preventDefault();
                                                        setObjToPrint({ type: "order", obj: order });
                                                        close();
                                                      }}>
                                                      Print Tickets
                                                    </a>
                                                  </li>
                                                ) : null}
                                                <li className="list-group-item pl-2">
                                                  <a href="#"
                                                     onClick={
                                                       (e) => {
                                                         e.preventDefault();
                                                         fetchNewRefund(csrfToken, team, order);
                                                       }
                                                     }
                                                     className="text-dark">
                                                    Refund Order
                                                  </a>
                                                </li>
                                                {order.email ? (
                                                  <li className="list-group-item pl-2">
                                                    <a href="#"
                                                       onClick={
                                                         (e) => {
                                                           e.preventDefault();
                                                           close();
                                                           slidingPaneStateChanged(RESEND_EMAIL_SLIDING_STATE);
                                                           fetchOrder(csrfToken, team, order.id);
                                                         }
                                                       }
                                                       className="text-dark">
                                                      Resend Order Confirmation
                                                    </a>
                                                  </li>
                                                ) : null}
                                              </ul>
                                            </div>
                                          </div>
                                        )}
                                      </Popup>
                                    ), menuMountReact);
                                  }
                               }
                               order={[[5, "desc"]]} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SlidingPane
        isOpen={Object.keys(order).length > 0}
        className="sliding-pane-container"
        width={"480px"}
        hideHeader={true}
        onAfterOpen={() => {
          resendEmailChanged(order.email || "");
        }}
        onRequestClose={() => {
          orderChanged({});
          slidingPaneStateChanged(SHOW_ORDER_SLIDING_STATE);
        }}
      >
        {slidingPaneState === SHOW_ORDER_SLIDING_STATE ? (
          <ShowOrderSlidingState csrfToken={csrfToken}
                                 team={team}
                                 currentUser={currentUser}
                                 confirm={confirm}
                                 order={order}
                                 orderChanged={orderChanged}
                                 sendOrderReceipt={sendOrderReceipt}
                                 slidingPaneStateChanged={slidingPaneStateChanged}
                                 fetchNewRefund={fetchNewRefund}
                                 setObjToPrint={setObjToPrint}
                                 sendOrderCheckIn={sendOrderCheckIn}
                                 sendOrderCheckOut={sendOrderCheckOut} />
        ) : slidingPaneState === RESEND_EMAIL_SLIDING_STATE ? (
          <ResendEmailSlidingState csrfToken={csrfToken}
                                   team={team}
                                   currentUser={currentUser}
                                   confirm={confirm}
                                   order={order}
                                   orderChanged={orderChanged}
                                   sendOrderReceipt={sendOrderReceipt}
                                   resendEmail={resendEmail}
                                   resendEmailChanged={resendEmailChanged}
                                   sendingConfirmationEmail={sendingConfirmationEmail}
                                   slidingPaneStateChanged={slidingPaneStateChanged} />
        ) : null}
      </SlidingPane>
      <RefundModal csrfToken={csrfToken}
                   team={team}
                   currentUser={currentUser}
                   confirm={confirm}
                   refund={refund}
                   fetchNewRefund={fetchNewRefund}
                   refundReasons={refundReasons}
                   createRefund={createRefund}
                   submittingRefund={submittingRefund}
                   confirmRefund={confirmRefund}
                   canBypassRefundRestrictions={canBypassRefundRestrictions}
                   refundChanged={refundChanged} />
    </div>
  );
}

ConfirmDashboard.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  confirm: PropTypes.object.isRequired,
  confirmChanged: PropTypes.func.isRequired,
  ticketTypes: PropTypes.array,
  typedSearchTerm: PropTypes.string,
  startSearchTermUpdate: PropTypes.func.isRequired,
  searchedSearchTerm: PropTypes.string,
  csvDataSource: PropTypes.string.isRequired,
  dataTableEl: PropTypes.object,
  dataTableElChanged: PropTypes.func.isRequired,
  order: PropTypes.object,
  orderChanged: PropTypes.func.isRequired,
  sendOrderReceipt: PropTypes.func.isRequired,
  widgetTab: PropTypes.string,
  widgetTabChanged: PropTypes.func.isRequired,
  addOnsByPrice: PropTypes.array,
  refund: PropTypes.object,
  fetchNewRefund: PropTypes.func.isRequired,
  refundChanged: PropTypes.func.isRequired,
  refundReasons: PropTypes.array,
  createRefund: PropTypes.func.isRequired,
  submittingRefund: PropTypes.bool,
  slidingPaneState: PropTypes.string,
  slidingPaneStateChanged: PropTypes.func.isRequired,
  resendEmail: PropTypes.string,
  resendEmailChanged: PropTypes.func.isRequired,
  sendingConfirmationEmail: PropTypes.bool,
  cumulativeSalesData: PropTypes.array,
  fakeSalesData: PropTypes.array,
  fakeSalesDataChanged: PropTypes.func.isRequired,
  confirmRefund: PropTypes.func.isRequired,
  refundOrder: PropTypes.object,
  buyers: PropTypes.array,
  promoters: PropTypes.array,
  ticketTypesByPrice: PropTypes.array,
  canBypassRefundRestrictions: PropTypes.bool,
  payoutInfo: PropTypes.object,
  salesData: PropTypes.object
};

export default ConfirmDashboard;

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { MultiSelect } from "react-multi-select-component";
import Popup from "reactjs-popup";
import { usePlaidLink } from 'react-plaid-link';
const axios = require('axios').default;
import { ToastContainer, toast } from 'react-toastify';

const popupContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "195px",
  "padding": "0px"
}

// https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const FinanceSettingsManager = ({
  csrfToken,
  team,
  currentUser,
  plaidToken,
  dwollaCustomer,
  dwollaCustomerChanged,
  plaidAccountLinks,
  plaidAccountLinksChanged,
  venueOwnerships,
  venueOwnershipsChanged
}) => {
  const [creatingDwollaCustomer, setCreatingDwollaCustomer] = useState(false);
  const [originalDwollaCustomer, setOriginalDwollaCustomer] = useState({...dwollaCustomer});

  const createDwollaCustomer = (dwollaCustomer) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    setCreatingDwollaCustomer(true);

    axios.post(`/teams/${team.id}/dwolla_customers`, {
        dwolla_customer: {
          first_name: dwollaCustomer.first_name,
          last_name: dwollaCustomer.last_name,
          email: dwollaCustomer.email,
          business_name: dwollaCustomer.business_name,
          terms_and_privacy_policy: dwollaCustomer.terms_and_privacy_policy
        }
      })
      .then(({data}) => {
        setOriginalDwollaCustomer(data);
        dwollaCustomerChanged(data);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        setCreatingDwollaCustomer(false);
      });
  };

  const updateDwollaCustomer = (dwollaCustomer) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    setCreatingDwollaCustomer(true);

    axios.patch(`/teams/${team.id}/dwolla_customers/${dwollaCustomer.id}`, {
        dwolla_customer: {
          first_name: dwollaCustomer.first_name,
          last_name: dwollaCustomer.last_name,
          email: dwollaCustomer.email,
          business_name: dwollaCustomer.business_name
        }
      })
      .then(({data}) => {
        setOriginalDwollaCustomer(data);
        dwollaCustomerChanged(data);
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      })
      .finally(() => {
        setCreatingDwollaCustomer(false);
      });
  };

  const updatePlaidAccountLink = (plaidAccountLink, venueOwnershipIds) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    axios.patch(`/teams/${team.id}/plaid_account_links/${plaidAccountLink.id}`, {
        plaid_account_link: {
          venue_ownership_ids: venueOwnershipIds
        }
      })
      .catch((error) => {
        toast.error(error.response.data.join(", "), {
          position: toast.POSITION.TOP_CENTER,
          draggable: false,
          closeOnClick: false,
          autoClose: 5000,
          hideProgressBar: true
        });
      });
  };

  const deletePlaidAccountLink = (plaidAccountLink) => {
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

    axios.delete(`/teams/${team.id}/plaid_account_links/${plaidAccountLink.id}`)
      .then(({data}) => {
        plaidAccountLinksChanged(
          plaidAccountLinks.filter((pal) => pal.id !== data.id)
        );

        venueOwnershipsChanged(
          [...venueOwnerships].map((vo) => {
            if(vo.plaid_account_link_id === data.id){
              return {
                ...vo,
                plaid_account_link_id: null
              };
            } else {
              return vo;
            }
          })
        )
      });
  };

  const canSaveDwollaCustomer = (dwollaCustomer, creatingDwollaCustomer, originalDwollaCustomer) => {
    var validTerms = (
      dwollaCustomer.terms_and_privacy_policy === "1" ||
        dwollaCustomer.id
    );

    var dwollaCustomerChanged = (
      dwollaCustomer.first_name !== originalDwollaCustomer.first_name ||
        dwollaCustomer.last_name !== originalDwollaCustomer.last_name ||
        dwollaCustomer.email !== originalDwollaCustomer.email ||
        dwollaCustomer.business_name !== originalDwollaCustomer.business_name ||
        dwollaCustomer.terms_and_privacy_policy !== originalDwollaCustomer.terms_and_privacy_policy
    );

    return (
      dwollaCustomer.first_name &&
        dwollaCustomer.first_name.length > 0 &&
        dwollaCustomer.last_name &&
        dwollaCustomer.last_name.length > 0 &&
        dwollaCustomer.email &&
        dwollaCustomer.email.length > 0 &&
        validateEmail(dwollaCustomer.email) &&
        dwollaCustomer.business_name &&
        dwollaCustomer.business_name.length > 0 &&
        validTerms &&
        dwollaCustomerChanged &&
        !creatingDwollaCustomer
    );
  }

  const { open, ready } = usePlaidLink({
    token: plaidToken,
    onSuccess: (publicToken, metadata) => {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;

      return axios.post("/teams/" + team.id + "/plaid_account_links", {
          public_token: publicToken,
          account_id: metadata.account_id
        })
        .then(({ data }) => {
          var updated = [...plaidAccountLinks, data];
          plaidAccountLinksChanged(updated);
        });
    },
  });

  return (
    <>
      <ToastContainer />
      <div className='row'>
        <div className='col-12' style={{"marginBottom": "40px"}}>
          <p className="mb-0 text-dark"
              style={{"fontSize": "16px"}}>
            <strong>Connect your bank account to receive payouts</strong>
          </p>
          <p className="mb-3 text-muted small">
            Opendate has partnered with a service called Dwolla to connect your bank account. Complete the following steps to get you account connected.
          </p>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'>
          <p className="mb-0 text-muted"
              style={{"fontSize": "10px"}}>
            <strong>STEP 1</strong>
          </p>
          <p className="mb-3 text-dark">
            <strong>
              Create your profile
            </strong>
          </p>
          <form onSubmit={
                  (e) => {
                    e.preventDefault();

                    if(canSaveDwollaCustomer(dwollaCustomer, creatingDwollaCustomer, originalDwollaCustomer)){
                      if(dwollaCustomer.id){
                        updateDwollaCustomer(dwollaCustomer);
                      } else {
                        createDwollaCustomer(dwollaCustomer);
                      }
                    }
                  }
                }>
            <div className='form-row'>
              <div className='col-6'>
                <div className="form-group">
                  <label className='text-muted mb-0'
                          htmlFor="dwolla-customer-first-name">
                    First Name
                  </label>
                  <input type="text"
                        className="form-control"
                        style={{"borderWidth": "1px"}}
                        value={dwollaCustomer.first_name || ""}
                        onChange={
                          (e) => {
                            var updated = {
                              ...dwollaCustomer,
                              first_name: e.target.value
                            };

                            dwollaCustomerChanged(updated);
                          }
                        }
                        id="dwolla-customer-first-name" />
                </div>
              </div>
              <div className='col-6'>
                <div className="form-group">
                  <label className='text-muted mb-0'
                          htmlFor="dwolla-customer-last-name">
                    Last Name
                  </label>
                  <input type="text"
                        className="form-control"
                        style={{"borderWidth": "1px"}}
                        value={dwollaCustomer.last_name || ""}
                        onChange={
                          (e) => {
                            var updated = {
                              ...dwollaCustomer,
                              last_name: e.target.value
                            };

                            dwollaCustomerChanged(updated);
                          }
                        }
                        id="dwolla-customer-last-name" />
                </div>
              </div>
            </div>
            <div className='form-row'>
              <div className='col-6'>
                <div className="form-group">
                  <label className='text-muted mb-0'
                          htmlFor="dwolla-customer-email">
                    Email
                  </label>
                  <input type="email"
                        className="form-control"
                        style={{"borderWidth": "1px"}}
                        value={dwollaCustomer.email || ""}
                        onChange={
                          (e) => {
                            var updated = {
                              ...dwollaCustomer,
                              email: e.target.value
                            };

                            dwollaCustomerChanged(updated);
                          }
                        }
                        id="dwolla-customer-email" />
                </div>
              </div>
              <div className='col-6'>
                <div className="form-group">
                  <label className='text-muted mb-0'
                          htmlFor="dwolla-customer-business-name">
                    Business Name
                  </label>
                  <input type="text"
                        className="form-control"
                        style={{"borderWidth": "1px"}}
                        value={dwollaCustomer.business_name || ""}
                        onChange={
                          (e) => {
                            var updated = {
                              ...dwollaCustomer,
                              business_name: e.target.value
                            };

                            dwollaCustomerChanged(updated);
                          }
                        }
                        id="dwolla-customer-business-name" />
                </div>
              </div>
            </div>
            {!dwollaCustomer.id ? (
              <div className="form-check mb-2">
                <input type="checkbox"
                        className="form-check-input"
                        checked={dwollaCustomer.terms_and_privacy_policy === "1" || false}
                        onChange={
                          (e) => {
                            var updated = {
                              ...dwollaCustomer,
                              terms_and_privacy_policy: (e.target.checked ? "1" : "0")
                            };

                            dwollaCustomerChanged(updated);
                          }
                        }
                        id="dwolla-customer-agree-to-terms" />
                <label className="form-check-label"
                        htmlFor="dwolla-customer-agree-to-terms">
                  By checking this box you agree to our payments partner Dwolla's <a href="https://www.dwolla.com/legal/dwolla-account-terms-of-service" target="_blank">Terms of Service</a> and <a href="https://www.dwolla.com/legal/privacy" target="_blank">Privacy Policy</a>
                </label>
              </div>
            ) : null}
            <button type="submit"
                    disabled={!canSaveDwollaCustomer(dwollaCustomer, creatingDwollaCustomer, originalDwollaCustomer)}
                    style={{"borderRadius": "4px"}}
                    className="btn btn-primary small mt-2">
              {creatingDwollaCustomer ? (
                <React.Fragment>
                  <img src="/uploading-loading.gif"
                      className="mr-1"
                      style={{width: "16px"}} />
                  <strong>Saving...</strong>
                </React.Fragment>
              ) : (
                <strong>Save</strong>
              )}
            </button>
          </form>
        </div>
      </div>
      <div className='row'>
        <div className='col-12'
              style={{
                "marginTop": "60px",
                "marginBottom": "60px"
              }}>
          <p className="mb-0 text-muted"
              style={{"fontSize": "10px"}}>
            <strong>STEP 2</strong>
          </p>
          <p className="mb-3 text-dark">
            <strong>
              Add your account(s)
            </strong>
          </p>
          <div className="row mt-3">
            {plaidAccountLinks.map((plaidAccountLink, index) =>
              <div className="col-12"
                  key={index}>
                <div className='form-row'>
                  <div className='col-6 col-xl-4'>
                    <div className="card w-100"
                        style={{"border": "2px solid #e7e7e7"}}>
                      <div className="card-body"
                          style={{"padding": "11px 17px"}}>
                        <div className="row">
                          <div className="col-xs-auto d-flex align-items-center"
                              style={{"paddingLeft": "15px"}}>
                            <i className="fas fa-university"
                              style={{
                                "color": "#B3B3B3",
                                "fontSize": "26px"
                              }}>
                            </i>
                          </div>
                          <div className="col">
                            <table className="table table-sm mb-0 table-borderless">
                              <tbody>
                                <tr>
                                  <td className="p-0 overflow-ellipsis"
                                      title={plaidAccountLink.official_name}>
                                    <p className="small mb-0 text-overflow-ellipsis">
                                      <strong>{plaidAccountLink.name}</strong>
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="p-0">
                                    <p className="small mb-0 text-muted">
                                      ***** {plaidAccountLink.mask} (USD)
                                    </p>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-xs-auto"
                              style={{"paddingRight": "15px"}}>
                            <Popup arrow={false}
                                  offsetY={5}
                                  position="bottom right"
                                  contentStyle={popupContentStyle}
                                  onOpen={
                                    (e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                    }
                                  }
                                  trigger={open => (
                                    <a href="#"
                                        style={
                                          {
                                            "top": "10px",
                                            "right": "12px",
                                            "position": "absolute"
                                          }
                                        }
                                        className="text-muted">
                                      <i className="far fa-ellipsis-v"></i>
                                    </a>
                                  )} >
                              {close => (
                                <div className='row text-left'>
                                  <div className="col-12">
                                    <ul className="list-group offer-inline-menu">
                                      <li className="list-group-item delete-offer-li">
                                        <a href="#"
                                          onClick={
                                            (e) => {
                                              e.preventDefault();

                                              if(confirm("Are you sure?")){
                                                close();
                                                deletePlaidAccountLink(plaidAccountLink);
                                              }
                                            }
                                          }
                                          className="text-danger">
                                          <span className="d-inline-block text-center" style={{width: "28px"}}>
                                            <i className="fas fa-times-circle"></i>
                                          </span>
                                          Remove
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-6 col-xl-8'>
                    <div className="card w-100"
                        style={{"border": "1px solid #e7e7e7"}}>
                      <div className="card-body"
                          style={{"padding": "11px 17px"}}>
                        <MultiSelect
                          className="dropdown-container-inline"
                          options={
                            venueOwnerships.map((venueOwnership) => (
                              {value: venueOwnership.id, label: venueOwnership.name}
                            ))
                          }
                          hasSelectAll={true}
                          value={
                            venueOwnerships
                              .filter((vo) => vo.plaid_account_link_id === plaidAccountLink.id)
                              .map((vo) => ({value: vo.id, label: vo.name}))
                          }
                          onChange={
                            (nowSelected) => {
                              var nowSelectedIds = nowSelected.map((ns) => ns.value);
                              var previouslySelectedIds = venueOwnerships
                                .filter((vo) => vo.plaid_account_link_id === plaidAccountLink.id);
                              var removedIds = previouslySelectedIds
                                .filter((ps) => !nowSelectedIds.includes(ps.id))
                                .map((ps) => ps.id);
                              var addedIds = nowSelectedIds
                                .filter((ns) => !previouslySelectedIds.map((ps) => ps.id).includes(ns))
                                .map((ns) => ns);

                              venueOwnershipsChanged(
                                venueOwnerships.map((vo) => {
                                  if(removedIds.includes(vo.id)){
                                    return {
                                      ...vo,
                                      plaid_account_link_id: null
                                    };
                                  } else if(addedIds.includes(vo.id)){
                                    return {
                                      ...vo,
                                      plaid_account_link_id: plaidAccountLink.id
                                    };
                                  } else {
                                    return vo;
                                  }
                                }
                              ));

                              updatePlaidAccountLink(plaidAccountLink, nowSelectedIds);
                            }
                          }
                          overrideStrings={{
                            "selectSomeItems": "Assign to venue",
                            "allItemsAreSelected": "Assigned to all venues"
                          }}
                          labelledBy="Select"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <button onClick={() => open()}
                  className='btn btn-primary small'
                  style={{
                    "borderRadius": "4px",
                    "borderWidth": "1px"
                  }}
                  disabled={!ready || !dwollaCustomer.id}>
            <strong>
              Add Account
            </strong>
          </button>
          <p className="text-muted mb-0 mt-1 small">
            <i>* Chase Bank support will be available soon</i>
          </p>
        </div>
      </div>
    </>
  )
};

FinanceSettingsManager.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  plaidToken: PropTypes.string.isRequired,
  dwollaCustomer: PropTypes.object,
  dwollaCustomerChanged: PropTypes.func.isRequired,
  plaidAccountLinks: PropTypes.array,
  plaidAccountLinksChanged: PropTypes.func.isRequired,
  venueOwnerships: PropTypes.array,
  venueOwnershipsChanged: PropTypes.func.isRequired
};

export default FinanceSettingsManager;

import React, { createContext, useState, useContext, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import axios from "axios";

import { parseLiquid } from "../BeefreeTemplates/ErrorsContainer";
import BroadcastEdit from "./BroadcastEdit";
import { toastSuccess, toastError } from "../../../shared/toastHelper";
import { offsetDateFromTimeZone, offsetDateToTimezone } from "../../../shared/timeZoneLogic";

const BroadcastEditContext = createContext();

export function useBroadcastEditContext() {
  return useContext(BroadcastEditContext);
}

const BroadcastEditProvider = (props) => {
  const {
    csrfToken,
    user,
    team,
    venueOwnership,
    broadcast,
    beefreeToken,
    beefreeUid,
    sendDomain,
    availableSendDomains,
    previouslySent,
    ahoyMessagesDataTableProps,
  } = props;
  const tabParam = new URLSearchParams(window.location.search).get('tab');

  const isEmailTemplate = true;
  const sendTestUrl = `/teams/${team.id}/broadcasts/${broadcast.id}/test`;

  const [subject, setSubject] = useState(broadcast.subject);
  const [preheader, setPreheader] = useState(broadcast.preheader);
  const [template, setTemplate] = useState(broadcast.beefree_template.template);
  const [processedTemplate, setProcessedTemplate] = useState(broadcast.beefree_template.template);
  const [beefreeTemplate, setBeefreeTemplate] = useState(broadcast.beefree_template);
  const [testModalOpen, setTestModalOpen] = useState(false);
  const [scheduledSend, setScheduledSend] = useState(broadcast.pending_scheduled_send);
  const [tab, setTab] = useState(tabParam ? tabParam : (scheduledSend || previouslySent) ? "content" : "recipients");
  const [errors, setErrors] = useState([]);
  const [sampleFanId, setSampleFanId] = useState(props.sampleFanId);
  const [sampleData, setSampleData] = useState({fan: {}});
  const [emailSendDomain, setEmailSendDomain] = useState(broadcast.beefree_template.email_send_domain);
  const [selectedSendDomain, setSelectedSendDomain] = useState(sendDomain);
  const [scheduledSendPaneOpen, setScheduledSendPaneOpen] = useState(false);
  const [timeZone, setTimeZone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [ahoyMessageId, setAhoyMessageId] = useState(null);
  const [ahoyMessage, setAhoyMessage] = useState({});
  const [previousBroadcastTemplates, setPreviousBroadcastTemplates] = useState([]);
  const [previewTemplate, setPreviewTemplate] = useState(null);
  const [recipientsCount, setRecipientsCount] = useState("--");
  const [templateHasContent, setTemplateHasContent] = useState(false);
  const [scheduledAt, setScheduledAt] = useState(
    scheduledSend ? offsetDateToTimezone(scheduledSend.scheduled_at, timeZone) : null
  );
  const [segmentFilters, setSegmentFilters] = useState(broadcast.parent_segment_filter ? (
    [
      {
        ...broadcast.parent_segment_filter,
        set: true
      },
      ...broadcast.parent_segment_filter.children.map(childFilter => ({
        ...childFilter,
        set: true
      })),
    ]
  ) : []);

  const visibleSegmentFilters = segmentFilters.filter(filter => !filter._destroy);

  useEffect(() => {
    if (!!sampleFanId) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
      axios.get(`/teams/${team.id}/broadcasts/${broadcast.id}/sample_event_data`, {
        params: {
          fan_id: sampleFanId,
        }
      })
        .then(({ data }) => setSampleData(data))
        .catch((_error) => toastError("Could not fetch sample data, please try again"));
    }
  }, [sampleFanId]);

  useEffect(() => {
    if (ahoyMessageId) {
      axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
      axios.get(`/teams/${team.id}/broadcasts/${broadcast.id}/ahoy_messages/${ahoyMessageId}`)
        .then((response) => {
          setAhoyMessage(response.data);
        });
    } else {
      setAhoyMessage({});
    }
  }, [ahoyMessageId]);

  const updateBroadcast = (payload, alert = true, callback = null) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
    axios.put(`/teams/${team.id}/broadcasts/${broadcast.id}`, payload)
      .then(() => {
        if(alert) {
          toastSuccess("Broadcast updated successfully")
        }

        if(callback) {
          callback();
        }
      })
      .catch(() => toastError("Could not update broadcast, please try again"));
  };

  const createSegmentFilter = (segmentFilters) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    const parentFilter = segmentFilters.slice(0, 1).find(filter => filter.set);
    const children = segmentFilters.slice(1).filter(filter => filter.set);

    axios.post(`/teams/${team.id}/broadcasts/${broadcast.id}/segment_filters`, {
      segment_filter: {
        inclusion: parentFilter.inclusion,
        segment_ids: parentFilter.segments.map(segment => segment.id),
        children_attributes: children.map(childFilter => ({
          inclusion: childFilter.inclusion,
          segment_ids: childFilter.segments.map(segment => segment.id),
        }))
      }
    })
      .then(({ data }) => {
        toastSuccess("Recipients saved successfully")

        setSegmentFilters(segmentFilters.map((filter, i) => {
          if (i === 0) {
            return {
              ...filter,
              id: data.id,
              set: true,
            };
          } else {
            return {
              ...filter,
              id: data.children[i - 1].id,
              set: true,
            };
          }
        }));
      })
      .catch(() => toastError("Could not save recipients, please try again"));
  };

  const updateSegmentFilter = (segmentFilters) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    const parentFilter = segmentFilters.slice(0, 1).find(filter => filter.set);
    const children = segmentFilters.slice(1).filter(filter => filter.set);

    axios.put(`/teams/${team.id}/broadcasts/${broadcast.id}/segment_filters/${parentFilter.id}`, {
      segment_filter: {
        id: parentFilter.id,
        inclusion: parentFilter.inclusion,
        segment_ids: parentFilter.segments.map(segment => segment.id),
        children_attributes: children.map(childFilter => ({
          id: childFilter.id,
          inclusion: childFilter.inclusion,
          segment_ids: childFilter.segments.map(segment => segment.id),
          _destroy: childFilter._destroy || false,
        }))
      }
    })
      .then(({ data }) => {
        toastSuccess("Recipients updated successfully")

        setSegmentFilters(segmentFilters.map((filter, i) => {
          if (i === 0) {
            return {
              ...filter,
              id: data.id,
              set: true,
            };
          } else {
            return {
              ...filter,
              id: data.children[i - 1].id,
              set: true,
            };
          }
        }));
      })
      .catch(() => toastError("Could not save recipients, please try again"));
  };

  const destroySegmentFilter = (segmentFilter) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    axios.delete(`/teams/${team.id}/broadcasts/${broadcast.id}/segment_filters/${segmentFilter.id}`)
      .then(() => {
        setSegmentFilters(segmentFilters.filter(filter => filter.id !== segmentFilter.id));
      })
      .catch(error => toastError(error.response.data.errors.join(", ")));
  };

  const debouncedFetchSegments = _.debounce((inputValue, callback) => {
    new Promise(resolve => {
      var exclude = segmentFilters.map(filter => filter.segments).flat().map(segment => segment.id).join(',');

      axios.get(`/teams/${team.id}/segments/grouped_index.json?q=${inputValue}&exclude=${exclude}`)
        .then(function (response) {
          var options = response.data.map(function(data){
            return data;
          });

          callback(options);
        })
    });
  }, 250);

  const fetchAllFansCount = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    axios.get(`/teams/${team.id}/fans/count?venue_ownership_id=${venueOwnership.id}`)
      .then(({ data }) => {
        setRecipientsCount(data.count);
      })
      .catch(() => toastError("Could not determine recipient count, please try again"));
  }

  const fetchSegmentFilteredFansCount = () => {
    const parentFilter = segmentFilters.slice(0, 1).find(filter => (
      filter.set && !filter._destroy
    ));

    if(parentFilter?.id) {
      axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
      axios.get(`/teams/${team.id}/broadcasts/${broadcast.id}/segment_filters/${parentFilter.id}/count`)
        .then(({ data }) => {
          setRecipientsCount(data.count);
        })
        .catch(() => toastError("Could not determine recipient count, please try again"));
    } else {
      setRecipientsCount("--");
    }
  }

  const fetchPreviousBroadcastTemplates = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    axios.get(`/teams/${team.id}/beefree_templates.json`)
      .then(({ data }) => {
        setPreviousBroadcastTemplates(data);
      })
      .catch(() => toastError("Could not load templates, please try again"));
  }

  const checkTemplateHasContent = () => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    axios.get(`/teams/${team.id}/broadcasts/${broadcast.id}/template_has_content`)
      .then(({ data }) => {
        setTemplateHasContent(data.has_content);
      })
      .catch(() => toastError("Could not check template content, please try again"));
  }

  const scheduleBroadcast = (callback) => {
    axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

    const axiosRequest = scheduledSend ?
      axios.put(`/teams/${team.id}/scheduled_sends/${scheduledSend.id}`, {
        scheduled_send: {
          scheduled_at: offsetDateFromTimeZone(scheduledAt, timeZone),
        }
      }) :
      axios.post(`/teams/${team.id}/scheduled_sends`, {
        scheduled_send: {
          schedulable_id: broadcast.id,
          schedulable_type: "Broadcast",
          scheduled_at: offsetDateFromTimeZone(scheduledAt, timeZone),
        }
      });

      axiosRequest
        .then(({ data }) => {
          setScheduledSend(data);

          if(callback) {
            callback();
          }
        })
        .catch((error) => {
          setScheduledAt(scheduledSend ? offsetDateToTimezone(scheduledSend.scheduled_at, timeZone) : null);
          const errors = error.response.data;
          toastError(errors.join(", "));
        });
  };

  const value = {
    csrfToken,
    user,
    team,
    venueOwnership,
    broadcast,
    beefreeToken,
    beefreeUid,
    isEmailTemplate,
    sendTestUrl,
    template,
    setTemplate,
    subject,
    setSubject,
    preheader,
    setPreheader,
    testModalOpen,
    setTestModalOpen,
    tab,
    setTab,
    errors,
    setErrors,
    sampleFanId,
    setSampleFanId,
    sampleData,
    setSampleData,
    scheduledSend,
    setScheduledSend,
    scheduledSendPaneOpen,
    setScheduledSendPaneOpen,
    timeZone,
    setTimeZone,
    updateBroadcast,
    parseLiquid,
    sendDomain,
    availableSendDomains,
    emailSendDomain,
    setEmailSendDomain,
    selectedSendDomain,
    setSelectedSendDomain,
    beefreeTemplate,
    setBeefreeTemplate,
    debouncedFetchSegments,
    previouslySent,
    ahoyMessagesDataTableProps,
    ahoyMessageId,
    setAhoyMessageId,
    ahoyMessage,
    setAhoyMessage,
    segmentFilters,
    setSegmentFilters,
    visibleSegmentFilters,
    createSegmentFilter,
    updateSegmentFilter,
    destroySegmentFilter,
    fetchPreviousBroadcastTemplates,
    previousBroadcastTemplates,
    previewTemplate,
    setPreviewTemplate,
    fetchAllFansCount,
    fetchSegmentFilteredFansCount,
    recipientsCount,
    setRecipientsCount,
    checkTemplateHasContent,
    templateHasContent,
    setTemplateHasContent,
    scheduleBroadcast,
    scheduledAt,
    setScheduledAt,
    processedTemplate,
    setProcessedTemplate,
  };

  return (
    <BroadcastEditContext.Provider value={value}>
      {props.children}
    </BroadcastEditContext.Provider>
  );
};

const BroadcastEditContainer = (props) => (
  <BroadcastEditProvider {...props}>
    <ToastContainer />
    <BroadcastEdit />
  </BroadcastEditProvider>
);

export default BroadcastEditContainer;

import React, { useEffect, useRef } from "react";

import { useBroadcastEditContext } from "./BroadcastEditContainer";

import Flatpickr from "react-flatpickr";
import Select from "react-select";
import Tippy from "@tippyjs/react";

import SegmentFilters from "./SegmentFilters";
import { offsetDateFromTimeZone } from "../../../shared/timeZoneLogic";

const containerStyle = {
  background: "white",
  boxShadow: "-2px 1px 5px 0 rgba(0,0,0,0.1)",
  display: "grid",
  width: "100%",
};

const timeZonePickerStyle = {
  border: "1px solid #ccc",
  borderRadius: "4px",
  padding: "8px",
  width: "100%",
};

const flatPickrFormat = "D, F j, Y h:i K"

const DetailsTab = () => {
  const {
    broadcast,
    updateBroadcast,
    scheduledSend,
    sendDomain,
    selectedSendDomain,
    setSelectedSendDomain,
    subject,
    setSubject,
    preheader,
    setPreheader,
    updateSubject,
    scheduledAt,
    setScheduledAt,
    timeZone,
    setTimeZone,
    emailSendDomain,
    setEmailSendDomain,
    visibleSegmentFilters,
    previouslySent,
    fetchAllFansCount,
    fetchSegmentFilteredFansCount,
    recipientsCount,
    setRecipientsCount,
    setTab,
  } = useBroadcastEditContext();

  const isInitialRender = useRef(true);

  const debouncedUpdateBroadcast = _.debounce(() => {
    updateBroadcast({
      broadcast: {
        subject: subject,
        preheader: preheader,
        email_send_domain_attributes: {
          id: emailSendDomain.id,
          name: emailSendDomain.name,
        },
        scheduled_sends_attributes: [{
          id: scheduledSend.id,
          scheduled_at: offsetDateFromTimeZone(scheduledAt, timeZone),
        }],
      }
    });
  }, 2000);

  useEffect(() => {
    if(broadcast.recipient_type === "all_fans") {
      fetchAllFansCount();
    } else if(broadcast.recipient_type === "conditional") {
      if(broadcast.segment_type === "existing") {
        fetchSegmentFilteredFansCount();
      } else {
        setRecipientsCount("--");
      }
    }
  }, []);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      debouncedUpdateBroadcast();
    }

    return () => {
      debouncedUpdateBroadcast.cancel();
    }
  }, [scheduledAt, subject, preheader, emailSendDomain]);

  return (
    <div style={containerStyle}>
      <div className="p-4">
        <div className="mb-3" style={{ width: "500px" }}>
          <label
            className="mb-1 text-muted"
            style={{ fontSize: "13px" }}
          >
            Delivery date & time
          </label>
          <div className="flatpickr-date-input px-2 d-flex align-items-center">
            <div className="pr-2" style={{
              fontSize: "14px",
              color: "#1982C4",
            }}>
              <i className="far fa-calendar"></i>
            </div>
            <Flatpickr
              disabled={previouslySent}
              key={`${timeZone}`}
              placeholder={"Select date"}
              value={scheduledAt}
              options={{
                enableTime: true,
                dateFormat: flatPickrFormat,
                minDate: new Date(),
                timeZone: timeZone,
              }}
              onClose={dates => setScheduledAt(offsetDateFromTimeZone(dates[0], timeZone))}
            />
          </div>
        </div>
        <div className="mb-3" style={{ width: "500px" }}>
          <label
            className="mb-1 text-muted"
            style={{ fontSize: "13px" }}
          >
            From name
          </label>
          <Tippy
            content="We recommend using a name that your subscribers will recognize. Like your venue name."
            placement="right"
          >
            <input
              type="text"
              disabled={previouslySent}
              className="form-control rounded-lg"
              value={emailSendDomain.name}
              onChange={option => {
                setEmailSendDomain({
                  ...emailSendDomain,
                  name: option.target.value
                });
              }}
            />
          </Tippy>
        </div>
        <div className="mb-3" style={{ width: "500px" }}>
          <label
            className="mb-1 text-muted"
            style={{ fontSize: "13px" }}
          >
            From email
          </label>
          <Select
            isDisabled
            options={[sendDomain]}
            value={selectedSendDomain}
            getOptionLabel={option => option.full_from_address}
            onChange={option => setSelectedSendDomain({
              ...selectedSendDomain,
              from_address: option.from_address,
              domain: option.domain
            })}
          />
        </div>
        <div className="mb-3" style={{ width: "500px" }}>
          <label
            className="mb-1 text-muted"
            style={{ fontSize: "13px" }}
          >
            Subject line
          </label>
          <input
            type="text"
            disabled={previouslySent}
            className="form-control rounded-lg"
            value={subject || ""}
            onChange={option => setSubject(option.target.value)}
          />
        </div>
        <div className="mb-3" style={{ width: "500px" }}>
          <label
            className="mb-1 text-muted"
            style={{ fontSize: "13px" }}
          >
            Preheader
          </label>
          <input
            type="text"
            disabled={previouslySent}
            className="form-control rounded-lg"
            value={preheader || ""}
            onChange={option => setPreheader(option.target.value)}
          />
        </div>
        <div className="mb-3">
          <label
            className="mb-1 text-muted"
            style={{ fontSize: "13px" }}
          >
            Recipients
          </label>
          {recipientsCount !== "--" ? (
            <div
              className="p-3 rounded align-self-start"
              style={{
                width: "300px",
                backgroundColor: "#eef7fd",
              }}
            >
              <p className="mb-2 d-flex align-items-center justify-content-between">
                <strong>
                  {broadcast.recipient_type === "all_fans" ? "All fans" : "Segment filtered fans"}
                </strong>
                <button
                  className="btn btn-link btn-sm"
                  onClick={() => {
                    setTab("recipients");
                  }}
                >
                  <i className="fas fa-pencil ml-2" />
                </button>
              </p>
              <p
                className="mb-2 d-flex align-items-center justify-content-between"
                style={{ fontSize: "24px" }}
              >
                <strong>
                  {recipientsCount}
                </strong>
              </p>
              {broadcast.recipient_type === "all_fans" ? (
                <p className="mb-2">Total fans in this workspace</p>
              ) : (
                <p className="mb-2">Total fans matching conditions</p>
              )}
              {broadcast.recipient_type === "all_fans" ? (
                <div className="w-100 mt-3 p-3 rounded" style={{ backgroundColor: "#fff" }}>
                  <p className="mb-1">
                    <i className="mr-2 fas fa-bolt text-primary" />
                    <strong>
                      Best Practices
                    </strong>
                  </p>
                  <p className="mb-0" style={{ fontSize: "12px" }}>
                    Sending emails to everyone in your database can damage your sending reputation and deliverability.
                  </p>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
};

export default DetailsTab;

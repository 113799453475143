import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import OfferV2PreviewApp from '../../OfferV2Preview/startup/OfferV2PreviewApp';
import LinkOfferModalApp from '../../LinkOfferModal/startup/LinkOfferModalApp';

import {SettlementMath} from "../../SettlementMath/SettlementMath";
import BudgetLineItems from "./BudgetLineItems";
import ForecastColumn from "./ForecastColumn";
import BudgetBonusManager from "./BudgetBonusManager";
import DepositManager from "./DepositManager";
import AttachArtistToOffer from'../../../shared/AttachArtistToOffer';
import TagManagerApp from '../../TagManager/startup/TagManagerApp';

import {DebounceInput} from 'react-debounce-input';
import Select from 'react-select'
import Popup from "reactjs-popup";
import RichEditor from '../../RichEditor/components/RichEditor';
import Tippy from '@tippyjs/react';

import { ToastContainer, toast } from 'react-toastify';
import CurrencyInput from '../../CurrencyInput/components/CurrencyInput';

import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { EditorState } from 'draft-js';
import { stateFromHTML } from "draft-js-import-html";
import { stateToHTML } from 'draft-js-export-html';
import {GlobalCurrency} from '../../GlobalCurrency/GlobalCurrency';

var _ = require('lodash');
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

TimeAgo.addDefaultLocale(en)
const timeAgo = new TimeAgo('en-US')

const parseFloatFromString = (value) => {
  if(typeof(value) === "number"){
    return value;
  }

  return parseFloat(value.split(",").join(""));
}

var _ = require('lodash');
var debouncedAutoSaveOffer;
var debouncedAutoSaveBudget;

const inlineMenuContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "215px",
  "padding": "0px"
}

const templatetSaveMenuContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "215px",
  "padding": "0px"
}

const formatOptionLabel = ({ value, label }, {context}, deleteBudgetTemplate) => (
  <div className="d-flex justify-content-between budget-template-option">
    <p className="mb-0 text-overflow-ellipsis"
       title={label}>
      {label}
    </p>
    {context === "menu" ? (
      <a href="#"
         onClick={
           (e) => {
             e.preventDefault();
             e.stopPropagation();

             if(window.confirm("Are you sure? Delete \"" + label + "\"?")){
               deleteBudgetTemplate();
             }
           }
         }
         className="text-white">
        <i className="fas fa-trash"></i>
      </a>
    ) : null}
  </div>
);

const selectStyles = {
  container: (base, state) => ({
    ...base,
    opacity: state.isDisabled ? ".5" : "1",
    backgroundColor: "#F9F9F9",
    zIndex: "999",
    width: "155px",
    marginBottom: "-10px"
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: '#1982C4'
    }
  },
  control: (styles, state) => {
    return {
      ...styles,
      backgroundColor: "#F9F9F9",
      border: "none",
      boxShadow: 'none'
    };
  },
  valueContainer: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#F9F9F9",
      fontWeight: "bold"
    };
  },
  singleValue: (styles, { data }) => {
    return {
      ...styles,
      fontWeight: "bold",
      color: "#1982C4"
    };
  },
  indicatorSeparator: (styles, { data }) => {
    return {
      ...styles,
      display: "none"
    };
  },
  indicatorsContainer: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#F9F9F9",
      color: "#1982C4",
      fontWeight: "bold",
      fontSize: "16px",
      ':hover': {
        cursor: "pointer"
      }
    };
  },
  menu: (provided, state) => ({
    ...provided,
    width: "230px"
  }),
  option: (provided, state) => ({
    ...provided,
    color: state.isFocused ? 'white' : '#333333',
    background: state.isFocused ? '#1982C4' : 'white',
    fontWeight: state.isFocused ? 'bold' : 'normal',
    padding: "0px",
    "&:hover": {
      color: "white",
      background: "#1982C4",
      fontWeight: "bold"
    }
  }),
  dropdownIndicator: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: "#F9F9F9",
      color: "#1982C4",
      fontWeight: "bold",
      "&:hover": {
        color: "#1982C4"
      }
    };
  }
};

const deleteModalContentStyle = {
  "textAlign": "left",
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "500px",
  "padding": "15px"
}

const modalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "800px",
  "padding": "0"
}

const linkOfferModalContentStyle = {
  "border": "none",
  "borderRadius": "8px",
  "boxShadow": "0 0 1px 1px rgba(0,0,0,0.02), 0 4px 6px 1px rgba(0,0,0,0.06)",
  "width": "700px",
  "padding": "0"
}

// https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
const numberStringWithCommas = (x) => {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const formatCalculatedNumber = (calculated, options={}) => {
  var absValue  = Math.abs(calculated);
  var formatted = numberStringWithCommas(Number(absValue).toFixed(2));

  if(options.prefix){
    formatted = options.prefix + formatted;
  }

  if(calculated < 0){
    formatted = "(" + formatted + ")";
  }

  return formatted;
}

const formatFacilitiesMaintenanceFee = (calculated, forecast = false) => {
  if(Object.keys(calculated).length === 0){
    return 0.00;
  }

  var item = calculated
    .beforeAdjustedDeductionsSection
    .items
    .find((item) => item.name === "Facilities Maintenance Fee");

  return forecast ? item.calculatedForecastTotal : item.calculatedTotal;
}

const formatCharitableDeduction = (calculated, forecast = false) => {
  if(Object.keys(calculated).length === 0){
    return 0.00;
  }

  var item = calculated
    .beforeAdjustedDeductionsSection
    .items
    .find((item) => item.name === "Charitable Deduction");

  return forecast ? item.calculatedForecastTotal : item.calculatedTotal;
}

const canSubmitBudgetTemplate = (budgetTemplate) => {
  return (
    budgetTemplate
      && budgetTemplate.name
      && budgetTemplate.name.length > 0
  );
}

const updatedBudgetRemovingBudgetLineItem = (budget, budgetLineItem) => {
  var ticketLineItems = budget.ticket_line_items.filter((item) =>
    item.uuid !== budgetLineItem.uuid
  );

  var incomeLineItems = budget.income_line_items.filter((item) =>
    item.uuid !== budgetLineItem.uuid
  );

  var beforeAdjustedDeductionsLineItems = budget.before_adjusted_deductions_line_items.filter((item) =>
    item.uuid !== budgetLineItem.uuid
  );

  var expenseLineItems = budget.expense_line_items.filter((item) =>
    item.uuid !== budgetLineItem.uuid
  );

  return Object.assign({}, budget, {
    ticket_line_items: ticketLineItems,
    income_line_items: incomeLineItems,
    before_adjusted_deductions_line_items: beforeAdjustedDeductionsLineItems,
    expense_line_items: expenseLineItems
  });
}

const tableWidth = (showCurrencyConversion, showForecast) => {
  var baseWidth = 835;
  var currencyWidth = showCurrencyConversion ? 130 : 0;
  var forecastWidth = showForecast ? 365 : 60;

  var width = baseWidth + currencyWidth + forecastWidth;

  return `${width}px`;
}

const OfferEditUI = ({
  csrfToken,
  team,
  currentUser,
  offer,
  updateOfferAdditionalTermsFromHTML,
  autoSaveOffer,
  budget,
  createTicketBudgetLineItem,
  createIncomeBudgetLineItem,
  createBeforeAdjustedDeductionsBudgetLineItem,
  createExpensesBudgetLineItem,
  deleteBudgetLineItem,
  autoSaveBudgetLineItem,
  updateOffer,
  updateBudget,
  autoSaveBudget,
  acceptOffer,
  declineOffer,
  deleteOffer,
  calculated,
  updateCalculated,
  modalUIState,
  updateModalUIState,
  isSaving,
  isCreatingTemplate,
  isCreatingTemplateChanged,
  submitBudgetTemplate,
  budgetTemplates,
  useBudgetTemplateWithBudget,
  budgetTemplate,
  budgetTemplateNameChanged,
  budgetTemplateChanged,
  offerToLink,
  offerToLinkChanged,
  deleteBudgetTemplate,
  createBudgetBonus,
  deleteBudgetBonus,
  autoSaveBudgetBonus,
  attachArtistModalOpen,
  attachArtistModalOpenChanged,
  getActiveTags,
  getAvailableTags,
}) => {
  const hasSecondaryCurrencySymbol = (
    typeof(team.secondary_currency_symbol) === "string"
      && team.secondary_currency_symbol.length > 0
  );

  const currencySymbol = (
    hasSecondaryCurrencySymbol ?
      team.secondary_currency_symbol : "USD"
  )

  const globalCurrency = new GlobalCurrency(
    team.currency_conversion_rate, currencySymbol
  );

  const showSecondaryDollarAmountField = (
    hasSecondaryCurrencySymbol
      && offer.display_currency_conversion_for_offer
  );

  const showCurrencyConversion = (
    hasSecondaryCurrencySymbol
      && offer.display_currency_conversion_for_offer
      && !team.convert_artist_earnings_only
  );

  const convertedOrZero = (calculated, options={}) => {
    if(Object.keys(calculated).length === 0){
      return globalCurrency.formatted(0.00);
    }

    var amount = options.amount ?
      options.amount : _.get(calculated, options.dotPath, 0.00);

    return globalCurrency.formatted(amount);
  }

  useEffect(() => {
    updateOfferAdditionalTermsFromHTML(offer.additional_terms || "");

    var updateOnInit = {};

    if(budget.upside_percentage){
      updateOnInit = Object.assign({}, updateOnInit, {
        upside_percentage: parseFloatFromString(budget.upside_percentage).toFixed(2)
      });
    }

    if(budget.dollar_amount_with_two_decimals){
      updateOnInit = Object.assign({}, updateOnInit, {
        dollar_amount: budget.dollar_amount_with_two_decimals
      });
    }

    var budgetBonuses = [...budget.budget_bonuses].map((bb) => {
      var attrs = {};

      if(bb.dollar_amount){
        var dollarAmountTwoDecimals = parseFloatFromString(bb.dollar_amount).toFixed(2);
        attrs = {...attrs, dollar_amount: dollarAmountTwoDecimals};
      }

      if(bb.upside_percentage){
        var upsidePercentageTwoDecimals = parseFloatFromString(bb.upside_percentage).toFixed(2);
        attrs = {...attrs, upside_percentage: upsidePercentageTwoDecimals};
      }

      return Object.assign({}, bb, attrs);
    });
    updateOnInit = Object.assign({}, updateOnInit, {
      budget_bonuses: budgetBonuses
    });

    var updated = Object.assign({}, budget, updateOnInit);
    updateBudget(updated);

    debouncedAutoSaveOffer = _.debounce((csrfToken, team, offer, additionalOfferTerms) => {
      autoSaveOffer(csrfToken, team, offer, additionalOfferTerms);
    }, 2000);

    debouncedAutoSaveBudget = _.debounce((csrfToken, team, budget) => {
      autoSaveBudget(csrfToken, team, budget);
    }, 2000);

    var calculator = new SettlementMath(budget);
    var calculated = calculator.calculate();
    updateCalculated(calculated);
  }, [])

  useEffect(() => {
    setDollarAmount(budget.dollar_amount || "");
    setUpsidePercentage(budget.upside_percentage || "");
  }, [budget])

  const [dollarAmount, setDollarAmount] = useState(budget.dollar_amount || "");
  const [upsidePercentage, setUpsidePercentage] = useState(budget.upside_percentage || "");
  const [additionalOfferTerms, setAdditionalOfferTerms] = useState(null);
  const [secondaryDollarAmount, setSecondaryDollarAmount] = useState("");

  const forecastColgroup = (
    <colgroup>
      <col style={{minWidth: "110px", maxWidth: "110px"}} />
      <col style={{minWidth: "95px", maxWidth: "95px"}} />
      {showCurrencyConversion ? (
        <col style={{minWidth: "130px", maxWidth: "130px"}} />
      ) : null}
      <col style={{minWidth: "130px", maxWidth: "130px"}} />
    </colgroup>
  );

  useEffect(() => {
    setAdditionalOfferTerms(
      EditorState.createWithContent(stateFromHTML(offer.additional_terms || ""))
    );
  }, [offer.additional_terms])

  useEffect(() => {
    var amount = budget.dollar_amount;
    var converted = "";

    if(amount && amount.length > 0){
      var dollarAmount = parseFloatFromString(amount);
      converted = globalCurrency.convert(dollarAmount).toFixed(2);
    }

    setSecondaryDollarAmount(converted);
  }, [budget.dollar_amount])

  return (
    <div>
      <ToastContainer />
      <div className="row">
        <div className="col-12 mb-1">
          <div className="page-title">
            {offer.event_name}
            {offer.state === "draft" ? (
              <span className="badge badge-secondary ml-3 p-2"
                    style={{"fontSize": "10px"}}>
                Draft
              </span>
            ) : offer.state === "sent" ? (
              <span className="badge badge-primary ml-3 p-2"
                    style={{"fontSize": "10px"}}>
                Sent
              </span>
            ) : offer.state === "accepted" ? (
              <span className="badge badge-success ml-3 p-2"
                    style={{"fontSize": "10px"}}>
                Accepted
              </span>
            ) : offer.state === "declined" ? (
              <span className="badge badge-danger ml-3 p-2"
                    style={{"fontSize": "10px"}}>
                Declined
              </span>
            ) : null}
            {offer.calendar_event && offer.calendar_event.id ? (
              <a href={`/teams/${team.id}/confirms/${offer.calendar_event.id}`}
                  className='ml-3'
                  style={{"fontSize": "14px", "fontWeight": "600"}}>
                Go to Event Details <i className="fas fa-arrow-right"></i>
              </a>
            ) : null}
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="col d-flex">
          <p className="text-muted mb-0 align-self-center">
            {offer.human_metadata}
          </p>
        </div>
        <div className="col-xs-auto"
             style={{"paddingRight": "15px"}}>
          <Popup
              trigger={
                <a href="#"
                   className="btn btn-external shadow-2 btn-sm"
                   style={{"borderRadius": "8px"}}>
                  <i className="fas fa-cog"></i>
                </a>
              }
              onOpen={
                (e) => {
                  e.preventDefault();
                }
              }
              arrow={false}
              offsetY={10}
              position="bottom right"
              contentStyle={inlineMenuContentStyle}
              closeOnDocumentClick
            >
            {close => (
              <div>
                <div className="row">
                  <div className="col">
                    <ul className="list-group offer-inline-menu highlight-primary">
                      {!offer.primary_performer ? (
                        <li className="list-group-item">
                          <a href="#"
                             className="text-dark"
                             onClick={
                               (e) => {
                                 e.preventDefault();

                                 attachArtistModalOpenChanged(true);
                                 close();
                               }
                             }>
                            <i className="text-muted fas fa-link fa-fw mx-2"></i>
                            <span style={{"fontWeight": 'normal'}}>
                              Attach artist
                            </span>
                          </a>
                        </li>
                      ) : null}
                      <li className="list-group-item">
                        <a href={`/teams/${team.id}/offers/${offer.id}`}
                           className="text-dark">
                          <i className="text-muted fas fa-eye fa-fw mx-2"></i>
                          <span style={{"fontWeight": 'normal'}}>
                            Preview
                          </span>
                        </a>
                      </li>
                      <li className="list-group-item">
                        <a href={"/teams/" + team.id + "/offers/" + offer.id + "/preview.pdf"}
                           target={"_blank"}
                           className="text-dark">
                          <i className="text-muted fas fa-arrow-to-bottom fa-fw mx-2"></i>
                          <span style={{"fontWeight": 'normal'}}>
                            Download
                          </span>
                        </a>
                      </li>
                      {["draft", "sent"].includes(offer.state) ? (
                        <li className="list-group-item">
                          <a href="#"
                             className="text-dark"
                             onClick={
                               (e) => {
                                 e.preventDefault();
                                 acceptOffer(team, offer.id, csrfToken);
                                 close();
                               }
                             }>
                            <i className="text-muted far fa-check-circle fa-fw mx-2"></i>
                            <span style={{"fontWeight": 'normal'}}>
                              Mark accepted
                            </span>
                          </a>
                        </li>
                      ) : null}
                      {["draft", "sent"].includes(offer.state) ? (
                        <li className="list-group-item">
                          <a href="#"
                             className="text-dark"
                             onClick={
                               (e) => {
                                 e.preventDefault();
                                 declineOffer(team, offer.id, csrfToken);
                                 close();
                               }
                             }>
                            <i className="text-muted far fa-times-circle fa-fw mx-2"></i>
                            <span style={{"fontWeight": 'normal'}}>
                              Mark declined
                            </span>
                          </a>
                        </li>
                      ) : null}
                      {!offer.calendar_event ? (
                        <li className="list-group-item">
                          <a href="#"
                             className="text-dark"
                             onClick={
                               (e) => {
                                 e.preventDefault();
                                 close();
                                 offerToLinkChanged(offer);
                               }
                             }>
                            <i className="text-muted far fa-link fa-fw mx-2"></i>
                            <span style={{"fontWeight": 'normal'}}>
                              Link with event
                            </span>
                          </a>
                        </li>
                      ) : null}
                      <li className="list-group-item delete-offer-li border-0">
                        <Popup
                            trigger={
                              <a href="#"
                                 onClick={
                                   (e) => {
                                     e.preventDefault();
                                   }
                                 }
                                 className="text-danger">
                                <i className="far fa-trash fa-fw mx-2"></i>
                                <span style={{"fontWeight": 'normal'}}>
                                  Delete
                                </span>
                              </a>
                            }
                            modal
                            contentStyle={deleteModalContentStyle}
                            closeOnDocumentClick
                          >
                          {closeDeletePopup => (
                            <div>
                              <div className="row">
                                <div className="col">
                                  <div className="row">
                                    <div className="col">
                                      <h5 style={{"marginBottom": "16px"}}>
                                        <div className="strong">Delete offer?</div>
                                      </h5>
                                    </div>
                                    <div className="col-xs-auto">
                                      <a href="#"
                                         className="text-dark"
                                         style={{"paddingRight": "15px"}}
                                         onClick={(e) => {e.preventDefault(); closeDeletePopup();}} >
                                        <i className="fa fa-times"></i>
                                      </a>
                                    </div>
                                  </div>
                                  <p>You're about to delete this offer.</p>
                                  <p>This cannot be undone.</p>
                                  <p>Are you sure?</p>
                                  <div className="text-right">
                                    <a href="#"
                                       onClick={
                                         (e) => {
                                           e.preventDefault();
                                           closeDeletePopup();
                                         }
                                       }>
                                      Cancel
                                    </a>
                                    <a href="#"
                                       className="btn btn-danger ml-3"
                                       onClick={
                                         (e) => {
                                           e.preventDefault();
                                           closeDeletePopup();
                                           close();
                                           deleteOffer(team, offer.id, csrfToken);
                                         }
                                       }>
                                      Yes, delete offer
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </Popup>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </Popup>
        </div>
      </div>
      <div className='row'>
        <div className="mt-3 col-12 col-md-6 d-flex align-items-end justify-content-start small">
          <div className='row'>
            {budgetTemplates.length > 0 ? (
              <div className="col-xs-auto"
                   style={{
                     "paddingLeft": "15px"
                   }}>
                <Select styles={selectStyles}
                        className="mr-4"
                        placeholder={"Select a template"}
                        components={{ ClearIndicator:() => null }}
                        options={budgetTemplates.sort((a, b) => a.label.localeCompare(b.label))}
                        value={budgetTemplates.find((t) => t.value === budget.template_id)}
                        formatOptionLabel={
                          (data, meta) => {
                            return formatOptionLabel(data, meta, () => {
                              deleteBudgetTemplate(csrfToken, team, data.value);
                            });
                          }
                        }
                        onChange={
                          (selected) => {
                            isCreatingTemplateChanged(false);
                            useBudgetTemplateWithBudget(csrfToken, team, budget, offer, selected.value);
                          }
                        } />
              </div>
            ) : null}
            <div className="col d-flex align-items-end justify-content-start small">
              {isCreatingTemplate ? (
                <div>
                  <form onSubmit={
                    (e) => {
                      e.preventDefault();

                      if(!canSubmitBudgetTemplate(budgetTemplate)){
                        return false;
                      }

                      submitBudgetTemplate(csrfToken, team, offer, budget, budgetTemplate, additionalOfferTerms);
                    }
                  }>
                    <div className="input-group">
                      <input type="text"
                             className="form-control"
                             value={budgetTemplate.name || ""}
                             onChange={
                               (e) => {
                                 budgetTemplateNameChanged(e.target.value);
                               }
                             }
                             style={{
                               "border": "1px solid #e6e6e6",
                               "borderRight": "none",
                               "borderTopLeftRadius": "4px",
                               "borderBottomLeftRadius": "4px"
                             }}
                             placeholder="Enter template name..." />
                      <div className="input-group-append">
                        <button className="btn text-primary"
                                style={{
                                  "background": "white",
                                  "border": "1px solid #e6e6e6",
                                  "borderLeft": "none",
                                  "borderTopRightRadius": "4px",
                                  "borderBottomRightRadius": "4px"
                                }}
                                disabled={!canSubmitBudgetTemplate(budgetTemplate)}
                                type="submit">
                          <strong>Save</strong>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                budgetTemplates.map((t) => t.value).includes(budget.template_id) ? (
                  <Popup
                      trigger={
                        <a href="#"
                           onClick={
                             (e) => {
                               e.preventDefault();
                             }
                           }>
                          <strong>
                            Save to current template
                            <i className="fas fa-caret-down ml-3"></i>
                          </strong>
                        </a>
                      }
                      onOpen={
                        (e) => {
                          e.preventDefault();
                        }
                      }
                      arrow={false}
                      offsetY={10}
                      position="bottom center"
                      contentStyle={templatetSaveMenuContentStyle}
                      closeOnDocumentClick
                    >
                    {close => (
                      <div>
                        <div className="row">
                          <div className="col">
                            <ul className="list-group offer-inline-menu highlight-primary"
                                style={{"fontSize": "14px"}}>
                              <li className="list-group-item pl-3 pb-2 pt-3">
                                <a href="#"
                                   className="text-dark"
                                   onClick={
                                     (e) => {
                                       e.preventDefault();

                                       var option = budgetTemplates.find((t) => t.value === budget.template_id);
                                       var budgetTemplate = Object.assign({}, {
                                         id: option.value,
                                         name: option.label
                                       });

                                       budgetTemplateChanged(budgetTemplate);
                                       isCreatingTemplateChanged(true);
                                       close();
                                     }
                                   }>
                                  <span style={{"fontWeight": 'normal'}}>
                                    Save to current template
                                  </span>
                                </a>
                              </li>
                              <li className="list-group-item pl-3 pt-2 pb-3">
                                <a href="#"
                                   className="text-dark"
                                   onClick={
                                     (e) => {
                                       e.preventDefault();
                                       isCreatingTemplateChanged(true);
                                       close();
                                     }
                                   }>
                                  <span style={{"fontWeight": 'normal'}}>
                                    Save as new template
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                ) : (
                  <a href="#"
                     onClick={
                       (e) => {
                         e.preventDefault();
                         isCreatingTemplateChanged(true);
                       }
                     }>
                    <strong>
                      Save as...
                      <i className="far fa-copy ml-3"></i>
                    </strong>
                  </a>
                )
              )}
            </div>
          </div>
        </div>
        <div className="mt-3 col-12 col-md-6 d-flex align-items-end justify-content-end">
          {isSaving ? (
            <a href="#"
               className="text-muted">
              <i className="far fa-sync mr-2 fa-rotate-90"></i>
              Automatically saving changes
            </a>
          ) : (
            <Tippy content={
                     <div style={{
                            "fontSize": "10px"
                          }}>
                       <p className="mb-0">Every change you make is automatically saved in Opendate.</p>
                       <p className="mb-0">Last edit was {timeAgo.format(new Date(offer.updated_at))}.</p>
                     </div>
                   }
                   placement={'top-start'}
                   maxWidth={390}
                   animation={'shift-away'}
                   theme='material'>
              <a href="#"
                 className="text-muted">
                <i className="far fa-check-circle mr-2"></i>
                Changes automatically saved
              </a>
            </Tippy>
          )}
          <a href="#"
             className={"ml-3 btn btn-primary"}
             onClick={
               (e) => {
                 e.preventDefault();
                 updateModalUIState("SEND");
               }
             }>
            <strong>
              Send Offer
            </strong>
          </a>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <div className="card no-border shadow-2">
            <div className="card-body"
                 style={{"padding": "35px 25px"}}>
              <div className="form-row">
                <div className="col-12 col-lg-6 col-xl-8">
                  <div className='row mb-2'>
                    <div className='col-12 d-flex align-items-center'>
                      <p className='mb-0' style={{"fontSize": "16px"}}>
                        <strong>Offer Details</strong>
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div className={"col-12"}>
                      <div className="form-row"
                          style={{"marginBottom": "14px"}}>
                        <div className="col-xs-auto"
                          style={showSecondaryDollarAmountField ? {"width": "350px"} : {"width": "200px"}}>
                          <div className="form-group mb-0">
                            <label className="text-muted"
                                  style={{"whiteSpace": "nowrap"}}
                                  htmlFor="offer-dollar-amount">
                              Offer Amount
                            </label>
                            {showSecondaryDollarAmountField ? (
                              <div className='d-flex'>
                                <div className="input-group inline-white"
                                    style={{
                                      "border": "1px solid #e6e6e6",
                                      "borderRadius": "4px",
                                      "borderTopRightRadius": "0px",
                                      "borderBottomRightRadius": "0px"
                                    }}>
                                  <div className="input-group-prepend">
                                    <span className="input-group-text px-1"
                                          style={{"color": "#9b9b9b"}}
                                          id="offer-dollar-amount-dollar-sign">
                                      <strong>{team.base_currency_symbol}</strong>
                                    </span>
                                  </div>
                                  <CurrencyInput className="form-control px-0"
                                                maskOptions={{
                                                  prefix: "",
                                                  integerLimit: null
                                                }}
                                                placeholder={"0"}
                                                value={budget.dollar_amount || ""}
                                                onChange={
                                                  (e) => {
                                                    var updated = Object.assign({}, budget, {dollar_amount: e.target.value});

                                                    updateBudget(updated);
                                                    debouncedAutoSaveBudget(csrfToken, team, updated);
                                                  }
                                                }
                                                id="offer-dollar-amount"
                                                aria-describedby="offer-dollar-amount-dollar-sign" />
                                </div>
                                <div className="input-group inline-white"
                                    style={{
                                      "borderTop": "1px solid #e6e6e6",
                                      "borderRight": "1px solid #e6e6e6",
                                      "borderBottom": "1px solid #e6e6e6",
                                      "borderRadius": "4px",
                                      "borderTopLeftRadius": "0px",
                                      "borderBottomLeftRadius": "0px"
                                    }}>
                                  <div className="input-group-prepend">
                                    <span className="input-group-text px-1"
                                          style={{"color": "#9b9b9b"}}
                                          id="offer-secondary-dollar-amount-dollar-sign">
                                      <strong>{team.secondary_currency_symbol}</strong>
                                    </span>
                                  </div>
                                  <CurrencyInput className="form-control px-0"
                                                maskOptions={{
                                                  prefix: "",
                                                  integerLimit: null
                                                }}
                                                placeholder={"0"}
                                                value={secondaryDollarAmount || ""}
                                                onChange={
                                                  (e) => {
                                                    var amount = e.target.value;
                                                    var converted = "";

                                                    if(amount && amount.length > 0){
                                                      var dollarAmount = parseFloatFromString(amount);
                                                      converted = globalCurrency.revert(dollarAmount).toFixed(2);
                                                    }

                                                    var updated = {
                                                      ...budget,
                                                      dollar_amount: converted
                                                    }

                                                    updateBudget(updated);
                                                    setSecondaryDollarAmount(amount);
                                                    debouncedAutoSaveBudget(csrfToken, team, updated);
                                                  }
                                                }
                                                id="offer-secondary-dollar-amount"
                                                aria-describedby="offer-secondary-dollar-amount-dollar-sign" />
                                </div>
                              </div>
                            ) : (
                              <div className="input-group inline-white"
                                  style={{
                                    "border": "1px solid #e6e6e6",
                                    "borderRadius": "4px"
                                  }}>
                                <div className="input-group-prepend">
                                  <span className="input-group-text px-2"
                                        id="offer-dollar-amount-dollar-sign">
                                    <i className="far fa-dollar-sign text-muted"></i>
                                  </span>
                                </div>
                                <CurrencyInput
                                  id="offer-dollar-amount"
                                  aria-describedby="offer-dollar-amount-dollar-sign"
                                  className="form-control"
                                  maskOptions={{ prefix: "", integerLimit: null }}
                                  placeholder={"Enter amount"}
                                  value={dollarAmount || 0.00}
                                  onChange={e => {
                                    setDollarAmount(current => {
                                      if(current !== e.target.value){
                                        var updated = {
                                          ...budget,
                                          dollar_amount: e.target.value
                                        };

                                        updateBudget(updated);
                                        debouncedAutoSaveBudget(csrfToken, team, updated);

                                        return updated.dollar_amount;
                                      }
                                    })
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-xs-auto">
                          <div className="btn-group btn-group-sm"
                              style={{
                                "marginTop": "29px",
                                "border": "1px solid #1982C4",
                                "borderRadius": "9px",
                                "background": "#1982C4"
                              }}
                              role="group">
                            <button type="button"
                                    style={{"borderTopLeftRadius": "8px", "borderBottomLeftRadius": "8px"}}
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                        var updated = Object.assign({}, budget, {dollar_or_percentage_operator: "AND"});

                                        updateBudget(updated);
                                        debouncedAutoSaveBudget(csrfToken, team, updated);
                                      }
                                    }
                                    className={"btn strong " + (budget.dollar_or_percentage_operator === 'AND' ? "btn-primary" : "btn-external-inverse")}>
                              <i className='fa-solid fa-plus mx-1'></i>
                            </button>
                            <button type="button"
                                    style={{"borderTopRightRadius": "8px", "borderBottomRightRadius": "8px"}}
                                    onClick={
                                      (e) => {
                                        e.preventDefault();
                                        var updated = Object.assign({}, budget, {dollar_or_percentage_operator: "OR"});

                                        updateBudget(updated);
                                        debouncedAutoSaveBudget(csrfToken, team, updated);
                                      }
                                    }
                                    className={"btn strong " + (budget.dollar_or_percentage_operator === 'OR' ? "btn-primary" : "btn-external-inverse")}>
                              VS
                            </button>
                          </div>
                        </div>
                        <div className="col-xs-auto"
                              style={{"maxWidth": "200px"}}>
                          <div className="form-group mb-0">
                            <label className="text-muted"
                                  style={{"whiteSpace": "nowrap"}}
                                  htmlFor="offer-upside-percentage">
                              Percentage of ticket sales
                            </label>
                            <div className="input-group inline-white"
                                style={{
                                  "border": "1px solid #e6e6e6",
                                  "borderRadius": "4px"
                                }}>
                              <CurrencyInput
                                id="offer-upside-percentage"
                                aria-describedby="offer-upside-percentage-percentage"
                                className="form-control"
                                maskOptions={{ prefix: "", integerLimit: null }}
                                placeholder={"0.00"}
                                value={upsidePercentage || 0.00}
                                onChange={e => {
                                  setUpsidePercentage(current => {
                                    if(current !== e.target.value){
                                      var updated = {
                                        ...budget,
                                        upside_percentage: e.target.value
                                      }

                                      updateBudget(updated);
                                      debouncedAutoSaveBudget(csrfToken, team, updated);

                                      return updated.upside_percentage;
                                    }
                                  })
                                }}
                              />
                              <div className="input-group-append">
                                <span className="input-group-text px-2"
                                      id="offer-upside-percentage-percentage">
                                  <i className="far fa-percentage text-muted"></i>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <BudgetBonusManager csrfToken={csrfToken}
                                      team={team}
                                      currentUser={currentUser}
                                      updateBudget={updateBudget}
                                      debouncedAutoSaveBudget={debouncedAutoSaveBudget}
                                      createBudgetBonus={createBudgetBonus}
                                      deleteBudgetBonus={deleteBudgetBonus}
                                      autoSaveBudgetBonus={autoSaveBudgetBonus}
                                      budget={budget} />
                  <div className='row'>
                    <div
                      className='col-12 mb-2 d-flex align-items-center'
                      style={{"height": "42px"}}
                    >
                      <div className="custom-control custom-checkbox custom-checkbox-table">
                        <input
                          id="offer-promoter-profit"
                          type="checkbox"
                          className="custom-control-input"
                          checked={budget.promoter_profit}
                          onChange={e => {
                            var updated = Object.assign({}, budget, {
                              promoter_profit: e.target.checked
                            });

                            updateBudget(updated);
                            autoSaveBudget(csrfToken, team, updated)
                          }}
                        />
                        <label
                          htmlFor="offer-promoter-profit"
                          className="custom-control-label"
                          style={{"fontSize": "14px"}}
                        >
                          <strong>
                            Promoter Profit
                          </strong>
                        </label>
                      </div>
                      {budget.promoter_profit ? (
                        <div
                          className="ml-3 input-group inline-white"
                          style={{
                            width: "120px",
                            border: "1px solid #e6e6e6",
                            borderRadius: "4px"
                          }}
                        >
                          <CurrencyInput
                            id="offer-promoter-profit-percentage"
                            aria-describedby="offer-promoter-profit-percentage"
                            className="form-control pr-1"
                            style={{
                              textAlign: "right"
                            }}
                            maskOptions={{
                              prefix: "",
                              integerLimit: null,
                              allowDecimal: true,
                              decimalLimit: 5,
                            }}
                            placeholder={"0.0"}
                            value={budget.promoter_profit_percentage || 0.00}
                            onChange={e => {
                              var updated = Object.assign({}, budget, {
                                promoter_profit_percentage: e.target.value
                              });

                              updateBudget(updated);
                              debouncedAutoSaveBudget(csrfToken, team, updated);
                            }}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text px-2">
                              <i className="far fa-percentage text-muted"></i>
                            </span>
                          </div>
                        </div>
                      ) : (null)}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                  {hasSecondaryCurrencySymbol ? (
                    <div className='col-12 mb-2 pr-0 d-flex align-items-center justify-content-end'>
                      <div className="custom-control custom-checkbox custom-checkbox-table">
                        <input type="checkbox"
                                className="custom-control-input"
                                checked={offer.display_currency_conversion_for_offer}
                                onChange={
                                  (e) => {
                                    var updated = Object.assign({}, offer, {
                                      display_currency_conversion_for_offer: e.target.checked
                                    });

                                    updateOffer(updated);
                                    autoSaveOffer(csrfToken, team, updated, additionalOfferTerms);
                                  }
                                }
                                id="offer-display-currency-conversion-for-offer" />
                        <label className="custom-control-label"
                                style={{"fontSize": "14px"}}
                                htmlFor="offer-display-currency-conversion-for-offer">
                          {`Display currency conversion (1 ${team.base_currency_symbol} / ${team.currency_conversion_rate} ${team.secondary_currency_symbol})`}
                        </label>
                      </div>
                    </div>
                  ) : null}
                  <div className="card border-0 my-3 ml-auto px-4 col-12 col-lg-10"
                       style={{"background": "#f4f9fc"}}>
                    <div className="card-body px-0 py-3">
                      <p className='mb-2 text-center text-lg-left' style={{"fontSize": "16px"}}>
                        <strong>
                          Tickets to Breakeven
                          {Object.keys(calculated).length > 0 && calculated.artistEarnings.ticketsToBreakeven ? (
                            <Tippy
                              content={
                                <div style={{"fontSize": "10px", "padding": "5px 8px"}}>
                                  <p className="mb-0">
                                    Tickets to breakeven is found by dividing the sum of all show expenses and artist earnings by the price of each individual ticket type. The average is then calculated to arrive at the final estimate.
                                  </p>
                                  <table
                                    className='table table-dark table-sm table-borderless'
                                    style={{"marginTop": "20px", "background": "#333333"}}>
                                    <thead>
                                      <tr>
                                        <th className='text-left py-0'>
                                          <span>
                                            Total Expenses
                                          </span>
                                          {calculated.artistEarningsForecast.ticketsToBreakeven ? (
                                            <span className="ml-1">
                                              (forecasted)
                                            </span>
                                          ) : null}
                                        </th>
                                        <th className='text-right py-0'>Ticket Price</th>
                                        <th className='text-right py-0'>Breakeven</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {calculated.ticketsToBreakeven.breakevenByIncomeItem.map((data, index) =>
                                        <tr key={index}>
                                          <td className='text-left py-0'>
                                            <span>
                                              {formatter.format(calculated.ticketsToBreakeven.totalExpenses)}
                                            </span>
                                            {calculated.artistEarningsForecast.ticketsToBreakeven ? (
                                              <span className="ml-1">
                                                ({formatter.format(calculated.ticketsToBreakevenForecast.totalExpenses)})
                                              </span>
                                            ) : null}
                                          </td>
                                          <td className='text-right py-0'>{data.item.name} {formatter.format(data.item.calculatedLeftHand)}</td>
                                          <td className='text-right py-0'>
                                            <span className="mr-1">
                                              {data.breakeven.toLocaleString()}
                                            </span>
                                            {calculated.ticketsToBreakevenForecast.breakevenByIncomeItem[index] ? (
                                              <span>
                                                ({calculated.ticketsToBreakevenForecast.breakevenByIncomeItem[index].breakeven.toLocaleString()})
                                              </span>
                                            ) : null}
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                  <p className="mb-2 text-right">
                                    <span className="mr-4">
                                      ({calculated.ticketsToBreakeven.breakevenByIncomeItem.map((i) => i.breakeven.toLocaleString()).join(" + ")}) / {calculated.ticketsToBreakeven.breakevenByIncomeItem.length} = {calculated.artistEarnings.ticketsToBreakeven.toLocaleString()}
                                    </span>
                                    {calculated.artistEarningsForecast.ticketsToBreakeven ? (
                                      <span>
                                        ({calculated.ticketsToBreakevenForecast.breakevenByIncomeItem.map((i) => i.breakeven.toLocaleString()).join(" + ")}) / {calculated.ticketsToBreakevenForecast.breakevenByIncomeItem.length} = {calculated.artistEarningsForecast.ticketsToBreakeven.toLocaleString()}
                                      </span>
                                    ) : null}
                                  </p>
                                </div>
                              }
                              placement={'top'}
                              maxWidth={390}
                              animation={'shift-away'}
                              theme='material'>
                              <a href="#" className="text-muted">
                                <i className="fas fa-info-circle ml-2"></i>
                              </a>
                            </Tippy>
                          ) : null}
                        </strong>
                      </p>
                      <h3 className="mb-3 text-center text-lg-left">
                        {Object.keys(calculated).length > 0 && calculated.artistEarnings.ticketsToBreakeven ? (
                          calculated.artistEarnings.ticketsToBreakeven.toLocaleString()
                        ) : (
                          0
                        )}
                        <span
                          className="ml-2"
                          style={{"fontSize": "16px"}}
                        >
                          as offered
                        </span>
                      </h3>
                      {Object.keys(calculated).length > 0 && calculated.artistEarningsForecast.ticketsToBreakeven ? (
                        <h4 className="mb-0 text-center text-lg-left">
                          {calculated.artistEarningsForecast.ticketsToBreakeven.toLocaleString()}
                          <span
                            className="text-muted ml-2"
                            style={{"fontSize": "16px"}}
                          >
                            as forecasted
                          </span>
                        </h4>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-auto col-12 col-lg-6 col-xl-5 px-0">
                <TagManagerApp
                  csrfToken={csrfToken}
                  team={team}
                  currentUser={currentUser}
                  scope="Offer"
                  title="Tags for Offer"
                  taggable={offer}
                  activeTagsAlignment="right"
                />
              </div>
              <hr className="mb-4" style={{"borderTop": "2px solid #e6e6e6"}} />
              <div style={{
                "maxWidth": "100%",
                "overflowX": "auto",
                "overflowY": "hidden"
              }}>
                <table
                  className="table mt-3 mb-4 budget-line-item-table"
                  style={{
                    minWidth: tableWidth(showCurrencyConversion, offer.display_forecast),
                    tableLayout: "fixed"
                  }}>
                  <tbody className="border-0">
                    <tr>
                      <td style={{padding: "0px", width: "auto", minWidth: "180px"}}>&nbsp;</td>
                      <td style={{padding: "0px", width: "75px"}}>&nbsp;</td>
                      <td style={{padding: "0px", width: "75px"}}>&nbsp;</td>
                      <td style={{padding: "0px", width: "75px"}}>&nbsp;</td>
                      <td style={{padding: "0px", width: "100px"}}>&nbsp;</td>
                      <td style={{padding: "0px", width: "110px"}}>&nbsp;</td>
                      {showCurrencyConversion ? (
                        <td style={{padding: "0px", width: "130px"}}>&nbsp;</td>
                      ) : null}
                      <td style={{padding: "0px", width: "130px"}}>&nbsp;</td>
                      {offer.display_forecast ? (
                        <td style={{padding: "0px", width: showCurrencyConversion ? "495px" : "365px"}}>&nbsp;</td>
                      ) : (
                        <td style={{padding: "0px", width: "60px"}}>&nbsp;</td>
                      )}
                      <td style={{padding: "0px", width: "10px"}}>&nbsp;</td>
                    </tr>
                  </tbody>
                  <tbody className="border-0">
                    <tr>
                      <td
                        colSpan={showCurrencyConversion ? 8 : 7}
                        className="pb-0">
                        <h3 style={{"fontSize": "20px"}}
                            className="mb-1">
                          <strong>Budget</strong>
                        </h3>
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        colgroup={forecastColgroup}
                        top={true}
                        showCurrencyConversion={showCurrencyConversion}
                      >
                        <div className={offer.display_forecast ? "text-right pr-3" : "text-center"}>
                          <Tippy content={offer.display_forecast ? "Hide Forecast" : "Show Forecast"}
                                 placement="top"
                                 maxWidth={120}
                                 animation="shift-away"
                                 theme="material">
                            <a href="#"
                               onClick={e => {
                                e.preventDefault();

                                var updated = Object.assign({}, offer, {
                                  display_forecast: !offer.display_forecast
                                });

                                updateOffer(updated);
                                autoSaveOffer(csrfToken, team, updated, additionalOfferTerms);
                               }}
                              >
                              <i className="fas fa-columns-3"></i>
                            </a>
                          </Tippy>
                        </div>
                      </ForecastColumn>
                      <td className="px-0">
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={showCurrencyConversion ? 8 : 7}>
                        <h4 style={{"fontSize": "16px"}}
                            className="mb-0">
                          <strong>INCOME</strong>
                        </h4>
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        showCurrencyConversion={showCurrencyConversion}
                      >
                        <div className="text-center strong">
                          Forecast
                        </div>
                      </ForecastColumn>
                      <td className="px-0">
                        &nbsp;
                      </td>
                    </tr>
                  </tbody>
                  <BudgetLineItems
                    team={team}
                    csrfToken={csrfToken}
                    currentUser={currentUser}
                    budget={budget}
                    calculatedBudget={calculated}
                    budgetLineItems={budget.ticket_line_items}
                    header={"Tickets"}
                    updateBudget={updateBudget}
                    formatCalculatedNumber={formatCalculatedNumber}
                    total={Object.keys(calculated).length > 0 ? (
                      calculated.ticketsSection.total
                    ) : (
                      0.00
                    )}
                    getCalculatedRow={budgetLineItem => {
                      var items = Object.keys(calculated).length > 0 ?
                       calculated.ticketsSection.items : [];

                      return items.find((item) => {
                        return item.uuid === budgetLineItem.uuid;
                      });
                    }}
                    namePrefix={"Ticket #"}
                    onAddRow={e => {
                      createTicketBudgetLineItem(csrfToken, team, budget, (budget.ticket_line_items.length + 1));
                    }}
                    onDeleteRow={budgetLineItem => {
                      var updated = updatedBudgetRemovingBudgetLineItem(budget, budgetLineItem);

                      updateBudget(updated);
                      deleteBudgetLineItem(csrfToken, team, budgetLineItem);
                    }}
                    autoSaveBudgetLineItem={autoSaveBudgetLineItem}
                    showCurrencyConversion={showCurrencyConversion}
                    globalCurrency={globalCurrency}
                    showForecast={offer.display_forecast}
                    forecastColgroup={forecastColgroup}
                  />
                  <tbody>
                    <tr>
                      <td
                        colSpan={showCurrencyConversion ? 8 : 7}
                        className="p-0 border-0">
                        &nbsp;
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        showCurrencyConversion={showCurrencyConversion}
                      />
                      <td className="px-0">&nbsp;</td>
                    </tr>
                  </tbody>
                  <BudgetLineItems
                    team={team}
                    csrfToken={csrfToken}
                    currentUser={currentUser}
                    budget={budget}
                    calculatedBudget={calculated}
                    budgetLineItems={budget.income_line_items}
                    header={"Other Income"}
                    updateBudget={updateBudget}
                    formatCalculatedNumber={formatCalculatedNumber}
                    total={Object.keys(calculated).length > 0 ? (
                      calculated.revenueSection.total
                    ) : (
                      0.00
                    )}
                    getCalculatedRow={budgetLineItem => {
                      var items = Object.keys(calculated).length > 0 ?
                       calculated.revenueSection.items : [];

                      return items.find((item) => {
                        return item.uuid === budgetLineItem.uuid;
                      });
                    }}
                    namePrefix={"Income #"}
                    onAddRow={e => {
                      createIncomeBudgetLineItem(csrfToken, team, budget, (budget.income_line_items.length + 1));
                    }}
                    onDeleteRow={budgetLineItem => {
                      var updated = updatedBudgetRemovingBudgetLineItem(budget, budgetLineItem);

                      updateBudget(updated);
                      deleteBudgetLineItem(csrfToken, team, budgetLineItem);
                    }}
                    autoSaveBudgetLineItem={autoSaveBudgetLineItem}
                    showCurrencyConversion={showCurrencyConversion}
                    globalCurrency={globalCurrency}
                    showForecast={offer.display_forecast}
                    forecastColgroup={forecastColgroup}
                  />
                  <tbody>
                    <tr>
                      <td colSpan={showCurrencyConversion ? 8 : 7}>
                        &nbsp;
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        showCurrencyConversion={showCurrencyConversion}
                      />
                      <td className="px-0">&nbsp;</td>
                    </tr>
                    <tr>
                      <td colSpan="6" className="border-0 text-right">
                        <strong>
                          Gross Potential
                        </strong>
                      </td>
                      {showCurrencyConversion  ? (
                        <td className="border-0 text-right pl-0">
                          <strong>
                            {convertedOrZero(calculated, {
                              amount: Object.keys(calculated).length > 0 ?
                                calculated.ticketsSection.total + calculated.revenueSection.total : 0
                            })}
                          </strong>
                        </td>
                      ) : null}
                      <td className="border-0 text-right">
                        <strong>
                          {Object.keys(calculated).length > 0 ? (
                            formatCalculatedNumber(calculated.ticketsSection.total + calculated.revenueSection.total, {prefix: "$"})
                          ) : (
                            formatCalculatedNumber(0.00, {prefix: "$"})
                          )}
                        </strong>
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        colgroup={forecastColgroup}
                        showCurrencyConversion={showCurrencyConversion}
                        values={{
                          convertedTotal: (
                            <strong>
                              {convertedOrZero(calculated, {
                                amount: Object.keys(calculated).length > 0 ?
                                  calculated.ticketsSection.forecastTotal + calculated.revenueSection.forecastTotal : 0
                              })}
                            </strong>
                          ),
                          total: (
                            <strong>
                              {Object.keys(calculated).length > 0 ? (
                                formatCalculatedNumber(calculated.ticketsSection.forecastTotal + calculated.revenueSection.forecastTotal, {prefix: "$"})
                              ) : (
                                formatCalculatedNumber(0.00, {prefix: "$"})
                              )}
                            </strong>
                          )
                        }}
                      />
                      <td className="px-0">&nbsp;</td>
                    </tr>
                  </tbody>
                  <tbody className="border-0">
                    <tr>
                      <td colSpan={showCurrencyConversion ? 8 : 7}>
                        <h4 style={{"fontSize": "16px"}}
                          className="mb-0">
                          <strong>BEFORE ADJUSTED DEDUCTIONS</strong>
                        </h4>
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        showCurrencyConversion={showCurrencyConversion}
                      />
                      <td className="px-0">&nbsp;</td>
                    </tr>
                  </tbody>
                  <BudgetLineItems
                    team={team}
                    csrfToken={csrfToken}
                    currentUser={currentUser}
                    budget={budget}
                    calculatedBudget={calculated}
                    budgetLineItems={budget.before_adjusted_deductions_line_items}
                    header={"Before Adjusted Deductions"}
                    updateBudget={updateBudget}
                    formatCalculatedNumber={formatCalculatedNumber}
                    total={Object.keys(calculated).length > 0 ? (
                      calculated.beforeAdjustedDeductionsSection.total
                    ) : (
                      0.00
                    )}
                    getCalculatedRow={budgetLineItem => {
                      var items = Object.keys(calculated).length > 0
                        ? calculated.beforeAdjustedDeductionsSection.items
                        : [];

                      return items.find((item) => {
                        return item.uuid === budgetLineItem.uuid;
                      });
                    }}
                    namePrefix={"Before Adjusted Deduction #"}
                    onAddRow={e => {
                      createBeforeAdjustedDeductionsBudgetLineItem(csrfToken, team, budget, (budget.before_adjusted_deductions_line_items.length + 1));
                    }}
                    onDeleteRow={budgetLineItem => {
                      var updated = updatedBudgetRemovingBudgetLineItem(budget, budgetLineItem);

                      updateBudget(updated);
                      deleteBudgetLineItem(csrfToken, team, budgetLineItem);
                    }}
                    autoSaveBudgetLineItem={autoSaveBudgetLineItem}
                    showCurrencyConversion={showCurrencyConversion}
                    globalCurrency={globalCurrency}
                    showForecast={offer.display_forecast}
                    forecastColgroup={forecastColgroup}
                  />
                  <tbody>
                    <tr>
                      <td
                        colSpan={showCurrencyConversion ? 8 : 7}
                        className="p-0 border-0">
                        &nbsp;
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        showCurrencyConversion={showCurrencyConversion}
                      />
                      <td className="px-0">&nbsp;</td>
                    </tr>
                    <tr>
                      <td colSpan="6" className="border-0 text-right">
                        <strong>
                          Adjusted Gross Potential
                        </strong>
                      </td>
                      {showCurrencyConversion  ? (
                        <td className="border-0 text-right pl-0">
                          <strong>
                            {Object.keys(calculated).length > 0 ? (
                              convertedOrZero(calculated, {dotPath: "showSummary.netBoxOfficeReceipts"})
                            ) : (
                              formatCalculatedNumber(0.00, {prefix: "$"})
                            )}
                          </strong>
                        </td>
                      ) : null}
                      <td className="border-0 text-right">
                        <strong>
                          {Object.keys(calculated).length > 0 ? (
                            formatCalculatedNumber(calculated.showSummary.netBoxOfficeReceipts, {prefix: "$"})
                          ) : (
                            formatCalculatedNumber(0.00, {prefix: "$"})
                          )}
                        </strong>
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        colgroup={forecastColgroup}
                        showCurrencyConversion={showCurrencyConversion}
                        values={{
                          convertedTotal: (
                            <strong>
                              {Object.keys(calculated).length > 0 ? (
                                convertedOrZero(calculated, {dotPath: "showSummary.netBoxOfficeReceiptsForecast"})
                              ) : (
                                formatCalculatedNumber(0.00, {prefix: "$"})
                              )}
                            </strong>
                          ),
                          total: (
                            <strong>
                              {Object.keys(calculated).length > 0 ? (
                                formatCalculatedNumber(calculated.showSummary.netBoxOfficeReceiptsForecast, {prefix: "$"})
                              ) : (
                                formatCalculatedNumber(0.00, {prefix: "$"})
                              )}
                            </strong>
                          )
                        }}
                      />
                      <td className="px-0">&nbsp;</td>
                    </tr>
                  </tbody>
                  <tbody className="border-0">
                    <tr>
                      <td colSpan={showCurrencyConversion ? 8 : 7}>
                        <h4 style={{"fontSize": "16px"}}
                          className="mb-0">
                          <strong>EXPENSES</strong>
                        </h4>
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        showCurrencyConversion={showCurrencyConversion}
                      />
                      <td className="px-0">&nbsp;</td>
                    </tr>
                  </tbody>
                  <BudgetLineItems
                    team={team}
                    csrfToken={csrfToken}
                    currentUser={currentUser}
                    budget={budget}
                    calculatedBudget={calculated}
                    budgetLineItems={budget.expense_line_items}
                    header={"Expenses"}
                    updateBudget={updateBudget}
                    formatCalculatedNumber={formatCalculatedNumber}
                    total={Object.keys(calculated).length > 0 ? (
                      calculated.expensesSection.total + calculated.finalExpensesSection.total
                    ) : (
                      0.00
                    )}
                    getCalculatedRow={budgetLineItem => {
                      var items = Object.keys(calculated).length > 0
                        ? calculated.expensesSection.items.concat(calculated.finalExpensesSection.items)
                        : [];

                      return items.find((item) => {
                        return item.uuid === budgetLineItem.uuid;
                      });
                    }}
                    namePrefix={"Expense #"}
                    onAddRow={e => {
                      createExpensesBudgetLineItem(csrfToken, team, budget, (budget.expense_line_items.length + 1));
                    }}
                    onDeleteRow={budgetLineItem => {
                      var updated = updatedBudgetRemovingBudgetLineItem(budget, budgetLineItem);

                      updateBudget(updated);
                      deleteBudgetLineItem(csrfToken, team, budgetLineItem);
                    }}
                    autoSaveBudgetLineItem={autoSaveBudgetLineItem}
                    showCurrencyConversion={showCurrencyConversion}
                    globalCurrency={globalCurrency}
                    showForecast={offer.display_forecast}
                    forecastColgroup={forecastColgroup}
                  />
                  <tbody>
                    <tr>
                      <td
                        colSpan={showCurrencyConversion ? 8 : 7}
                        className="p-0">
                        &nbsp;
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        showCurrencyConversion={showCurrencyConversion}
                      />
                      <td className="px-0">&nbsp;</td>
                    </tr>
                    <tr>
                      <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                        Gross Potential
                      </td>
                      {showCurrencyConversion  ? (
                        <td className="pl-0 pt-0 pb-0 border-0 text-right">
                          {convertedOrZero(calculated, {
                            amount: Object.keys(calculated).length > 0 ?
                              calculated.ticketsSection.total + calculated.revenueSection.total : 0
                          })}
                        </td>
                      ) : null}
                      <td className="pl-0 pt-0 pb-0 border-0 text-right">
                        {Object.keys(calculated).length > 0 ? (
                          formatCalculatedNumber(calculated.ticketsSection.total + calculated.revenueSection.total, {prefix: "$"})
                        ) : (
                          formatCalculatedNumber(0.00, {prefix: "$"})
                        )}
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        colgroup={forecastColgroup}
                        showCurrencyConversion={showCurrencyConversion}
                        height="auto"
                        values={{
                          convertedTotal: (
                            convertedOrZero(calculated, {
                              amount: Object.keys(calculated).length > 0 ?
                                calculated.ticketsSection.forecastTotal + calculated.revenueSection.forecastTotal : 0
                            })
                          ),
                          total: (
                            Object.keys(calculated).length > 0 ? (
                              formatCalculatedNumber(
                                calculated.ticketsSection.forecastTotal + calculated.revenueSection.forecastTotal,
                                {prefix: "$"}
                              )
                            ) : (
                              formatCalculatedNumber(0, {prefix: "$"})
                            )
                          )
                        }}
                      />
                    </tr>
                    {Object.keys(calculated).length > 0 && calculated.beforeAdjustedDeductionsSection.total > 0 ? (
                      <>
                        <tr>
                          <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                            Before Adjusted Deductions
                          </td>
                          {showCurrencyConversion  ? (
                            <td className="pl-0 pt-0 pb-0 border-0 text-right">
                              {Object.keys(calculated).length > 0 ? (
                                `(${convertedOrZero(calculated, {dotPath: "beforeAdjustedDeductionsSection.total"})})`
                              ) : (
                                `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                              )}
                            </td>
                          ) : null}
                          <td className="pl-0 pt-0 pb-0 border-0 text-right">
                            {Object.keys(calculated).length > 0 ? (
                              `(${formatCalculatedNumber(calculated.beforeAdjustedDeductionsSection.total, {prefix: "$"})})`
                            ) : (
                              `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                            )}
                          </td>
                          <ForecastColumn
                            show={offer.display_forecast}
                            colgroup={forecastColgroup}
                            showCurrencyConversion={showCurrencyConversion}
                            height="auto"
                            values={{
                              convertedTotal: (
                                Object.keys(calculated).length > 0 ? (
                                  `(${convertedOrZero(calculated, {dotPath: "beforeAdjustedDeductionsSection.forecastTotal"})})`
                                ) : (
                                  `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                                )
                              ),
                              total: (
                                Object.keys(calculated).length > 0 ? (
                                  `(${formatCalculatedNumber(calculated.beforeAdjustedDeductionsSection.forecastTotal, {prefix: "$"})})`
                                ) : (
                                  `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                                )
                              )
                            }}
                          />
                        </tr>
                        <tr>
                          <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                            Adjusted Gross Potential
                          </td>
                          {showCurrencyConversion  ? (
                            <td className="pl-0 pt-0 pb-0 border-0 text-right">
                              {Object.keys(calculated).length > 0 ? (
                                convertedOrZero(calculated, {dotPath: "showSummary.netBoxOfficeReceipts"})
                              ) : (
                                formatCalculatedNumber(0.00, {prefix: "$"})
                              )}
                            </td>
                          ) : null}
                          <td className="pl-0 pt-0 pb-0 border-0 text-right">
                            {Object.keys(calculated).length > 0 ? (
                              formatCalculatedNumber(calculated.showSummary.netBoxOfficeReceipts, {prefix: "$"})
                            ) : (
                              formatCalculatedNumber(0.00, {prefix: "$"})
                            )}
                          </td>
                          <ForecastColumn
                            show={offer.display_forecast}
                            colgroup={forecastColgroup}
                            showCurrencyConversion={showCurrencyConversion}
                            height="auto"
                            values={{
                              convertedTotal: (
                                Object.keys(calculated).length > 0 ? (
                                  convertedOrZero(calculated, {dotPath: "showSummary.netBoxOfficeReceiptsForecast"})
                                ) : (
                                  formatCalculatedNumber(0.00, {prefix: "$"})
                                )
                              ),
                              total: (
                                Object.keys(calculated).length > 0 ? (
                                  formatCalculatedNumber(calculated.showSummary.netBoxOfficeReceiptsForecast, {prefix: "$"})
                                ) : (
                                  formatCalculatedNumber(0.00, {prefix: "$"})
                                )
                              )
                            }}
                          />
                        </tr>
                      </>
                    ) : null}
                    <tr>
                      <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                        Expenses
                      </td>
                      {showCurrencyConversion  ? (
                        <td className="pl-0 pt-0 pb-0 border-0 text-right">
                          {Object.keys(calculated).length > 0 ? (
                            `(${convertedOrZero(calculated, {dotPath: "expensesSection.total"})})`
                          ) : (
                            `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                          )}
                        </td>
                      ) : null}
                      <td className="pl-0 pt-0 pb-0 border-0 text-right">
                        {Object.keys(calculated).length > 0 ? (
                          `(${formatCalculatedNumber(calculated.expensesSection.total, {prefix: "$"})})`
                        ) : (
                          `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                        )}
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        colgroup={forecastColgroup}
                        showCurrencyConversion={showCurrencyConversion}
                        height="auto"
                        values={{
                          convertedTotal: (
                            Object.keys(calculated).length > 0 ? (
                              `(${convertedOrZero(calculated, {dotPath: "expensesSection.forecastTotal"})})`
                            ) : (
                              `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                            )
                          ),
                          total: (
                            Object.keys(calculated).length > 0 ? (
                              `(${formatCalculatedNumber(calculated.expensesSection.forecastTotal, {prefix: "$"})})`
                            ) : (
                              `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                            )
                          )
                        }}
                      />
                    </tr>
                    <tr>
                      <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                        <strong>
                          Show Net, After Expenses
                        </strong>
                      </td>
                      {showCurrencyConversion  ? (
                        <td className="pl-0 pt-0 pb-0 border-0 text-right">
                          <strong>
                            {Object.keys(calculated).length > 0 ? (
                              convertedOrZero(calculated, {dotPath: "showSummary.showNetAfterExpenses"})
                            ) : (
                              formatCalculatedNumber(0.00, {prefix: "$"})
                            )}
                          </strong>
                        </td>
                      ) : null}
                      <td className="pl-0 pt-0 pb-0 border-0 text-right">
                        <strong>
                          {Object.keys(calculated).length > 0 ? (
                            formatCalculatedNumber(calculated.showSummary.showNetAfterExpenses, {prefix: "$"})
                          ) : (
                            formatCalculatedNumber(0.00, {prefix: "$"})
                          )}
                        </strong>
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        colgroup={forecastColgroup}
                        showCurrencyConversion={showCurrencyConversion}
                        height="auto"
                        values={{
                          convertedTotal: (
                            <strong>
                              {Object.keys(calculated).length > 0 ? (
                                convertedOrZero(calculated, {dotPath: "showSummary.showNetAfterExpensesForecast"})
                              ) : (
                                formatCalculatedNumber(0.00, {prefix: "$"})
                              )}
                            </strong>
                          ),
                          total: (
                            <strong>
                              {Object.keys(calculated).length > 0 ? (
                                formatCalculatedNumber(calculated.showSummary.showNetAfterExpensesForecast, {prefix: "$"})
                              ) : (
                                formatCalculatedNumber(0.00, {prefix: "$"})
                              )}
                            </strong>
                          )
                        }}
                      />
                    </tr>
                    {Object.keys(calculated).length > 0 && calculated.finalExpensesSection.total > 0 ? (
                      <>
                        <tr>
                          <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                            <span>Final Expenses</span>
                            <Tippy content={
                                     <div style={{
                                            "fontSize": "10px"
                                          }}>
                                       <p className="mb-0">Final Expenses include any expense that require the total expenses or show net to be calculated beforehand:</p>
                                       <ul className="mt-2 mb-2">
                                         <li className="pl-0">Percentage of Expenses calculations</li>
                                         <li className="pl-0">Percentage of Net calculations</li>
                                       </ul>
                                     </div>
                                   }
                                   placement={'top'}
                                   maxWidth={390}
                                   animation={'shift-away'}
                                   theme='material'>
                              <a href="#" className="text-muted ml-2">
                                <i className="fas fa-info-circle"></i>
                              </a>
                            </Tippy>
                          </td>
                          {showCurrencyConversion  ? (
                            <td className="pl-0 pt-0 pb-0 border-0 text-right">
                              {Object.keys(calculated).length > 0 ? (
                                `(${convertedOrZero(calculated, {dotPath: "finalExpensesSection.total"})})`
                              ) : (
                                `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                              )}
                            </td>
                          ) : null}
                          <td className="pl-0 pt-0 pb-0 border-0 text-right">
                            {Object.keys(calculated).length > 0 ? (
                              `(${formatCalculatedNumber(calculated.finalExpensesSection.total, {prefix: "$"})})`
                            ) : (
                              `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                            )}
                          </td>
                          <ForecastColumn
                            show={offer.display_forecast}
                            colgroup={forecastColgroup}
                            showCurrencyConversion={showCurrencyConversion}
                            height="auto"
                            values={{
                              convertedTotal: (
                                Object.keys(calculated).length > 0 ? (
                                  `(${convertedOrZero(calculated, {dotPath: "finalExpensesSection.forecastTotal"})})`
                                ) : (
                                  `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                                )
                              ),
                              total: (
                                Object.keys(calculated).length > 0 ? (
                                  `(${formatCalculatedNumber(calculated.finalExpensesSection.forecastTotal, {prefix: "$"})})`
                                ) : (
                                  `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                                )
                              )
                            }}
                          />
                        </tr>
                        <tr>
                          <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                            <strong>
                              Show Net, After Final Expenses
                            </strong>
                          </td>
                          {showCurrencyConversion  ? (
                            <td className="pl-0 pt-0 pb-0 border-0 text-right">
                              <strong>
                                {Object.keys(calculated).length > 0 ? (
                                  convertedOrZero(calculated, {dotPath: "showSummary.showNetAfterFinalExpenses"})
                                ) : (
                                  formatCalculatedNumber(0.00, {prefix: "$"})
                                )}
                              </strong>
                            </td>
                          ) : null}
                          <td className="pl-0 pt-0 pb-0 border-0 text-right">
                            <strong>
                              {Object.keys(calculated).length > 0 ? (
                                formatCalculatedNumber(calculated.showSummary.showNetAfterFinalExpenses, {prefix: "$"})
                              ) : (
                                formatCalculatedNumber(0.00, {prefix: "$"})
                              )}
                            </strong>
                          </td>
                          <ForecastColumn
                            show={offer.display_forecast}
                            colgroup={forecastColgroup}
                            showCurrencyConversion={showCurrencyConversion}
                            height="auto"
                            values={{
                              convertedTotal: (
                                <strong>
                                  {Object.keys(calculated).length > 0 ? (
                                    convertedOrZero(calculated, {dotPath: "showSummary.showNetAfterFinalExpensesForecast"})
                                  ) : (
                                    formatCalculatedNumber(0.00, {prefix: "$"})
                                  )}
                                </strong>
                              ),
                              total: (
                                <strong>
                                  {Object.keys(calculated).length > 0 ? (
                                    formatCalculatedNumber(calculated.showSummary.showNetAfterFinalExpensesForecast, {prefix: "$"})
                                  ) : (
                                    formatCalculatedNumber(0.00, {prefix: "$"})
                                  )}
                                </strong>
                              )
                            }}
                          />
                        </tr>
                      </>
                    ) : null}
                    {Object.keys(calculated).length > 0 && calculated.showSummary.promoterProfit > 0 ? (
                      <>
                        <tr>
                          <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                            <span>Promoter Profit</span>
                          </td>
                          {showCurrencyConversion  ? (
                            <td className="pl-0 pt-0 pb-0 border-0 text-right">
                              {Object.keys(calculated).length > 0 ? (
                                `(${convertedOrZero(calculated, {dotPath: "showSummary.promoterProfit"})})`
                              ) : (
                                `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                              )}
                            </td>
                          ) : null}
                          <td className="pl-0 pt-0 pb-0 border-0 text-right">
                            {Object.keys(calculated).length > 0 ? (
                              `(${formatCalculatedNumber(calculated.showSummary.promoterProfit, {prefix: "$"})})`
                            ) : (
                              `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                            )}
                          </td>
                          <ForecastColumn
                            show={offer.display_forecast}
                            colgroup={forecastColgroup}
                            showCurrencyConversion={showCurrencyConversion}
                            height="auto"
                            values={{
                              convertedTotal: (
                                Object.keys(calculated).length > 0 ? (
                                  `(${convertedOrZero(calculated, {dotPath: "showSummary.promoterProfitForecast"})})`
                                ) : (
                                  `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                                )
                              ),
                              total: (
                                Object.keys(calculated).length > 0 ? (
                                  `(${formatCalculatedNumber(calculated.showSummary.promoterProfitForecast, {prefix: "$"})})`
                                ) : (
                                  `(${formatCalculatedNumber(0.00, {prefix: "$"})})`
                                )
                              )
                            }}
                          />
                        </tr>
                        <tr>
                          <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                            <strong>
                              Show Net, After Promoter Profit
                            </strong>
                          </td>
                          {showCurrencyConversion  ? (
                            <td className="pl-0 pt-0 pb-0 border-0 text-right">
                              <strong>
                                {Object.keys(calculated).length > 0 ? (
                                  convertedOrZero(calculated, {dotPath: "showSummary.showNetAfterPromoterProfit"})
                                ) : (
                                  formatCalculatedNumber(0.00, {prefix: "$"})
                                )}
                              </strong>
                            </td>
                          ) : null}
                          <td className="pl-0 pt-0 pb-0 border-0 text-right">
                            <strong>
                              {Object.keys(calculated).length > 0 ? (
                                formatCalculatedNumber(calculated.showSummary.showNetAfterPromoterProfit, {prefix: "$"})
                              ) : (
                                formatCalculatedNumber(0.00, {prefix: "$"})
                              )}
                            </strong>
                          </td>
                          <ForecastColumn
                            show={offer.display_forecast}
                            colgroup={forecastColgroup}
                            showCurrencyConversion={showCurrencyConversion}
                            height="auto"
                            values={{
                              convertedTotal: (
                                <strong>
                                  {Object.keys(calculated).length > 0 ? (
                                    convertedOrZero(calculated, {dotPath: "showSummary.showNetAfterPromoterProfitForecast"})
                                  ) : (
                                    formatCalculatedNumber(0.00, {prefix: "$"})
                                  )}
                                </strong>
                              ),
                              total: (
                                <strong>
                                  {Object.keys(calculated).length > 0 ? (
                                    formatCalculatedNumber(calculated.showSummary.showNetAfterPromoterProfitForecast, {prefix: "$"})
                                  ) : (
                                    formatCalculatedNumber(0.00, {prefix: "$"})
                                  )}
                                </strong>
                              )
                            }}
                          />
                        </tr>
                      </>
                    ) : null}
                    <tr>
                      <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                        Split Point
                      </td>
                      {showCurrencyConversion  ? (
                        <td className="pl-0 pt-0 pb-0 border-0 text-right">
                          {Object.keys(calculated).length > 0 ? (
                            convertedOrZero(calculated, {dotPath: "showSummary.splitPoint"})
                          ) : (
                            formatCalculatedNumber(0.00, {prefix: "$"})
                          )}
                        </td>
                      ) : null}
                      <td className="pl-0 pt-0 pb-0 border-0 text-right">
                        {Object.keys(calculated).length > 0 ? (
                          formatCalculatedNumber(calculated.showSummary.splitPoint, {prefix: "$"})
                        ) : (
                          formatCalculatedNumber(0.00, {prefix: "$"})
                        )}
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        colgroup={forecastColgroup}
                        showCurrencyConversion={showCurrencyConversion}
                        height="auto"
                        values={{
                          convertedTotal: (
                            Object.keys(calculated).length > 0 ? (
                              convertedOrZero(calculated, {dotPath: "showSummary.splitPointForecast"})
                            ) : (
                              formatCalculatedNumber(0.00, {prefix: "$"})
                            )
                          ),
                          total: (
                            Object.keys(calculated).length > 0 ? (
                              formatCalculatedNumber(calculated.showSummary.splitPointForecast, {prefix: "$"})
                            ) : (
                              formatCalculatedNumber(0.00, {prefix: "$"})
                            )
                          )
                        }}
                      />
                    </tr>
                    <tr>
                      <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                        Artist Amount from Split
                      </td>
                      {showCurrencyConversion  ? (
                        <td className="pl-0 pt-0 pb-0 border-0 text-right">
                          {Object.keys(calculated).length > 0 ? (
                            convertedOrZero(calculated, {dotPath: "showSummary.artistPercentage"})
                          ) : (
                            formatCalculatedNumber(0.00, {prefix: "$"})
                          )}
                        </td>
                      ) : null}
                      <td className="pl-0 pt-0 pb-0 border-0 text-right">
                        {Object.keys(calculated).length > 0 ? (
                          formatCalculatedNumber(calculated.showSummary.artistPercentage, {prefix: "$"})
                        ) : (
                          formatCalculatedNumber(0.00, {prefix: "$"})
                        )}
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        colgroup={forecastColgroup}
                        showCurrencyConversion={showCurrencyConversion}
                        height="auto"
                        values={{
                          convertedTotal: (
                            Object.keys(calculated).length > 0 ? (
                              convertedOrZero(calculated, {dotPath: "showSummary.artistPercentageForecast"})
                            ) : (
                              formatCalculatedNumber(0.00, {prefix: "$"})
                            )
                          ),
                          total: (
                            Object.keys(calculated).length > 0 ? (
                              formatCalculatedNumber(calculated.showSummary.artistPercentageForecast, {prefix: "$"})
                            ) : (
                              formatCalculatedNumber(0.00, {prefix: "$"})
                            )
                          )
                        }}
                      />
                    </tr>
                    <tr>
                      <td
                        colSpan={showCurrencyConversion ? 8 : 7}
                        className="p-0"
                      >
                        &nbsp;
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        showCurrencyConversion={showCurrencyConversion}
                      />
                    </tr>
                    <tr style={{fontSize: "16px"}}>
                      <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                        <strong>
                          Potential Artist Earnings
                        </strong>
                      </td>
                      {showCurrencyConversion  ? (
                        <td className="pl-0 pt-0 pb-0 border-0 text-right">
                          <strong>
                            {Object.keys(calculated).length > 0 ? (
                              convertedOrZero(calculated, {dotPath: "artistEarnings.artistTotalDue"})
                            ) : (
                              formatCalculatedNumber(0.00, {prefix: "$"})
                            )}
                          </strong>
                        </td>
                      ) : null}
                      <td className="pl-0 pt-0 pb-0 border-0 text-right">
                        <strong>
                          {Object.keys(calculated).length > 0 ? (
                            formatCalculatedNumber(calculated.artistEarnings.artistTotalDue, {prefix: "$"})
                          ) : (
                            formatCalculatedNumber(0.00, {prefix: "$"})
                          )}
                        </strong>
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        colgroup={forecastColgroup}
                        showCurrencyConversion={showCurrencyConversion}
                        height="auto"
                        values={{
                          convertedTotal: (
                            <strong style={{fontSize: "16px"}}>
                              {Object.keys(calculated).length > 0 ? (
                                convertedOrZero(calculated, {dotPath: "artistEarningsForecast.artistTotalDue"})
                              ) : (
                                formatCalculatedNumber(0.00, {prefix: "$"})
                              )}
                            </strong>
                          ),
                          total: (
                            <strong style={{fontSize: "16px"}}>
                              {Object.keys(calculated).length > 0 ? (
                                formatCalculatedNumber(calculated.artistEarningsForecast.artistTotalDue, {prefix: "$"})
                              ) : (
                                formatCalculatedNumber(0.00, {prefix: "$"})
                              )}
                            </strong>
                          )
                        }}
                      />
                    </tr>
                    <tr style={{fontSize: "16px"}}>
                      <td colSpan="6" className="pl-0 pt-0 pb-0 border-0 text-right">
                        <strong>
                          Venue Net Potential
                        </strong>
                      </td>
                      {showCurrencyConversion  ? (
                        <td className="pl-0 pt-0 pb-0 border-0 text-right">
                          <strong>
                            {Object.keys(calculated).length > 0 ? (
                              convertedOrZero(calculated, {dotPath: "showSummary.venueNetPotential"})
                            ) : (
                              formatCalculatedNumber(0.00, {prefix: "$"})
                            )}
                          </strong>
                        </td>
                      ) : null}
                      <td className="pl-0 pt-0 pb-0 border-0 text-right">
                        <strong>
                          {Object.keys(calculated).length > 0 ? (
                            formatCalculatedNumber(calculated.showSummary.venueNetPotential, {prefix: "$"})
                          ) : (
                            formatCalculatedNumber(0.00, {prefix: "$"})
                          )}
                        </strong>
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        colgroup={forecastColgroup}
                        showCurrencyConversion={showCurrencyConversion}
                        height="auto"
                        values={{
                          convertedTotal: (
                            <strong style={{fontSize: "16px"}}>
                              {Object.keys(calculated).length > 0 ? (
                                convertedOrZero(calculated, {dotPath: "showSummary.venueNetPotentialForecast"})
                              ) : (
                                formatCalculatedNumber(0.00, {prefix: "$"})
                              )}
                            </strong>
                          ),
                          total: (
                            <strong style={{fontSize: "16px"}}>
                              {Object.keys(calculated).length > 0 ? (
                                formatCalculatedNumber(calculated.showSummary.venueNetPotentialForecast, {prefix: "$"})
                              ) : (
                                formatCalculatedNumber(0.00, {prefix: "$"})
                              )}
                            </strong>
                          )
                        }}
                      />
                    </tr>
                    <tr>
                      <td
                        colSpan={showCurrencyConversion ? 8 : 7}
                        className="p-0">
                        &nbsp;
                      </td>
                      <ForecastColumn
                        show={offer.display_forecast}
                        bottom={true}
                        showCurrencyConversion={showCurrencyConversion}
                      />
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr className="mb-4"
                  style={{"borderTop": "2px solid #e6e6e6"}} />
              <div className="row">
                <div className="col-12 col-xl-6" style={{"marginBottom": "16px"}}>
                  <div className="form-group mb-0">
                    <h3 style={{"fontSize": "20px"}}>
                      <strong>
                        Merchandising
                      </strong>
                    </h3>
                  </div>
                  <div className='form-row'>
                    <div className="col-6 col-xl-3 text-truncate">
                      <div className="form-group" style={{"marginBottom": "2px"}}>
                        <label className="text-muted"
                              style={{"marginBottom": "3px"}}
                              htmlFor="offer-soft-merch-rate">
                          Soft Merch Rate (artist %)
                        </label>
                        <div className="input-group inline-white"
                            style={{
                              "border": "1px solid #ececec",
                              "borderRadius": "4px"
                            }}>
                          <CurrencyInput className="form-control form-control-sm"
                                   maskOptions={{
                                     prefix: "",
                                     integerLimit: null
                                   }}
                                   placeholder={"0"}
                                   value={budget.soft_merch_rate || ""}
                                   onChange={
                                     (e) => {
                                       var updated = Object.assign({}, budget, {
                                          soft_merch_rate: e.target.value
                                       });

                                       updateBudget(updated);
                                       debouncedAutoSaveBudget(csrfToken, team, updated);
                                     }
                                   }
                                   id="offer-soft-merch-rate" />
                          <div className="input-group-append">
                            <span className="input-group-text px-2"
                                  id="offer-upside-percentage-percentage">
                              <i className="far fa-percentage text-muted"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-xl-3">
                      <div className="form-group" style={{"marginBottom": "2px"}}>
                        <label className="text-muted"
                              style={{"marginBottom": "3px"}}
                              htmlFor="offer-soft-merch-sold-by">
                          Sold by
                        </label>
                        <select className="form-control form-control-sm"
                                style={{"border": "1px solid #ececec"}}
                                value={budget.soft_merch_sold_by || ""}
                                onChange={
                                  (e) => {
                                    var updated = Object.assign({}, budget, {
                                      soft_merch_sold_by: e.target.value
                                    });

                                    updateBudget(updated);
                                    debouncedAutoSaveBudget(csrfToken, team, updated);
                                  }
                                }
                                id="offer-soft-merch-sold-by">
                          <option value="Artist">Artist</option>
                          <option value="Venue">Venue</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-xl-6">
                      <div className="form-group" style={{"marginBottom": "2px"}}>
                        <label className="text-muted"
                              style={{"marginBottom": "3px"}}
                              htmlFor="offer-soft-merch-notes">
                          Soft Merch Notes
                        </label>
                        <DebounceInput className="form-control form-control-sm"
                                type="text"
                                style={{"border": "1px solid #ececec"}}
                                value={budget.soft_merch_notes || ""}
                                onChange={
                                  (e) => {
                                    var updated = Object.assign({}, budget, {
                                      soft_merch_notes: e.target.value
                                    });

                                    updateBudget(updated);
                                    debouncedAutoSaveBudget(csrfToken, team, updated);
                                  }
                                }
                                id="offer-soft-merch-notes" />
                      </div>
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className="col-6 col-xl-3 text-truncate">
                      <div className="form-group" style={{"marginBottom": "2px"}}>
                        <label className="text-muted"
                              style={{"marginBottom": "3px"}}
                              htmlFor="offer-hard-merch-rate">
                          Hard Merch Rate (artist %)
                        </label>
                        <div className="input-group inline-white"
                            style={{
                              "border": "1px solid #ececec",
                              "borderRadius": "4px"
                            }}>
                          <CurrencyInput className="form-control form-control-sm"
                                   maskOptions={{
                                     prefix: "",
                                     integerLimit: null
                                   }}
                                   placeholder={"0"}
                                   value={budget.hard_merch_rate || ""}
                                   onChange={
                                     (e) => {
                                       var updated = Object.assign({}, budget, {
                                          hard_merch_rate: e.target.value
                                       });

                                       updateBudget(updated);
                                       debouncedAutoSaveBudget(csrfToken, team, updated);
                                     }
                                   }
                                   id="offer-hard-merch-rate" />
                          <div className="input-group-append">
                            <span className="input-group-text px-2"
                                  id="offer-upside-percentage-percentage">
                              <i className="far fa-percentage text-muted"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6 col-xl-3">
                      <div className="form-group" style={{"marginBottom": "2px"}}>
                        <label className="text-muted"
                              style={{"marginBottom": "3px"}}
                              htmlFor="offer-hard-merch-sold-by">
                          Sold by
                        </label>
                        <select className="form-control form-control-sm"
                                style={{"border": "1px solid #ececec"}}
                                value={budget.hard_merch_sold_by || ""}
                                onChange={
                                  (e) => {
                                    var updated = Object.assign({}, budget, {
                                      hard_merch_sold_by: e.target.value
                                    });

                                    updateBudget(updated);
                                    debouncedAutoSaveBudget(csrfToken, team, updated);
                                  }
                                }
                                id="offer-hard-merch-sold-by">
                          <option value="Artist">Artist</option>
                          <option value="Venue">Venue</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-12 col-xl-6">
                      <div className="form-group" style={{"marginBottom": "2px"}}>
                        <label className="text-muted"
                              style={{"marginBottom": "3px"}}
                              htmlFor="offer-hard-merch-notes">
                          Hard Merch Notes
                        </label>
                        <DebounceInput className="form-control form-control-sm"
                                type="text"
                                style={{"border": "1px solid #ececec"}}
                                value={budget.hard_merch_notes || ""}
                                onChange={
                                  (e) => {
                                    var updated = Object.assign({}, budget, {
                                      hard_merch_notes: e.target.value
                                    });

                                    updateBudget(updated);
                                    debouncedAutoSaveBudget(csrfToken, team, updated);
                                  }
                                }
                                id="offer-hard-merch-notes" />
                      </div>
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className="col-12">
                      <div className="form-group" style={{"marginBottom": "2px"}}>
                        <label className="text-muted"
                              style={{"marginBottom": "3px"}}
                              htmlFor="offer-addl-merch-notes">
                          Additional Merch Notes
                        </label>
                        <DebounceInput className="form-control form-control-sm"
                                type="text"
                                style={{"border": "1px solid #ececec"}}
                                value={budget.addl_merch_notes || ""}
                                onChange={
                                  (e) => {
                                    var updated = Object.assign({}, budget, {
                                      addl_merch_notes: e.target.value
                                    });

                                    updateBudget(updated);
                                    debouncedAutoSaveBudget(csrfToken, team, updated);
                                  }
                                }
                                id="offer-addl-merch-notes" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="mb-4"
                  style={{"borderTop": "2px solid #e6e6e6"}} />
              <h3 style={{"fontSize": "20px"}}>
                <strong>Deposits</strong>
              </h3>
              <div className="row">
                <div className="col-12 pt-2">
                  <DepositManager
                    csrfToken={csrfToken}
                    team={team}
                    budget={budget}
                    updateBudget={updateBudget}
                    currentUser={currentUser}
                    debouncedAutoSaveBudget={debouncedAutoSaveBudget}
                  />
                </div>
              </div>
              <hr className="mb-4"
                  style={{"borderTop": "2px solid #e6e6e6"}} />
              <h3 style={{"fontSize": "20px"}}>
                <strong>Additional Terms</strong>
              </h3>
              <div className="row">
                <div className="col-12 pt-2">
                  <RichEditor
                    editorState={additionalOfferTerms}
                    onEditorStateChange={
                      (editorState) => {
                        setAdditionalOfferTerms(current => {
                          const currentText = stateToHTML(editorState.getCurrentContent());
                          const newText = stateToHTML(current.getCurrentContent());

                          if (currentText !== newText) {
                            var updated = Object.assign({}, offer, {additionalTerms: editorState});

                            updateOffer(updated);
                            debouncedAutoSaveOffer(csrfToken, team, updated, editorState);
                          }

                          return editorState;
                        });
                      }
                    } />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Popup
        open={(modalUIState.length > 0)}
        contentStyle={modalContentStyle}
        closeOnDocumentClick={false}
        className={"offer-modal"}
        onClose={() => updateModalUIState("")}
      >
        <OfferV2PreviewApp offer={offer}
                           currentUser={currentUser}
                           csrfToken={csrfToken}
                           uiState={modalUIState}
                           onClose={
                             () => {
                               updateModalUIState("");
                             }
                           }
                           onSuccess={
                             (offer) => {
                               updateModalUIState("");
                               updateOffer(offer);

                               toast.success("Offer has been sent.", {
                                 position: toast.POSITION.TOP_CENTER,
                                 draggable: false,
                                 closeOnClick: false,
                                 autoClose: 5000,
                                 hideProgressBar: true
                               });
                             }
                           }
                           team={team} />
      </Popup>
      <Popup
        open={Object.keys(offerToLink).length > 0}
        contentStyle={linkOfferModalContentStyle}
        closeOnDocumentClick={false}
        className={"offer-modal"}
        onClose={() => {
          offerToLinkChanged({});
        }}
      >
        <LinkOfferModalApp currentUser={currentUser}
                           csrfToken={csrfToken}
                           offer={offerToLink}
                           onClose={
                             () => {
                               offerToLinkChanged({});
                             }
                           }
                           onSuccess={
                             (data) => {
                               var updated = Object.assign({}, offer, data);

                               updateOffer(updated);
                               offerToLinkChanged({});

                               toast.success("Offer has been updated.", {
                                 position: toast.POSITION.TOP_CENTER,
                                 draggable: false,
                                 closeOnClick: false,
                                 autoClose: 5000,
                                 hideProgressBar: true
                               });
                             }
                           }
                           team={team} />
      </Popup>
      <Popup
        modal
        open={attachArtistModalOpen}
        closeOnDocumentClick={false}
        contentStyle={deleteModalContentStyle}
      >
        {close => (
          <AttachArtistToOffer
            csrfToken={csrfToken}
            currentUser={currentUser}
            team={team}
            confirmPerformers={offer.calendar_event?.performers || []}
            offer={offer}
            offerChanged={updateOffer}
            close={() => { attachArtistModalOpenChanged(false); close() }}
            handleAttachArtist={updatedOffer => {
              autoSaveOffer(csrfToken, team, updatedOffer, additionalOfferTerms);

              attachArtistModalOpenChanged(false);
              close();
            }}
          />
        )}
      </Popup>
    </div>
  )
};

OfferEditUI.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  updateOfferAdditionalTermsFromHTML: PropTypes.func.isRequired,
  autoSaveOffer: PropTypes.func.isRequired,
  budget: PropTypes.object.isRequired,
  createTicketBudgetLineItem: PropTypes.func.isRequired,
  createIncomeBudgetLineItem: PropTypes.func.isRequired,
  createBeforeAdjustedDeductionsBudgetLineItem: PropTypes.func.isRequired,
  createExpensesBudgetLineItem: PropTypes.func.isRequired,
  deleteBudgetLineItem: PropTypes.func.isRequired,
  autoSaveBudgetLineItem: PropTypes.func.isRequired,
  updateOffer: PropTypes.func.isRequired,
  updateBudget: PropTypes.func.isRequired,
  autoSaveBudget: PropTypes.func.isRequired,
  acceptOffer: PropTypes.func.isRequired,
  declineOffer: PropTypes.func.isRequired,
  deleteOffer: PropTypes.func.isRequired,
  calculated: PropTypes.object,
  updateCalculated: PropTypes.func.isRequired,
  modalUIState: PropTypes.string,
  updateModalUIState: PropTypes.func.isRequired,
  isSaving: PropTypes.bool,
  isCreatingTemplate: PropTypes.bool,
  isCreatingTemplateChanged: PropTypes.func.isRequired,
  submitBudgetTemplate: PropTypes.func.isRequired,
  budgetTemplates: PropTypes.array,
  useBudgetTemplateWithBudget: PropTypes.func.isRequired,
  budgetTemplate: PropTypes.object,
  budgetTemplateNameChanged: PropTypes.func.isRequired,
  budgetTemplateChanged: PropTypes.func.isRequired,
  offerToLink: PropTypes.object,
  offerToLinkChanged: PropTypes.func.isRequired,
  deleteBudgetTemplate: PropTypes.func.isRequired,
  createBudgetBonus: PropTypes.func.isRequired,
  deleteBudgetBonus: PropTypes.func.isRequired,
  autoSaveBudgetBonus: PropTypes.func.isRequired,
  attachArtistModalOpen: PropTypes.bool.isRequired,
  attachArtistModalOpenChanged: PropTypes.func.isRequired,
  getActiveTags: PropTypes.func.isRequired,
  getAvailableTags: PropTypes.func.isRequired,
};

export default OfferEditUI;

import PropTypes from 'prop-types';
import React, { useEffect, useContext } from 'react';

import {
  CHOOSING_TICKET_TYPES,
  CHOOSING_ADD_ONS,
  CHOOSING_DONATION
} from '../constants/webOrderFormConstants';

var _ = require('lodash');

import { ToastContainer, toast } from 'react-toastify';
import {loadStripe} from '@stripe/stripe-js';
import { ActionCableContext } from '../startup/WebOrderFormApp';

import SoldOut from './SoldOut';
import Canceled from './Canceled';
import TimeLimitReached from './TimeLimitReached';
import PickTickets from './PickTickets';
import Checkout from './Checkout';

const axios = require('axios').default;

const https = require('https');
const httpsAgent = new https.Agent({ keepAlive: true });

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

// Mimics PromoCode#calculate_discount
const calculateDiscountedTicketTypePrice = (ticketType, promoCode) => {
  var discount = 0.00;
  var discountedPrice = 0.00;
  var ticketPrice = parseFloat(ticketType.price);

  if(!promoCode.applies_to_ticket_types){
    return ticketPrice;
  }

  if(promoCode.reveal_hidden_tickets
    && !promoCode.ticket_type_ids.includes(ticketType.id)
    && !promoCode.ticket_type_ids.includes(ticketType.ticket_type_template_id)
  ) {
    return ticketPrice;
  }

  if(typeof(promoCode.percentage) === "number"){
    discount = (ticketPrice * (promoCode.percentage / 100.00));
  } else {
    discount = parseFloat(promoCode.amount);
  }

  discount = (discount > ticketPrice ? ticketPrice : discount);
  discountedPrice = (ticketPrice - discount);

  return discountedPrice;
}

const discountedTicketTypePrice = (ticketType, promoCode) => {
  var discountedPrice = calculateDiscountedTicketTypePrice(ticketType, promoCode);
  return formatter.format(discountedPrice);
}

const calculateDiscountedAddOnPrice = (addOn, promoCode) => {
  var discount = 0.00;
  var discountedPrice = 0.00;
  var addOnPrice = parseFloat(addOn.price);

  if(!promoCode.applies_to_add_ons){
    return addOnPrice;
  }

  if(typeof(promoCode.percentage) === "number"){
    discount = (addOnPrice * (promoCode.percentage / 100.00));
  } else {
    discount = parseFloat(promoCode.amount);
  }

  discount = (discount > addOnPrice ? addOnPrice : discount);
  discountedPrice = (addOnPrice - discount);

  return discountedPrice;
}

const discountedAddOnPrice = (addOn, promoCode) => {
  var discountedPrice = calculateDiscountedAddOnPrice(addOn, promoCode);
  return formatter.format(discountedPrice);
}

const visibleTicketTypes = (confirm, ticketTypes, promoCode) => {
  var now = new Date();

  return ticketTypes.filter((tt) => {
    var startTime = new Date(tt.start_time);
    var endTime = new Date(tt.end_time);

    var onSale = !confirm.sold_out;
    var unmanifested = tt.manifested === false;
    var notAtTheDoor = tt.sales_channel !== "At the door only";
    var visible = tt.visibility === "Visible";
    var showWhenOnSale = tt.visibility === "Hidden when not on sale" && startTime <= now && now <= endTime;
    var revealedByPromoCode = Object.keys(promoCode).length > 0
      && (promoCode.ticket_type_ids.includes(tt.id)
        || promoCode.ticket_type_ids.includes(tt.ticket_type_template_id));

    return (
      notAtTheDoor
        && (onSale || unmanifested)
        && (visible || showWhenOnSale || revealedByPromoCode)
        && tt.start_time
        && tt.end_time
    );
  });
}

// Matches AddOn.available_for_upgrade
const visibleAddOns = (addOns, ticketTypes, userContext) => {
  var now = new Date();

  var ticketTypeIds = ticketTypes
    .filter((tt) => tt.quantity && tt.quantity > 0)
    .map((tt) => tt.id);

  return addOns.filter((addOn) => {
    var startTime = new Date(addOn.start_time);
    var endTime = new Date(addOn.end_time);
    var quantityOptions = addOnQuantityOptions(addOn, ticketTypes, userContext);

    var ownedTicketTypeIds = eligibleOwnedTicketTypes(addOn, userContext)
      .filter((tt) => tt.quantity && tt.quantity > 0)
      .map((tt) => tt.id);

    var matchedTicketTypeIds = ticketTypeIds
      .concat(ownedTicketTypeIds)
      .filter((id) => addOn.ticket_type_ids.includes(id));

    return (
      startTime < now
        && endTime > now
        && (quantityOptions.filter((i) => i !== 0).length > 0)
        && (!addOn.require_ticket_type || matchedTicketTypeIds.length > 0)
        && addOn.start_time
        && addOn.end_time
    );
  });
}

const addOnQuantityOptions = (addOn, ticketTypes, userContext) => {
  var useQuantityOptions = (
    !addOn.require_ticket_type
      || (
        addOn.seating_chart_category_key
          && addOn.seating_chart_category_key.length > 0
      )
  );

  if(useQuantityOptions){
    return addOn.current_quantity_options || addOn.quantity_options;
  } else {
    var ownedTicketTypes = eligibleOwnedTicketTypes(addOn, userContext);
    var buyingAndOwnedTicketTypes = ticketTypes.concat(ownedTicketTypes);
    var groupedTicketTypes = _.groupBy(buyingAndOwnedTicketTypes, "id");

    var allTicketTypes = Object.keys(groupedTicketTypes)
      .map((ticketTypeId) => {
        var quantity = groupedTicketTypes[ticketTypeId]
          .reduce((sum, tt) => {
            return sum + (tt.quantity || 0);
          }, 0);

        return {
          id: parseInt(ticketTypeId),
          quantity: quantity
        }
      });

    var ticketsOrdered = allTicketTypes
      .filter((tt) => addOn.ticket_type_ids.includes(tt.id))
      .filter((tt) => tt.quantity && tt.quantity > 0)
      .reduce((sum, tt) => {
        return sum + tt.quantity;
      }, 0);

    var maximumAllowed = (
      addOn.maximum_per_order < ticketsOrdered ? addOn.maximum_per_order : ticketsOrdered
    );

    return addOn.quantity_options.filter((i) => i <= maximumAllowed);
  }
}

// Logic matches AddOnOrderItem#eligible_tickets_from_user_context
const eligibleOwnedTicketTypes = (addOn, userContext) => {
  if(!addOn.require_ticket_type){
    return [];
  }

  var userTickets = (userContext.tickets || []);
  var addOnTickets = userTickets.filter((t) => t.add_on_id === addOn.id);
  var admissionTickets = userTickets.filter((t) => addOn.ticket_type_ids.includes(t.ticket_type_id));

  var addOnsCount = addOn.seating_chart_category_key && addOn.seating_chart_category_key.length > 0 ? (
      0
    ) : addOnTickets.length > admissionTickets.length ? (
      admissionTickets.length
    ) : (
      addOnTickets.length
    );

  var lastIndex = (admissionTickets.length - addOnsCount);
  var slicedAdmissionTickets = admissionTickets.slice(0, lastIndex);
  var groupedByTicketTypeId = _.groupBy(slicedAdmissionTickets, "ticket_type_id");

  return Object.keys(groupedByTicketTypeId).map((ticketTypeId) => {
    return {
      id: parseInt(ticketTypeId),
      quantity: groupedByTicketTypeId[ticketTypeId].length
    }
  });
}

const notPublishedYet = (confirm) => {
  if(confirm.published_at === null){
    return true;
  }

  var publishedAt = new Date(confirm.published_at);
  var now = new Date();

  return now < publishedAt;
}

const showSoldOutScreen = (confirm, ticketReservation, ticketTypes) => {
  return (
    confirm.sold_out &&
      !ticketReservation.id &&
      choosingTicketTypes(ticketReservation) &&
      ticketTypes.filter(tt => !tt.manifested).length === 0
  );
}

const postMessageReceived = (
  event,
  gtag,
  confirm,
  customerioIdChanged,
  goToAddOns,
  ticketReservation,
  postToParent,
  ticketReservationChanged,
  nextStepIsChooseAddOns,
  visibleAddOns
) => {
  var payload = event.data;

  if(typeof(payload) === "object" && payload.from === "od_embed.js"){
    var command = payload.command;
    var data = payload.data;

    switch (command) {
      case "triggerGoToAddOns":
        if(nextStepIsChooseAddOns(ticketReservation, visibleAddOns)){
          goToAddOns(ticketReservation, postToParent, ticketReservationChanged);
        }
        break;
      case "setClientID":
        gtag('set', {'client_id': data.clientId});
        break;
      case "setCustomerioId":
        customerioIdChanged(data.customerioId);
        break;
      case "reportPageView":
        gtag('event', 'page_view', {
          page_title: confirm.title,
          page_location: "/confirms/" + confirm.id + "/web_orders/new"
        });

        postToParent("ga.page_view", "/confirms/" + confirm.id + "/web_orders/new");
        break;
      default:
        console.log("Unknown command \"" + command + "\" received from od_embed.js");
    }
  }
}

const postToParent = (command, data) => {
  parent.postMessage({
    from: "WebOrderForm.jsx",
    command: command,
    data: data
  }, "*");
}

const formatItemsForGoogleTagManager = (confirm, promoCode, ticketTypes, addOns) => {
  var formattedticketTypes = ticketTypes
    .filter((tt) => tt.quantity && tt.quantity > 0)
    .map((tt) => {
      var price = (Object.keys(promoCode).length > 0 ? (
          calculateDiscountedTicketTypePrice(tt, promoCode)
        ) : (
          parseFloat(tt.price)
        ));

      return {
        item_name: confirm.id + " " + tt.name,
        item_id: tt.id,
        price: price,
        quantity: tt.quantity
      }
    });

  var formattedAddOns = addOns
    .filter((ao) => ao.quantity && ao.quantity > 0)
    .map((ao) => {
      var price = (Object.keys(promoCode).length > 0 ? (
          calculateDiscountedAddOnPrice(ao, promoCode)
        ) : (
          parseFloat(ao.price)
        ));

      return {
        item_name: confirm.id + " " + ao.name,
        item_id: ao.id,
        price: price,
        quantity: ao.quantity
      }
    });

  return formattedticketTypes
    .concat(formattedAddOns);
}

const choosingTicketTypes = (ticketReservation) => {
  return (
    !ticketReservation._screen
      || ticketReservation._screen === CHOOSING_TICKET_TYPES
  );
}

const choosingAddOns = (ticketReservation) => {
  return (
    ticketReservation._screen
      && ticketReservation._screen === CHOOSING_ADD_ONS
  );
}

const nextStepIsChooseAddOns = (ticketReservation, visibleAddOns) => {
  return (
    visibleAddOns.length > 0
      && (
        Object.keys(ticketReservation).length === 0
          || choosingTicketTypes(ticketReservation)
      )
  );
}

const hasDonationStep = (donationCustomChargeType) => {
  return (
    donationCustomChargeType
      && donationCustomChargeType.id
  );
}

const nextStepIsChooseDonation = (ticketReservation, visibleAddOns, donationCustomChargeType) => {
  var goToDonationFromTicketTypes = (
    choosingTicketTypes(ticketReservation)
      && !nextStepIsChooseAddOns(ticketReservation, visibleAddOns)
  );

  var goToDonationFromAddOns = (
    choosingAddOns(ticketReservation)
  );

  return (
    hasDonationStep(donationCustomChargeType)
      && (goToDonationFromTicketTypes || goToDonationFromAddOns)
  );
}

const goToAddOns = (ticketReservation, postToParent, ticketReservationChanged) => {
  var updated = Object.assign({}, ticketReservation, {_screen: CHOOSING_ADD_ONS});
  postToParent("opendateOrderGoToAddOns", {});
  ticketReservationChanged(updated);
}

const goToDonation = (ticketReservation, ticketReservationChanged) => {
  var updated = Object.assign({}, ticketReservation, {_screen: CHOOSING_DONATION});
  ticketReservationChanged(updated);
}

var debouncedBuildTicketReservation;

export const ticketTypeAvailable = (ticketType) => {
  return !(
    ticketType.sold_out || ticketType.sales_ended || ticketType.scheduled
  );
};

export const salesStartInFuture = (confirm) => {
  return confirm.sales_start_at
    && new Date(confirm.sales_start_at) > new Date();
};

const WebOrderForm = ({
  team,
  csrfToken,
  confirm,
  confirmChanged,
  channelMsgReceived,
  ticketTypes,
  promoCode,
  lookupPromoCode,
  promoCodeName,
  promoCodeNameChanged,
  promoCodeNameValid,
  promoCodeChanged,
  ticketReservation,
  ticketTypesChanged,
  buildTicketReservation,
  ticketReservationChanged,
  createTicketReservation,
  deleteTicketReservation,
  stripePromise,
  stripePromiseChanged,
  stripePublishableKey,
  stripeError,
  stripeErrorChanged,
  updateTicketReservation,
  rootUrl,
  checkoutTimePercentageRemaining,
  checkoutTimePercentageRemainingChanged,
  timeLimitReached,
  timeLimitReachedChanged,
  isPlacingOrder,
  isPlacingOrderChanged,
  addOns,
  addOnsChanged,
  googleTrackingId,
  gtag,
  gtagChanged,
  customFieldValues,
  customFieldValueChanged,
  userContext,
  cancelCheckout,
  seatsIOPublicKey,
  seatsIOChart,
  seatsIOChartChanged,
  seatsIOSelectedObjects,
  seatsIOSelectedObjectsChanged,
  autoSaveTicketReservation,
  customerioId,
  customerioIdChanged,
  isCreatingTicketReservation,
  saveGeoCodedStateCode,
  verticalInsureClientID,
  registerTicketInsurance,
  insuranceOfferState,
  insuranceOfferStateChanged,
  ticketInsurance,
  deleteTicketInsurance,
  hasInsuranceQuote,
  hasInsuranceQuoteChanged,
  announceListEnabled,
  waitlistEnabled,
  donationCustomChargeType,
  donationCustomChargeTypeChanged,
  feeRules,
  startAtAddOns,
  startAtDonation
}) => {
  const cachedVisibleAddOns = visibleAddOns(addOns, ticketTypes, userContext);
  const cable = useContext(ActionCableContext).cable;

  useEffect(() => {
    if(team.real_time_ticket_data) {
      cable.subscriptions.create(
        {
          channel: "ConfirmChannel",
          id: confirm.id
        },
        {
          received: channelMsgReceived
        }
      )
    }

    var promise = loadStripe(stripePublishableKey);
    stripePromiseChanged(promise);

    var gtag = () => {};

    if(googleTrackingId && googleTrackingId.length > 0){
      window.dataLayer = window.dataLayer || [];
      gtag = function(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', googleTrackingId, {cookie_flags: 'secure;samesite=none'});
    }

    var wrappedPostMessageReceived = (event) => {
      postMessageReceived(
        event,
        gtag,
        confirm,
        customerioIdChanged,
        goToAddOns,
        ticketReservation,
        postToParent,
        ticketReservationChanged,
        nextStepIsChooseAddOns,
        visibleAddOns(addOns, ticketTypes, userContext)
      );
    }

    // https://robertnyman.com/html5/postMessage/postMessage.html
    if (window.addEventListener) {
      window.addEventListener("message", wrappedPostMessageReceived, false);
    } else {
      window.attachEvent("onmessage", wrappedPostMessageReceived);
    }

    gtagChanged(gtag);

    debouncedBuildTicketReservation = _.debounce((csrfToken, confirm, ticketTypes, promoCode, addOns, ticketReservation, userContext) => {
      buildTicketReservation(
        csrfToken,
        confirm,
        ticketTypes,
        promoCode,
        addOns,
        ticketReservation,
        userContext
      );
    }, 300);

    if(startAtAddOns && nextStepIsChooseAddOns(ticketReservation, visibleAddOns)){
      goToAddOns(ticketReservation, postToParent, ticketReservationChanged);
    }

    if(startAtDonation && hasDonationStep(donationCustomChargeType)){
      goToDonation(ticketReservation, ticketReservationChanged);
    }
  }, []);

  const freeHoldTokens = () => {
    var orderItems = ticketTypes.filter((tt) =>
      tt.quantity && tt.quantity > 0
    );

    var addOnOrderItems = addOns.filter((ao) =>
      ao.quantity && ao.quantity > 0
    );

    var seatsioHoldTokens = [];

    if(orderItems.some((i) => i.seatsIOObjects && i.seatsIOObjects.length > 0)){
      seatsioHoldTokens = [...seatsioHoldTokens, seatsIOChart.holdToken];
    }

    var addOnHoldTokens = [
      ...new Set(
        addOnOrderItems
          .filter((item) => item.seatsIOObjects)
          .map((item) => item.seatsIOObjects)
          .flat()
          .map((object) => object.hold_token)
      )
    ];

    seatsioHoldTokens = [
      ...seatsioHoldTokens,
      ...addOnHoldTokens
    ];

    if (seatsioHoldTokens.length) {
      axios.get(`/confirms/${confirm.id}/ticket_reservations/expire_hold_tokens`, {
        params: {
          hold_tokens: seatsioHoldTokens
        },
        httpsAgent: httpsAgent
      })
      .catch(_error => {});
    }
  };

  useEffect(() => {
    window.onbeforeunload = function(e) {
      var ticketTypesWithQuantity = ticketTypes.filter((tt) =>
        tt.quantity && tt.quantity > 0
      );

      var addOnsWithQuantity = addOns.filter((ao) =>
        ao.quantity && ao.quantity > 0
      );

      var shouldPromptOnPageUnload = (
        !isPlacingOrder
          && (
            ticketTypesWithQuantity.length > 0
              || addOnsWithQuantity.length > 0
          )
      );

      if(shouldPromptOnPageUnload) {
        freeHoldTokens();
        e.preventDefault();
        return "";
      }
    }
  }, [ticketTypes, addOns, isPlacingOrder]);

  const handleCancelCheckout = () => {
    freeHoldTokens();
    cancelCheckout();
  }

  return (
    <div>
      <ToastContainer />
      {notPublishedYet(confirm) ? (
        <div></div>
      ) : confirm.calendar_classification === "canceled" ? (
        <Canceled />
      ) : confirm.sales_end_at && new Date(confirm.sales_end_at) < new Date() ? (
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8 offset-lg-2">
              <div className="card border-0 h-100" style={{"background": "#f9f9f9"}}>
                <div className="card-body d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <p className="mb-2"
                      style={{"fontSize": "16px"}}>
                      <strong>Sale ended</strong>
                    </p>
                    <p className="mb-0">
                      This event has ended and tickets are no longer available.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : showSoldOutScreen(confirm, ticketReservation, ticketTypes) ? (
        <SoldOut team={team}
                 csrfToken={csrfToken}
                 confirm={confirm}
                 ticketTypes={ticketTypes}
                 promoCode={promoCode}
                 ticketReservation={ticketReservation}
                 addOns={addOns}
                 nextStepIsChooseAddOns={nextStepIsChooseAddOns(ticketReservation, cachedVisibleAddOns)}
                 nextStepIsChooseDonation={nextStepIsChooseDonation(ticketReservation, cachedVisibleAddOns, donationCustomChargeType)}
                 visibleAddOns={cachedVisibleAddOns}
                 goToAddOns={goToAddOns}
                 goToDonation={() => { goToDonation(ticketReservation, ticketReservationChanged) }}
                 postToParent={postToParent}
                 ticketReservationChanged={ticketReservationChanged}
                 seatsIOSelectedObjects={seatsIOSelectedObjects}
                 discountedAddOnPrice={discountedAddOnPrice}
                 ticketInsurance={ticketInsurance}
                 discountedTicketTypePrice={discountedTicketTypePrice}
                 waitlistEnabled={waitlistEnabled} />
      ) : timeLimitReached ? (
        <TimeLimitReached team={team}
                          csrfToken={csrfToken}
                          timeLimitReachedChanged={timeLimitReachedChanged}
                          confirm={confirm} />
      ) : !ticketReservation.id ? (
        <PickTickets team={team}
                     csrfToken={csrfToken}
                     confirm={confirm}
                     ticketTypes={ticketTypes}
                     promoCode={promoCode}
                     lookupPromoCode={lookupPromoCode}
                     promoCodeName={promoCodeName}
                     promoCodeNameChanged={promoCodeNameChanged}
                     promoCodeNameValid={promoCodeNameValid}
                     promoCodeChanged={promoCodeChanged}
                     ticketReservation={ticketReservation}
                     ticketTypesChanged={ticketTypesChanged}
                     buildTicketReservation={buildTicketReservation}
                     createTicketReservation={createTicketReservation}
                     customerioId={customerioId}
                     visibleTicketTypes={visibleTicketTypes}
                     visibleAddOns={cachedVisibleAddOns}
                     addOnQuantityOptions={addOnQuantityOptions}
                     addOns={addOns}
                     addOnsChanged={addOnsChanged}
                     ticketReservationChanged={ticketReservationChanged}
                     calculateDiscountedTicketTypePrice={calculateDiscountedTicketTypePrice}
                     calculateDiscountedAddOnPrice={calculateDiscountedAddOnPrice}
                     userContext={userContext}
                     cancelCheckout={handleCancelCheckout}
                     formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                     postToParent={postToParent}
                     seatsIOPublicKey={seatsIOPublicKey}
                     seatsIOChart={seatsIOChart}
                     seatsIOChartChanged={seatsIOChartChanged}
                     seatsIOSelectedObjects={seatsIOSelectedObjects}
                     seatsIOSelectedObjectsChanged={seatsIOSelectedObjectsChanged}
                     goToAddOns={goToAddOns}
                     goToDonation={() => { goToDonation(ticketReservation, ticketReservationChanged) }}
                     nextStepIsChooseAddOns={nextStepIsChooseAddOns(ticketReservation, cachedVisibleAddOns)}
                     nextStepIsChooseDonation={nextStepIsChooseDonation(ticketReservation, cachedVisibleAddOns, donationCustomChargeType)}
                     choosingAddOns={choosingAddOns}
                     choosingTicketTypes={choosingTicketTypes}
                     isCreatingTicketReservation={isCreatingTicketReservation}
                     ticketInsurance={ticketInsurance}
                     discountedAddOnPrice={discountedAddOnPrice}
                     discountedTicketTypePrice={discountedTicketTypePrice}
                     donationCustomChargeType={donationCustomChargeType}
                     donationCustomChargeTypeChanged={donationCustomChargeTypeChanged}
                     debouncedBuildTicketReservation={debouncedBuildTicketReservation}
                     feeRules={feeRules}
                     announceListEnabled={announceListEnabled}
                     waitlistEnabled={waitlistEnabled} />
      ) : ticketReservation.id ? (
        <Checkout team={team}
                  csrfToken={csrfToken}
                  confirm={confirm}
                  ticketTypes={ticketTypes}
                  promoCode={promoCode}
                  ticketReservation={ticketReservation}
                  discountedTicketTypePrice={discountedTicketTypePrice}
                  discountedAddOnPrice={discountedAddOnPrice}
                  deleteTicketReservation={deleteTicketReservation}
                  stripePromise={stripePromise}
                  stripeError={stripeError}
                  stripeErrorChanged={stripeErrorChanged}
                  updateTicketReservation={updateTicketReservation}
                  rootUrl={rootUrl}
                  saveGeoCodedStateCode={saveGeoCodedStateCode}
                  checkoutTimePercentageRemaining={checkoutTimePercentageRemaining}
                  checkoutTimePercentageRemainingChanged={checkoutTimePercentageRemainingChanged}
                  timeLimitReachedChanged={timeLimitReachedChanged}
                  isPlacingOrder={isPlacingOrder}
                  isPlacingOrderChanged={isPlacingOrderChanged}
                  addOns={addOns}
                  gtag={gtag}
                  formatItemsForGoogleTagManager={formatItemsForGoogleTagManager}
                  customFieldValues={customFieldValues}
                  customFieldValueChanged={customFieldValueChanged}
                  calculateDiscountedTicketTypePrice={calculateDiscountedTicketTypePrice}
                  postToParent={postToParent}
                  seatsIOSelectedObjects={seatsIOSelectedObjects}
                  autoSaveTicketReservation={autoSaveTicketReservation}
                  userContext={userContext}
                  verticalInsureClientID={verticalInsureClientID}
                  registerTicketInsurance={registerTicketInsurance}
                  insuranceOfferState={insuranceOfferState}
                  insuranceOfferStateChanged={insuranceOfferStateChanged}
                  ticketInsurance={ticketInsurance}
                  deleteTicketInsurance={deleteTicketInsurance}
                  hasInsuranceQuote={hasInsuranceQuote}
                  hasInsuranceQuoteChanged={hasInsuranceQuoteChanged}
                  ticketReservationChanged={ticketReservationChanged} />
      ) : null}
    </div>
  );
};

WebOrderForm.propTypes = {
  team: PropTypes.object.isRequired,
  csrfToken: PropTypes.string.isRequired,
  confirm: PropTypes.object.isRequired,
  confirmChanged: PropTypes.func.isRequired,
  channelMsgReceived: PropTypes.func.isRequired,
  ticketTypes: PropTypes.array.isRequired,
  promoCode: PropTypes.object,
  lookupPromoCode: PropTypes.func.isRequired,
  promoCodeName: PropTypes.string,
  promoCodeNameChanged: PropTypes.func.isRequired,
  promoCodeNameValid: PropTypes.bool,
  promoCodeChanged: PropTypes.func.isRequired,
  ticketReservation: PropTypes.object,
  ticketTypesChanged: PropTypes.func.isRequired,
  buildTicketReservation: PropTypes.func.isRequired,
  ticketReservationChanged: PropTypes.func.isRequired,
  createTicketReservation: PropTypes.func.isRequired,
  deleteTicketReservation: PropTypes.func.isRequired,
  stripePromise: PropTypes.object,
  stripePromiseChanged: PropTypes.func.isRequired,
  stripePublishableKey: PropTypes.string.isRequired,
  stripeError: PropTypes.object,
  stripeErrorChanged: PropTypes.func.isRequired,
  updateTicketReservation: PropTypes.func.isRequired,
  rootUrl: PropTypes.string.isRequired,
  checkoutTimePercentageRemaining: PropTypes.number,
  checkoutTimePercentageRemainingChanged: PropTypes.func.isRequired,
  timeLimitReached: PropTypes.bool,
  timeLimitReachedChanged: PropTypes.func.isRequired,
  isPlacingOrder: PropTypes.bool,
  isPlacingOrderChanged: PropTypes.func.isRequired,
  addOns: PropTypes.array,
  addOnsChanged: PropTypes.func.isRequired,
  googleTrackingId: PropTypes.string,
  gtag: PropTypes.func,
  gtagChanged: PropTypes.func.isRequired,
  customFieldValues: PropTypes.array,
  customFieldValueChanged: PropTypes.func.isRequired,
  userContext: PropTypes.object,
  cancelCheckout: PropTypes.func.isRequired,
  seatsIOPublicKey: PropTypes.string.isRequired,
  seatsIOChart: PropTypes.object,
  seatsIOChartChanged: PropTypes.func.isRequired,
  seatsIOSelectedObjects: PropTypes.array,
  seatsIOSelectedObjectsChanged: PropTypes.func.isRequired,
  autoSaveTicketReservation: PropTypes.func.isRequired,
  customerioId: PropTypes.string.isRequired,
  customerioIdChanged: PropTypes.func.isRequired,
  isCreatingTicketReservation: PropTypes.bool,
  saveGeoCodedStateCode: PropTypes.func.isRequired,
  verticalInsureClientID: PropTypes.string,
  registerTicketInsurance: PropTypes.func.isRequired,
  insuranceOfferState: PropTypes.string,
  insuranceOfferStateChanged: PropTypes.func.isRequired,
  ticketInsurance: PropTypes.object,
  deleteTicketInsurance: PropTypes.func.isRequired,
  hasInsuranceQuote: PropTypes.bool,
  hasInsuranceQuoteChanged: PropTypes.func.isRequired,
  announceListEnabled: PropTypes.bool,
  waitlistEnabled: PropTypes.bool,
  donationCustomChargeType: PropTypes.object,
  donationCustomChargeTypeChanged: PropTypes.func.isRequired,
  feeRules: PropTypes.array.isRequired,
  startAtAddOns: PropTypes.bool,
  startAtDonation: PropTypes.bool
};

export default WebOrderForm;
